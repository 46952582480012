// @utilities
import { removeLocalStorageByPrefix } from 'utilities/cleanHelper';
import { LOCAL_ENVIRONMENT } from 'constants/index';
const { getSessionId } = require('utilities/usd');
const { isPrintOfficeMode } = require('utilities/contactCenter/printerHelper');
// @constants
import { VIEWER } from 'constants/contactCenter/pdfViewer';


const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
})

const getBaseURL = () => {
    const isLocal = window.__ENV__ === LOCAL_ENVIRONMENT;
    if (isPrintOfficeMode()) {
        return isLocal ? '/pdfViewerOffice.html' : '/ok-ui-frame-es-cc/pdfViewerOffice.html';
    } else {
        return isLocal ? '/pdfViewer.html' : '/ok-ui-frame-es-cc/pdfViewer.html';
    }
}

const openPDFViewer = (result, customId) => {
    const sessionId = getSessionId();
    if (sessionId && result) {
        const name = `${VIEWER}${customId ? '-' + customId : ''}-${sessionId}`;
        if (customId) {
            removeLocalStorageByPrefix(`${VIEWER}-${customId}`);
        } else {
            removeLocalStorageByPrefix(VIEWER);
        }

        const file = new Blob( [result], { type: 'application/pdf' });
        convertBlobToBase64(file).then((plainFile) => {
            localStorage.setItem(name, plainFile);
            const url = getBaseURL() + `?storage=${name}`;
            window.open(url, '_blank');
        });
    }
};

const showPDFFailure = () => {
    const sessionId = getSessionId();
    const name = `${VIEWER}-${sessionId}`;
    const url = getBaseURL() + `?storage=${name}`;
    removeLocalStorageByPrefix(VIEWER);
    window.open(url, '_blank');
};

export { openPDFViewer, showPDFFailure };

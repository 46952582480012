// @utilities
const { recordPage, recordLinkV2, recordProcess } = require('utilities/tagging');
// @core
const { formatText } = require('core/i18n').i18n;

// @ constants
const { EMPLOYMENT_SITUATION_TYPES } = require('constants/index');
const PROFETIONAL_SITUATION = 'situacion profesional';
const CAPITAL_INCOMES = {
  'capital_work' :'jobSalary',
  'capital_activities' :'professionalActivities',
  'capital_heritage' :'legacy/donate ',
  'capital_business' :'financialInvestments',
  'capital_realState' :'propertiesInvestments',
  'capital_gambling' :'lottery',
  'capital_other': 'others',
}

const OPERATION_PROFESIONAL_ACTIVITIES = {
  operations_dangerous_countries: 'operationsWithHighRiskCountries',
  checks_holder: 'bearerBankChecksFrequently',
  checks_traveler: 'travelChecksFrequently',
  delivery_or_cash_withdrawals :'cashFlowsFrequently',
  buying_and_selling_foreing_banknotes: 'currencyFlowsFrequently',
  payment_orders_or_collection_abroad: 'internationalTransfersFrequently',
  foreing_trade_operations: 'buySellOtherCountriesFrequently',
  none_of_the_above: 'noOneTheseActivities',
}

const INCOME_VALUES = {
  income_work: 'jobSalary',
  income_activities: 'professionalActivities',
  income_business: 'financialInvestments',
  income_realState: 'propertiesInvestments',
  income_other: 'others',
  income_origin_benefits: 'benefits',
  income_origin_family_help: 'familyHelp',
  income_origin_financial_investments: 'financialInvestments',
  income_origin_investment_property: 'propertiesInvestments',
}

const MAIN_ACCOUNT_PURPOSE = [
  {
    value: 'dailyManagement',
    label: 'contractsView-accountUsePersonal'
  },
  {
    value: 'workManagement',
    label: 'contractsView-accountUseProfessional'
  },
  {
    value: 'anotherOperation',
    label: 'contractsView-accountUseOther'
  }
];

const yourJobOptions = [
  {
    label: 'contractsView-student',
    value: 'student',
  },
  {
    label: 'contractsView-homeWork',
    value: 'housework',
  },
  {
    label: 'contractsView-unemployed',
    value: 'unemployed',
  },
];

function getYesNoValue(value, language) {
  const valueTransform = Number(value);

  if(language === 'es') {
    return valueTransform === 1 ? 'si' : 'no';
  } 

  return valueTransform === 1 ? 'yes' : 'no';
}

const getInputsFiocTagging = (id, value) => {
  try {
    switch (id) {
      case 'fioc_cnae': {
        return {
          categoriaEvento: 'conoces el CNAE',
          accionEvento: 'seleccion',
          etiquetaEvento: getYesNoValue(value, 'es'),
          action: 'click',
          format: 'radioButton',
          component: 'knowYourCNAE',
          element: getYesNoValue(value, 'en'),
        }
      }
    case 'profession_cnae':
      return {
        categoriaEvento: 'desplegable codigo profesional',
        accionEvento: 'seleccion',
        etiquetaEvento: value,
        action: 'select',
        format: 'selectBox',
        component: 'dropdown/professionCode',
        element: value,
      }
    case 'professionCode':
      return {    
        categoriaEvento: 'codigo de profesion',
        accionEvento: 'clickButton',
        etiquetaEvento: 'borrar',
        action: 'click',
        format: 'button',
        component: 'professionCode',
        element: 'clearText',
      }

      case 'company_name':
        return {
          categoriaEvento: 'nombre de la empresa',
          accionEvento: 'clickTextBox',
          etiquetaEvento: 'introduce el nombre de la empresa',
          action: 'click',
          format: 'textBox',
          component: 'companyName',
          element: 'introduceCompanyName'
        }

      case 'incomes_annual':
        return {
          categoriaEvento: 'desplegable nivel de ingresos',
          accionEvento: 'seleccion',
          etiquetaEvento: value,
          action: 'select',
          format: 'selectBox',
          component: 'dropdown/incomeLevel',
          element: value,
        }

      case 'profession_category':
          return {
            ategoriaEvento: 'desplegable sector profesional',
            accionEvento: 'seleccion',
            etiquetaEvento: value,
            action: 'select',
            format: 'selectBox',
            component: 'dropdown/professionalSector',
            element: value,
          }

      case 'profession_current':
        return {
          categoriaEvento: 'desplegable actividad economica',
          accionEvento: 'seleccion',
          etiquetaEvento: value,
          action: 'select',
          format: 'selectBox',
          component: 'dropdown/economicActivity',
          element: value,
        }

      case 'capital_description':
        return {
          categoriaEvento: 'como has generado patrimonio',
          accionEvento: 'clickTextBox',
          etiquetaEvento: 'introduce informacion sobre patrimonio',
          action: 'click',
          format: 'textBox',
          component: 'howBuiltCapital',
          element: 'introducehowBuiltCapital',
        }
      case 'has_pep':
        return {
          categoriaEvento: 'cargo publico',
          accionEvento: 'seleccion',
          etiquetaEvento: getYesNoValue(value, 'es'),
          action: 'click',
          format: 'radioButton',
          component: 'publicPosition',
          element: getYesNoValue(value, 'en'),
        }

        case 'operations_account':
          return {
            categoriaEvento: 'gestion de fondos de terceros',
            accionEvento: 'seleccion',
            etiquetaEvento: getYesNoValue(value, 'es'),
            action: 'click',
            format: 'radioButton',
            component: 'manageThirdPartyFunds',
            element:  getYesNoValue(value, 'en'),
          }
        case 'income_description':
          return {
            categoriaEvento: 'procedencia de ingresos',
            accionEvento: 'clickTextBox',
            etiquetaEvento: 'introduce informacion sobre ingresos',
            action: 'click',
            format: 'textBox',
            component: 'usualSourceOfIncome',
            element: 'introduceUsualSourceOfIncome',
          }
        case 'account_use_2':
        case 'account_use':
            return {
              categoriaEvento: 'principal proposito de la cuenta',
              accionEvento: 'seleccion',
              etiquetaEvento: formatText(MAIN_ACCOUNT_PURPOSE[value-1].label),
              action: 'click',
              format: 'radioButton',
              component: 'mainAccountPurpose',
              element: MAIN_ACCOUNT_PURPOSE[value-1].value
            }
      default:
        return null
    }
  } catch(error) {
    console.error(error)
  }
}

const getInputErrorFiocTagging = (id, message) => {
  switch (id) {
    case 'profession_cnae':
      return {
        categoriaEvento: 'codigo de profesion',
        accionEvento: 'textBox - error',
        etiquetaEvento: 'introduce el CNAE',
        action: 'error',
        format: 'textBox',
        component: 'professionCode',
        element: 'introduceCNAE',
        errorCategory: 'mandatoryField',
        errorDescription: message,
      }

      default:
        return null
    }
}

const tagFioc = (eventName, eventTag) => {
    recordProcess(
        'perfil',
        null,
        {
            eventCategory: 'actualizacion fioc',
            eventName,
            eventTag
        }
    );
};

const tagFiocPersonalInfo = eventName => {
    tagFioc(eventName, 'actualizar fioc');
};

const tagFiocPersonalInfoStart = () => {
    tagFiocPersonalInfo('inicio');
};

const tagFiocPersonalInfoSuccess = () => {
    tagFiocPersonalInfo('exito');
};

const tagFiocDocumentation = eventName => {
    tagFioc(eventName, 'subir documentacion');
};

const tagFiocDocumentationStart = () => {
    tagFiocDocumentation('inicio');
};

const tagFiocDocumentationSuccess = () => {
    tagFiocDocumentation('exito');
};


const updateFiocRecordView = () => {
  const data = {
    nombrePagina: 'renovacion FIOC/paso1/seleccion',
    tipoPagina: 'hibrida',
    tipoProceso: 'renovacion FIOC',
    pasoProceso: 'paso1',
    detalleProceso: 'seleccion',
    pageName: 'updateFIOC/step1/selection',
    sectionFamily: 'banking',
    sectionCategory: 'services',
    sectionName: 'customer',
    serviceType: 'userInformation',
    interactionType: 'funnel',
    processType: 'updateFIOC',
    processStep: 'step1',
    processDetail: 'selection'
  }
  recordPage(data)
};

const employmentSituationLink = (tagEvent, value) => {
  const elementValue = EMPLOYMENT_SITUATION_TYPES[value];
  recordLinkV2({
    categoriaEvento: PROFETIONAL_SITUATION,
    accionEvento: 'seleccion',
    etiquetaEvento: tagEvent,
    action: 'click',
    format: 'radioButton',
    component: 'employmentSituation',
    element: elementValue
  })
}

const dataProtectionLink = () => {
  recordLinkV2({
    categoriaEvento: PROFETIONAL_SITUATION,
    accionEvento: 'clickLink',
    etiquetaEvento: 'tus derechos y proteccion de datos',
    action: 'click',
    format: 'link',
    component: 'message',
    element: 'rightsAndDataProtection'
  })
}

const buttonNextTagging = (value) => {
  const elementValue = EMPLOYMENT_SITUATION_TYPES[value];
  recordLinkV2({
    categoriaEvento: PROFETIONAL_SITUATION,
    accionEvento: 'clickButton',
    etiquetaEvento: 'siguiente',
    action: 'continue',
    format: 'button',
    component: 'lowerNavigation',
    element: 'next',
    data: elementValue
  })
}

const buttonCancelTagging = () => {
  recordLinkV2({
    categoriaEvento: PROFETIONAL_SITUATION,
    accionEvento: 'clickLink',
    etiquetaEvento: 'cancelar',
    action: 'cancel',
    format: 'link',
    component: 'lowerNavigation',
    element: 'cancel'
  })
}

const fiocForeignTaxErrorTagging = (error) => {
  recordLinkV2({
    categoriaEvento: PROFETIONAL_SITUATION,
    accionEvento: 'click - error',
    etiquetaEvento: 'selecciona una situacion',
    action: 'error',
    format: 'radioButton',
    component: 'employmentSituation',
    element: 'selectSituation',
    errorCategory: 'mandatoryField',
    errorDescription: error,
  })
}

const fiocErrorTagging = (error) => {
  recordLinkV2({
    categoriaEvento: PROFETIONAL_SITUATION,
    accionEvento: 'click - error',
    etiquetaEvento: 'selecciona una situacion',
    action: 'error',
    format: 'radioButton',
    component: 'employmentSituation',
    element: 'selectSituation',
    errorCategory: 'mandatoryField',
    errorDescription: error
  })
}

const fiocFormErrorTagging = ({ id, message }) => {
  const data = getInputErrorFiocTagging(id, message);
  data && recordLinkV2({
    ...data
  })
}

const fiocSelectClickTagging = () => {
  recordLinkV2({
    categoriaEvento: 'codigo de profesion',
    accionEvento: 'clickTextBox',
    etiquetaEvento: 'introduce el CNAE',
    action: 'click',
    format: 'textBox',
    component: 'professionCode',
    element: 'introduceCNAE',
  })
}

const fiocOKSelectClickTagging = (id) => {
  switch (id) {
    case 'profession_category':
      fiocSelectProfessionalClickTagging();
      break;
    case 'incomes_annual':
      fiocSelectIncomeAnnnualClickTagging();
      break;
  }

}

const fiocSelectIncomeAnnnualClickTagging = () => {
  recordLinkV2({
    categoriaEvento: 'desplegable nivel de ingresos',
    accionEvento: 'clickTextBox',
    etiquetaEvento: 'selecciona nivel de ingresos',
    action: 'click',
    format: 'textBox',
    component: 'dropdown/incomeLevel',
    element: 'selectIncomeLevel',
  })
}

const fiocSelectProfessionalClickTagging = () => {
  recordLinkV2({
    categoriaEvento: 'desplegable sector profesional',
    accionEvento: 'clickTextBox',
    etiquetaEvento: 'selecciona sector profesional',
    action: 'click',
    format: 'textBox',
    component: 'dropdown/professionalSector',
    element: 'selectProfessionalSector',
  })
}

const fiocInputTagging = ({ id, value = '' }) => {
  const data = getInputsFiocTagging(id, value);
  data && recordLinkV2({
      ...data
    })
}

const fiocSelctKnowYourCNAE = ({ id, value }) => {
  fiocInputTagging({ id, value })
}

const fiocSelectProfessionTagging = ({ id, value }) => {
  value ?
  fiocInputTagging({ id, value })
  : 
  fiocInputTagging({ id: 'professionCode' })
}

const fiocCompanyNameTagging = ({ id, value })  => {
  fiocInputTagging({ id, value }) 
}

const fiocSelectTagging = ({ id, value = ''}) => {
  fiocInputTagging({ id, value })
}

const fiocSelectEconomicActivityTagging = () => {
  recordLinkV2({
    categoriaEvento: 'desplegable actividad economica',
    accionEvento: 'clickTextBox',
    etiquetaEvento: 'selecciona actividad economica',
    action: 'click',
    format: 'textBox',
    component: 'dropdown/economicActivity',
    element: 'selectEconomicActivity',
  })
}

const fiocCheckboxTagging = (component) => {
  try {
    if(Object.keys(CAPITAL_INCOMES).includes(component.id)) {
      recordLinkV2({
        categoriaEvento: 'generacion de capital',
        accionEvento: 'seleccion',
        etiquetaEvento: formatText(component.label),
        action: 'click',
        format: 'checkBox',
        component: 'employmentSituation',
        element: CAPITAL_INCOMES[component.id],
      })
    } else if(Object.keys(OPERATION_PROFESIONAL_ACTIVITIES).includes(component.id)) {
      recordLinkV2({
        categoriaEvento: 'operaciones en gestion de actividad profesional',
        accionEvento: 'seleccion',
        etiquetaEvento: formatText(component.label),
        action: 'click',
        format: 'checkBox',
        component: 'employmentSituation',
        element: OPERATION_PROFESIONAL_ACTIVITIES[component.id]
      })
    } else if(Object.keys(INCOME_VALUES).includes(component.id)) {
      recordLinkV2({
        categoriaEvento: 'procedencia de ingresos',
        accionEvento: 'seleccion',
        etiquetaEvento: formatText(component.label),
        action: 'click',
        format: 'checkBox',
        component: 'usualSourceOfIncome',
        element: INCOME_VALUES[component.id],
      })
    }
  } catch(error) {
    console.error(error)
  }
}

const fiocTooltipHasPepTagging = () => {
  recordLinkV2({
    categoriaEvento: 'cargo publico',
    accionEvento: 'tooltip',
    etiquetaEvento: 'abrir',
    action: 'click',
    format: 'tooltip',
    component: 'publicPosition',
    element: 'open',
  })
}

const fiocInfoAboutPublicTagging = () => {
  recordLinkV2({
    categoriaEvento: 'informacion sobre cargo publico',
    accionEvento: 'clickTextBox',
    etiquetaEvento: 'introduce informacion sobre cargo publico',
    action: 'click',
    format: 'textBox',
    component: 'infoAboutPublicCharge',
    element: 'introduceInfoAboutPublicCharge',
  })
}

const fiocPepNameTagging = (id) => {
  if (id === 'has_pep_details') {
    recordLinkV2({
      categoriaEvento: 'informacion sobre cargo publico',
      accionEvento: 'clickTextBox',
      etiquetaEvento: 'introduce informacion sobre cargo publico',
      action: 'click',
      format: 'textBox',
      component: 'infoAboutPublicCharge',
      element: 'introduceInfoAboutPublicCharge',
    })
  }
}

const fiocForeignTaxesTagging = (value) => {
    try {
      recordLinkV2({
        categoriaEvento: 'obligaciones fiscales en otros paises',
        accionEvento: 'seleccion',
        etiquetaEvento: getYesNoValue(value, 'es'),
        action: 'click',
        format: 'radioButton',
        component: 'taxObligationsAbroad',
        element: getYesNoValue(value, 'en'),
      })
    } catch(error) {
      console.error(error)
    }
 
}

const fiocFiscalCountrySelectTagging = (value) => {
  recordLinkV2({
    categoriaEvento: 'pais donde tributas',
    accionEvento: 'dropdown',
    etiquetaEvento: value === 'open' ? 'abrir' : 'cerrar',
    action: value,
    format: 'dropdown',
    component: 'countryOfTaxation',
    element: value,
  })
}

const fiocFiscalCountryOpenTagging = () => {
  fiocFiscalCountrySelectTagging('open')
}

const fiocFiscalCountryCloseTagging = () => {
  fiocFiscalCountrySelectTagging('close')
}

const fiocPurposeOfTaxationSelectTagging = (value) => {
  recordLinkV2({
    categoriaEvento: 'motivo de tributacion',
    accionEvento: 'dropdown',
    etiquetaEvento: value === 'open' ? 'abrir' : 'cerrar',
    action: value,
    format: 'dropdown',
    component: 'purposeOfTaxation',
    element: value,
  })
}

const fiocPurposeOfTaxationOpenTagging = () => {
  fiocPurposeOfTaxationSelectTagging('open')
}

const fiocPurposeOfTaxationCloseTagging = () => {
  fiocPurposeOfTaxationSelectTagging('close')
}

const fiocFiscalCountrySelectedTagging = (value) => {
  recordLinkV2({
    categoriaEvento: 'desplegable pais donde tributas',
    accionEvento: 'seleccion',
    etiquetaEvento: value,
    action: 'select',
    format: 'selectBox',
    component: 'dropdown/countryOfTaxation',
    element: value, 
  })
}

const fiocFiscalCountryClickTagging = () => {
  recordLinkV2({
    categoriaEvento: 'desplegable pais donde tributas',
    accionEvento: 'clickTextBox',
    etiquetaEvento: 'selecciona un pais',
    action: 'click',
    format: 'textBox',
    component: 'dropdown/countryOfTaxation',
    element: 'selectCountry'
  })
}

const fiocPurposeOfTaxationClickTagging = () => {
  recordLinkV2({
    categoriaEvento: 'desplegable motivo de tributacion',
    accionEvento: 'clickTextBox',
    etiquetaEvento: 'selecciona motivo de tributacion',
    action: 'click',
    format: 'textBox',
    component: 'dropdown/purposeOfTaxation',
    element: 'selectPurposeOfTaxation',
  })
}

const fiocPurposeOfTaxationSelectedTagging = (value) => {
  recordLinkV2({
    categoriaEvento: 'desplegable motivo de tributacion',
    accionEvento: 'seleccion',
    etiquetaEvento: value,
    action: 'select',
    format: 'selectBox',
    component: 'dropdown/purposeOfTaxation',
    element: value,
  })
}

const fiocTinChangeTagging = () => {
  recordLinkV2({
    categoriaEvento: 'numero identificacion fiscal tin',
    accionEvento: 'clickTextBox',
    etiquetaEvento: 'introduce el tin',
    action: 'click',
    format: 'textBox',
    component: 'fiscalNumber',
    element: 'introduceTIN',
  })
}

const fiocTaxationTagging = (value) => {
  recordLinkV2({
    categoriaEvento: 'tributacion adicional',
    accionEvento: 'clickLink',
    etiquetaEvento: value,
    action: 'click',
    format: 'link',
    component: 'additionalTaxes',
    element: value === 'añadir' ? 'addAdditionalTaxes' : 'remove',
  })
}

const fiocAddTaxationTagging = () => {
  fiocTaxationTagging('añadir')
}

const fiocRemoveTaxationTagging = () => {
  fiocTaxationTagging('eliminar')
}

const fiocStepTwoViewTagging = () => {
  recordPage({
    nombrePagina: 'renovacion FIOC/paso2/confirmacion',
    tipoPagina: 'hibrida',
    tipoProceso: 'renovacion FIOC',
    pasoProceso: 'paso2',
    detalleProceso: 'confirmacion',
    pageName: 'updateFIOC/step2/confirmation',
    sectionFamily: 'banking',
    sectionCategory: 'services',
    sectionName: 'customer',
    serviceType: 'userInformation',
    interactionType: 'funnel',
    processType: 'updateFIOC',
    processStep: 'step2',
    processDetail: 'confirmation',
  })
}

const fiocIntroduceOTPTagging = () => {
  recordLinkV2({
    categoriaEvento: 'codigo de confirmacion',
    accionEvento: 'clickTextBox',
    etiquetaEvento: 'introduce OTP',
    action: 'click',
    format: 'textBox',
    component: 'OTP',
    element: 'introduceOTP',
  })
}

const fiocRequestNewOTPTagging = () => {
  recordLinkV2({
    categoriaEvento: 'codigo de confirmacion',
    accionEvento: 'clickLink',
    etiquetaEvento: 'solicita uno nuevo',
    action: 'click',
    format: 'link',
    component: 'OTP',
    element: 'requestNewCode',
  })
}

const fiocPreviousButtonTagging = () => {
  recordLinkV2({
    categoriaEvento: 'codigo de confirmacion',
    accionEvento: 'clickButton',
    etiquetaEvento: 'anterior',
    action: 'previous',
    format: 'button',
    component: 'lowerNavigation',
    element: 'previous',
  })
}

const fiocCancelButtonTagging = () => {
  recordLinkV2({
    categoriaEvento: 'codigo de confirmacion',
    accionEvento: 'clickLink',
    etiquetaEvento: 'cancelar',
    action: 'cancel',
    format: 'link',
    component: 'lowerNavigation',
    element: 'cancel',
  })
}

const fiocUpdateButtonTagging = () => {
  recordLinkV2({
    categoriaEvento: 'codigo de confirmacion',
    accionEvento: 'clickButton',
    etiquetaEvento: 'actualizar',
    action: 'click',
    format: 'button',
    component: 'lowerNavigation',
    element: 'update',
  })
}

const fiocOTPErrorTagging = (error) => {
  recordLinkV2({
    categoriaEvento: 'codigo de confirmacion',
    accionEvento: 'OTP - error',
    etiquetaEvento: 'OTP',
    action: 'error',
    format: 'textBox',
    component: 'OTP',
    element: 'confirmationCode',
    errorCategory: 'incorrectCode',
    errorDescription: error,
  })
}

const fiocUpdateDocumentClickTagging = () => {
  recordLinkV2({
    categoriaEvento: 'documentacion actualizada',
    accionEvento: 'clickButton',
    etiquetaEvento: 'actualizar documentacion',
    action: 'click',
    format: 'button',
    component: 'documentationUpdated',
    element: 'updateDocumentation',
  })
}

const fiocUpdateDocumentSuccessTagging = () => {
  recordPage({
    nombrePagina: 'renovacion FIOC/exito',
    tipoPagina: 'hibrida',
    tipoProceso: 'renovacion FIOC',
    pasoProceso: 'exito',
    pageName: 'updateFIOC/success',
    sectionFamily: 'banking',
    sectionCategory: 'services',
    sectionName: 'customer',
    serviceType: 'userInformation',
    interactionType: 'funnel',
    processType: 'updateFIOC',
    processStep: 'success',
  })
}

const fiocUpdateDocumentReturnGlobalTagging = () => {
  recordLinkV2({
    categoriaEvento: 'documentacion actualizada',
    accionEvento: 'clickLink',
    etiquetaEvento: 'volver a datos personales',
    action: 'click',
    format: 'link',
    component: 'documentationUpdated',
    element: 'returnToPersonalData'
  })
}

const fiocUpdateErrorView = (error) => {
  recordPage({
    nombrePagina: 'renovacion FIOC/error',
    tipoPagina: 'hibrida',
    tipoProceso: 'renovacion FIOC',
    pasoProceso: 'error',
    pageName: 'updateFIOC/error',
    sectionFamily: 'banking',
    sectionCategory: 'services',
    sectionName: 'customer',
    serviceType: 'userInformation',
    interactionType: 'funnel',
    processType: 'updateFIOC',
    processStep: 'error',
    errorCategory: 'cantUpdateInformation',
    errorDescription: error,
  })
};

const fiocUpdateActualSituationTagging = (component, value) => {
  if (component.id === 'your_job') {
    const event = yourJobOptions[value];
    recordLinkV2({
      categoriaEvento: 'situacion actual',
      accionEvento: 'seleccion',
      etiquetaEvento: formatText(event.label),
      action: 'click',
      format: 'radioButton',
      component: 'actualSituation',
      element: event.value
    })
  }
}

const fiocCurrentProfessionSelectionTagging = (value) => {
    recordLinkV2({
      categoriaEvento: 'desplegable profesión actual',
      accionEvento: 'seleccion',
      etiquetaEvento: value,
      action: 'select',
      format: 'selectBox',
      component: 'dropdown/currentProfession',
      element: value,
    })
}

const fiocCurrentProfessionDropdownTagging = (value) => {
    recordLinkV2({
      categoriaEvento: 'profesion actual',
      accionEvento: 'dropdown',
      etiquetaEvento: value === 'open' ? 'abrir' : 'cerrar',
      action: value,
      format: 'dropdown',
      component: 'currentProfession',
      element: value,
    })
}

const fiocEconomicActivitySelectionTagging = (value) => {
  recordLinkV2({
    categoriaEvento: 'desplegable actividad economica',
    accionEvento: 'seleccion',
    etiquetaEvento: value, 
    action: 'select',
    format: 'selectBox',
    component: 'dropdown/economicActivity',
    element: value,
  })
}

const fiocEconomicActivityDropdownTagging = (value) => {
  recordLinkV2({
    categoriaEvento: 'actividad economica',
    accionEvento: 'dropdown',
    etiquetaEvento: value === 'open' ? 'abrir' : 'cerrar',
    action: value,
    format: 'dropdown',
    component: 'economicActivity',
    element: value === 'open' ? 'abrir' : 'cerrar',
  })
}

const fiocOpenCancelModalTagging = () => {
  recordLinkV2({
    categoriaEvento: 'modal cancelar actualizar FIOC',
    accionEvento: 'vista',
    action: 'open',
    format: 'modal',
    component: 'modal/cancelUpdateFIOC',
    element: 'full'
  })
}

const fiocCloseCancelModalTagging = () => {
  recordLinkV2({
    categoriaEvento: 'modal cancelar actualizar FIOC',
    accionEvento: 'cerrar',
    action: 'close',
    format: 'cross',
    component: 'modal/cancelUpdateFIOC',
    element: 'close'
  })
}

const fiocLeaveModalTagging = () => {
  recordLinkV2({
    categoriaEvento: 'modal cancelar actualizar FIOC',
    accionEvento: 'clickButton',
    etiquetaEvento: 'si',
    action: 'click',
    format: 'button',
    component: 'modal/cancelUpdateFIOC',
    element: 'yes'
  })
}

const customCheckboxErrorFiocTagging = (id, errorMsg) => {
  if(id === 'income_origin_benefits') {
    recordLinkV2({
      categoriaEvento: 'procedencia de ingresos',
      accionEvento: 'click - error',
      etiquetaEvento: 'selecciona una procedencia de ingresos',
      action: 'error',
      format: 'checkBox',
      component: 'employmentSituation',
      element: 'selectIncome',
      errorCategory: 'mandatoryField',
      errorDescription: errorMsg,
    })
  }

}


module.exports = {
  tagFiocDocumentationStart,
  tagFiocDocumentationSuccess,
  tagFiocPersonalInfoStart,
  tagFiocPersonalInfoSuccess,
  updateFiocRecordView,
  employmentSituationLink,
  dataProtectionLink,
  buttonNextTagging,
  buttonCancelTagging,
  fiocErrorTagging,
  fiocSelectClickTagging,
  fiocSelectProfessionTagging,
  fiocSelctKnowYourCNAE,
  fiocForeignTaxErrorTagging,
  fiocFormErrorTagging,
  fiocCompanyNameTagging,
  fiocSelectTagging,
  fiocSelectProfessionalClickTagging,
  fiocSelectEconomicActivityTagging,
  fiocOKSelectClickTagging,
  fiocCheckboxTagging,
  fiocTooltipHasPepTagging,
  fiocInfoAboutPublicTagging,
  fiocPepNameTagging,
  fiocForeignTaxesTagging,
  fiocFiscalCountryOpenTagging,
  fiocFiscalCountryCloseTagging,
  fiocFiscalCountrySelectedTagging,
  fiocFiscalCountryClickTagging,
  fiocPurposeOfTaxationClickTagging,
  fiocPurposeOfTaxationSelectedTagging,
  fiocTinChangeTagging,
  fiocAddTaxationTagging,
  fiocRemoveTaxationTagging,
  fiocStepTwoViewTagging,
  fiocIntroduceOTPTagging,
  fiocPurposeOfTaxationOpenTagging,
  fiocPurposeOfTaxationCloseTagging,
  fiocRequestNewOTPTagging,
  fiocPreviousButtonTagging,
  fiocCancelButtonTagging,
  fiocUpdateButtonTagging,
  fiocOTPErrorTagging,
  fiocUpdateDocumentClickTagging,
  fiocUpdateDocumentSuccessTagging,
  fiocUpdateDocumentReturnGlobalTagging,
  fiocUpdateErrorView,
  fiocUpdateActualSituationTagging,
  fiocCurrentProfessionSelectionTagging,
  fiocCurrentProfessionDropdownTagging,
  fiocEconomicActivitySelectionTagging,
  fiocEconomicActivityDropdownTagging,
  fiocOpenCancelModalTagging,
  fiocCloseCancelModalTagging,
  fiocLeaveModalTagging,
  customCheckboxErrorFiocTagging,
}
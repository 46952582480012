const raHireManagementPlanTags = (eventTag) => ({
  step3_errorAccountInput: {
    categoriaEvento: "proc-contratos",
    accionEvento: "paso_3_contrato",
    etiquetaEvento: "error:saldo insuficiente",
    categoriaError: "error",
    descripcionError: eventTag ? eventTag.descripcionError : null,
  },
  step4_documentReadFull: {
    categoriaEvento: 'lectura y firma del contrato',
    accionEvento: 'documento',
    etiquetaEvento: 'leido',
  },
  step4_checkBoxShown: {
    categoriaEvento: 'lectura y firma del contrato',
    accionEvento: 'checkbox documento',
    etiquetaEvento: 'vista',
  },
  step4_checkboxChecked: {
    categoriaEvento: 'lectura y firma del contrato',
    accionEvento: 'interaccion en checkbox',
    etiquetaEvento: 'check',
  },
  step4_checkboxUnchecked: {
    categoriaEvento: 'lectura y firma del contrato',
    accionEvento: 'interaccion en checkbox',
    etiquetaEvento: 'uncheck',
  },
});

module.exports = { raHireManagementPlanTags };

const {
    DESCRIPTION_FIELD_REGEX,
    DEFAULT_COUNTRY,
    NUMBER_REGEX,
    ONLY_LETTERS_AND_SPACES_REGEX,
    ONLY_LETTERS_REGEX
} = require('constants/index');
const {
    contractsValidations,
    contractsValidationsErrorMessages
} = require('constants/contractsValidations');
const moment = require('moment');

module.exports = {
    fioc: {
        sector: {
            id_component: 'radio-button',
            id: '###fioc_sector',
            target: '###fioc_sector',
            label: 'contractsView-checkingPayrollAccountSectorLabel',
            type: 'single',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            options: [
                {
                    label: 'contractsView-checkingPayrollAccountPublicSector',
                    id: 1
                },
                {
                    label: 'contractsView-checkingPayrollAccountPrivateSector',
                    id: 2
                }
            ],
            order: 0,
            required: true,
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_IS_REQUIRED_FIELD
                }
            ],
            errorMessage: 'contractsView-radioButtonsRequiredError'
        },
        cnae: {
            id_component: 'radio-button',
            id: '###fioc_cnae',
            target: '###fioc_cnae',
            label: 'contractsView-checkingPayrollAccountCNAELabel',
            type: 'single',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            options: [
                {
                    label: 'contractsView-checkingPayrollAccountCNAEYes',
                    id: 1
                },
                {
                    label: 'contractsView-checkingPayrollAccountCNAENo',
                    id: 2
                }
            ],
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_IS_REQUIRED_FIELD
                }
            ],
            errorMessage: 'contractsView-radioButtonsRequiredError'
        },
        ocupation: {
            id_component: 'radio-button',
            id: '###fioc_ocupation',
            target: '###fioc_ocupation',
            label: 'contractsView-ocupation',
            type: 'single',
            onChangeFn: 'checkingPayrollAccount_onChangeFioc',
            options: [
                {
                    label: 'contractsView-ocupationSelfEmployed',
                    id: 0
                },
                {
                    label: 'contractsView-salaried',
                    id: 1
                },
                {
                    label: 'contractsView-ocupationRentier',
                    id: 2
                },
                {
                    label: 'contractsView-ocupationPensioner',
                    id: 3
                },
                {
                    label: 'contractsView-ocupationWithoutActivity',
                    id: 4
                }
            ],
            order: 3,
            required: true,
            errorMessage: 'contractsView-ocupationRequiredError',
            msgError: 'contractsView-ocupationRequiredError'
        },
        has_pep: {
            id_component: 'radio-button',
            id: '###has_pep',
            target: '###has_pep',
            label: 'contractsView-hasPEPLabel',
            type: 'single',
            options: [
                {
                    label: 'contractsView-hasPEPYes',
                    id: 1
                },
                {
                    label: 'contractsView-hasPEPNo',
                    id: 0
                }
            ],
            order: 1,
            required: true,
            msgError: 'contractsView-listRequiredError',
            tooltip: 'contractsView-hasPepTooltipMsg',
            errorMessage: 'contractsView-listRequiredError'
        },
        has_pep_details: {
            id_component: 'input-safe',
            target: '###has_pep_details',
            id: '###has_pep_details',
            label: 'contractsView-hasPEPDetails',
            type_format: 'text',
            type: 'single',
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            msgError: 'contractsView-listRequiredError',
            errorMessage: 'contractsView-listRequiredError',
            regex: DESCRIPTION_FIELD_REGEX,
            value: '',
            maxLength: 100
        },
        profession: {
            autocompleteLabel: 'contractsView-checkingPayrollProfessionAutocompleteTitle',
            autocompletePlaceholder: 'contractsView-checkingPayrollProfessionAutocompletePlaceholder',
            default_value: '',
            id_component: 'fioc-profession',
            id: '###fioc_profession',
            label: 'contractsView-checkingPayrollProfessionAutocompleteTitle',
            onChangeFn: 'checkingPayrollAccount_handleChange',
            order: 1,
            placeholder: 'contractsView-checkingPayrollProfessionAutocompletePlaceholder',
            required: true,
            searchByCategoryLabel: 'contractsView-checkingPayrollAccountFiocProfessionTitle',
            searchByCategoryPlaceholder: 'constractsView-checkingPayrollProfessionSearchByCategoryPlaceholder',
            searchByCategorySubcategoryLabel: 'contractsView-checkingPayrollAccountFiocProfessionActivityTitle',
            target: '###fioc_profession',
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_IS_REQUIRED_FIELD
                }
            ],
            errorMessage: 'contractsView-professionRequiredError'
        },
        profession_category: {
            id_component: 'combo',
            id: '###profession_category',
            label: 'contractsView-checkingPayrollProfessionCategoryLabel',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            options: [],
            order: 3,
            placeholder: 'contractsView-checkingPayrollProfessionCategoryPlaceholder',
            required: true,
            source: 'checkingPayrollAccount_generalActivities',
            extraClasses: ['col-md-12', 'contract-view__wizard-field-no-left-padding'],
            target: '###profession_category',
            type: 'single',
            loadFromStoreField: 'professionCnaeCategories',
            executeExtraCall: {
                functionName: 'getProfessionCnaeCategoryItem',
                stateKey: 'profession_category'
            },
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_IS_REQUIRED_FIELD
                }
            ],
            errorMessage: 'contractsView-listRequiredError',
        },
        profession_current: {
            id_component: 'profession-current',
            id: '###profession_current',
            label: 'contractsView-checkingPayrollProfessionCurrentLabel',
            onChangeFn: 'checkingPayrollAccount_handleChange',
            order: 1,
            target: '###profession_current',
            required: true,
            default_value: '',
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_IS_REQUIRED_FIELD
                }
            ],
            errorMessage: 'contractsView-listRequiredError'
        },
        profession_sector_categories: {
            executeExtraCall: {
                functionName: 'getSectorItem',
                stateKey: 'profession_sector_categories'
            },
            id_component: 'combo',
            id: '###profession_sector_categories',
            label: 'contractsView-checkingPayrollSectorsLabel',
            loadFromStoreField: 'professionSectorSubcategories', //This specify the store atributte to take the data
            msgError: 'contractsView-listRequiredError',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            options: [],
            order: 3,
            placeholder: 'contractsView-checkingPayrollProfessionCategoryPlaceholder',
            required: true,
            source: 'checkingPayrollAccount_generalActivities',
            target: '###profession_sector_categories',
            type: 'single',
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_IS_REQUIRED_FIELD
                }
            ],
            errorMessage: 'contractsView-listRequiredError'
        },
        profession_sector_specific: {
            id_component: 'combo',
            id: '###profession_sector_specific',
            label: 'contractsView-checkingPayrollSectorsSpecificLabel',
            msgError: 'contractsView-listRequiredError',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            options: [],
            order: 3,
            placeholder: 'contractsView-checkingPayrollProfessionCategoryPlaceholder',
            required: true,
            source: 'checkingPayrollAccount_generalActivities',
            target: '###profession_sector_specific',
            type: 'single',
            loadFromStoreField: 'professionSectorItems',
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_IS_REQUIRED_FIELD
                }
            ],
            errorMessage: 'contractsView-listRequiredError'
        },
        profession_cnae: {
            default_value: '',
            errorMessage: 'contractsView-professionRequiredError',
            extraClasses: ['col-md-12', 'contract-view__wizard-field-no-left-padding'],
            id_component: 'profession-cnae',
            id: '###profession_cnae',
            label: 'contractsView-checkingPayrollProfessionCnaeLabel',
            msgError: 'contractsView-professionRequiredError',
            onChangeFn: 'checkingPayrollAccount_handleChange',
            order: 1,
            required: true,
            target: '###profession_cnae',
            placeholder: 'contractsView-checkingPayrollCnaePlaceholder'
        },
        profession_cno: {
            id_component: 'profession-cno',
            id: '###profession_cno',
            label: 'contractsView-professionCno',
            onChangeFn: 'checkingPayrollAccount_handleChange',
            order: 1,
            target: '###profession_cno',
            required: true,
            msgError: 'contractsView-professionRequiredError',
            errorMessage: 'contractsView-professionRequiredError',
            default_value: ''
        },
        company_name: {
            id_component: 'input-safe',
            id: '###company_name',
            target: '###company_name',
            label: 'contractsView-companyNameLabel',
            type_format: 'text',
            type: 'single',
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            required: true,
            maxLength: 60,
            regex: DESCRIPTION_FIELD_REGEX,
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_COMPANY_NAME_OR_DESCRIPTION
                }
            ],
            size: 3,
            msgError: 'contractsView-companyNameRequiredError',
            errorMessage: 'contractsView-companyNameRequiredError',
            value: ''
        },
        capital_label: {
            id_component: 'label',
            id: '###capital_label',
            target: '###capital_label',
            label: 'contractsView-capitalLabel',
            subLabel: 'contractsView-capitalSubLabel'
        },
        capital_work: {
            id_component: 'checkbox',
            id: '###capital_work',
            target: '###capital_work',
            label: 'contractsView-checkWork',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        capital_activities: {
            id_component: 'checkbox',
            id: '###capital_activities',
            target: '###capital_activities',
            label: 'contractsView-checkActivities',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        capital_heritage: {
            id_component: 'checkbox',
            id: '###capital_heritage',
            target: '###capital_heritage',
            label: 'contractsView-checkHeritage',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        capital_business: {
            id_component: 'checkbox',
            id: '###capital_business',
            target: '###capital_business',
            label: 'contractsView-checkBusiness',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        capital_realState: {
            id_component: 'checkbox',
            id: '###capital_realState',
            target: '###capital_realState',
            label: 'contractsView-checkRealState',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        capital_gambling: {
            id_component: 'checkbox',
            id: '###capital_gambling',
            target: '###capital_gambling',
            label: 'contractsView-checkGambling',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        capital_other: {
            id_component: 'checkbox',
            id: '###capital_other',
            target: '###capital_other',
            label: 'contractsView-checkOther',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        capital_description: {
            id_component: 'input-safe',
            id: '###capital_description',
            target: '###capital_description',
            label: 'contractsView-capitalDescription',
            type_format: 'text',
            type: 'single',
            required: true,
            regex: DESCRIPTION_FIELD_REGEX,
            maxLength: 40,
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_COMPANY_NAME_OR_DESCRIPTION
                }
            ],
            msgError: 'contractsView-requiredFieldError',
            errorMessage: 'contractsView-requiredFieldError',
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            value: ''
        },
        income_label: {
            id_component: 'label',
            id: '###income_label',
            target: '###income_label',
            label: 'contractsView-incomeLabel',
            subLabel: 'contractsView-incomeSubLabel'
        },
        incomes_annual: {
            id_component: 'combo',
            id: '###incomes_annual',
            target: '###incomes_annual',
            label: 'contractsView-incomesLabelStatement',
            type: 'single',
            placeholder: 'contractsView-combosPlaceholder',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            source: 'checkingPayrollAccount_loadIncomeAmounts',
            options: [],
            order: 1,
            required: true,
            msgError: 'contractsView-listRequiredError',
            errorMessage: 'contractsView-listRequiredError'
        },
        operationsLabel: {
            id_component: 'radio-button',
            id: '###operations_account',
            target: '###operations_account',
            label: 'contractsView-operationsLabel',
            type: 'single',
            options: [
                {
                    label: 'contractsView-hasPEPYes',
                    id: 1
                },
                {
                    label: 'contractsView-hasPEPNo',
                    id: 0
                }
            ],
            order: 1,
            required: true,
            msgError: 'contractsView-listRequiredError',
            errorMessage: 'contractsView-listRequiredError'
        },
        income_work: {
            id_component: 'checkbox',
            id: '###income_work',
            target: '###income_work',
            label: 'contractsView-checkWork',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        income_activities: {
            id_component: 'checkbox',
            id: '###income_activities',
            target: '###income_activities',
            label: 'contractsView-checkActivities',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        income_business: {
            id_component: 'checkbox',
            id: '###income_business',
            target: '###income_business',
            label: 'contractsView-checkBusiness',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        income_realState: {
            id_component: 'checkbox',
            id: '###income_realState',
            target: '###income_realState',
            label: 'contractsView-checkRealState',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        income_other: {
            id_component: 'checkbox',
            id: '###income_other',
            target: '###income_other',
            label: 'contractsView-checkOther',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        income_description: {
            id_component: 'input-safe',
            id: '###income_description',
            target: '###income_description',
            label: 'contractsView-incomeDescription',
            type_format: 'text',
            type: 'single',
            required: true,
            regex: DESCRIPTION_FIELD_REGEX,
            maxLength: 40,
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_COMPANY_NAME_OR_DESCRIPTION
                }
            ],
            msgError: 'contractsView-requiredFieldError',
            errorMessage: 'contractsView-requiredFieldError',
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            value: ''
        },
        account_use: {
            id_component: 'radio-button',
            id: '###account_use',
            target: '###account_use',
            label: 'contractsView-accountUseLabel',
            type: 'single',
            options: [
                {
                    label: 'contractsView-accountUsePersonal',
                    id: 1 // personal
                },
                {
                    label: 'contractsView-accountUseProfessional',
                    id: 2 // professional
                },
                {
                    label: 'contractsView-accountUseOther',
                    id: 3 // investment
                }
            ],
            order: 1,
            required: true,
            msgError: 'contractsView-listRequiredError',
            errorMessage: 'contractsView-listRequiredError'
        },
        account_use_2: {
            id_component: 'radio-button',
            id: '###account_use_2',
            target: '###account_use_2',
            label: 'contractsView-accountUseLabel',
            type: 'single',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            options: [
                {
                    label: 'contractsView-accountUsePersonal',
                    id: 1 // personal
                },
                {
                    label: 'contractsView-accountUseOther',
                    id: 3 // investment
                }
            ],
            order: 1,
            required: true,
            msgError: 'contractsView-listRequiredError',
            errorMessage: 'contractsView-listRequiredError'
        },
        account_use_operation_label: {
            id_component: 'label',
            id: '###account_use_operation_label',
            target: '###account_use_operation_label',
            label: 'contractsView-accountUseOperationLabel',
            subLabel: 'contractsView-accountUseOperationSubLabel',
            uncheckElements: ['###noneOfTheAbove']
        },
        operations_dangerous_countries: {
            id_component: 'checkbox',
            id: '###operations_dangerous_countries',
            target: '###operations_dangerous_countries',
            label: 'contractsView-accountUseOperationsDangerousCountries',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            uncheckElements: ['###none_of_the_above'],
            validateGroup: true
        },
        checks_holder: {
            id_component: 'checkbox',
            id: '###checks_holder',
            target: '###checks_holder',
            label: 'contractsView-accountUseChecksHolder',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            uncheckElements: ['###none_of_the_above'],
            validateGroup: true
        },
        checks_traveler: {
            id_component: 'checkbox',
            id: '###checks_traveler',
            target: '###checks_traveler',
            label: 'contractsView-accountUseChecksTraveler',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            uncheckElements: ['###none_of_the_above'],
            validateGroup: true
        },
        delivery_or_cash_withdrawals: {
            id_component: 'checkbox',
            id: '###delivery_or_cash_withdrawals',
            target: '###delivery_or_cash_withdrawals',
            label: 'contractsView-accountUseDeliveryOrCashWithdrawals',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            uncheckElements: ['###none_of_the_above'],
            validateGroup: true
        },
        buying_and_selling_foreing_banknotes: {
            id_component: 'checkbox',
            id: '###buying_and_selling_foreing_banknotes',
            target: '###buying_and_selling_foreing_banknotes',
            label: 'contractsView-accountUseBuyingAndSellingForeingBanknotes',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            uncheckElements: ['###none_of_the_above'],
            validateGroup: true
        },
        payment_orders_or_collection_abroad: {
            id_component: 'checkbox',
            id: '###payment_orders_or_collection_abroad',
            target: '###payment_orders_or_collection_abroad',
            label: 'contractsView-accountUsePaymentOrdersOrCollectionAbroad',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            uncheckElements: ['###none_of_the_above'],
            validateGroup: true
        },
        foreing_trade_operations: {
            id_component: 'checkbox',
            id: '###foreing_trade_operations',
            target: '###foreing_trade_operations',
            label: 'contractsView-accountUseForeingTradeOperations',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            uncheckElements: ['###none_of_the_above'],
            validateGroup: true
        },
        none_of_the_above: {
            id_component: 'checkbox',
            id: '###none_of_the_above',
            target: '###none_of_the_above',
            label: 'contractsView-accountUseNoneOfTheAbove',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            uncheckElements: [
                '###operations_dangerous_countries',
                '###checks_holder',
                '###checks_traveler',
                '###delivery_or_cash_withdrawals',
                '###buying_and_selling_foreing_banknotes',
                '###payment_orders_or_collection_abroad',
                '###foreing_trade_operations'
            ],
            validateGroup: true
        },
        activities: {
            id_component: 'combo',
            id: '###activities',
            label: 'contractsView-activitiesLabel',
            type: 'single',
            placeholder: 'contractsView-activitiesPlaceholder',
            source: 'checkingPayrollAccount_generalActivities',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            target: '###activities',
            options: [],
            order: 3,
            required: true,
            msgError: 'contractsView-listRequiredError',
            errorMessage: 'contractsView-listRequiredError'
        },
        foreingTax: {
            id_component: 'foreign-tax',
            id: '###foreign_tax',
            target: '###foreign_tax',
            source: 'checkingPayrollAccount_loadForeignTaxRadioOptions',
            taxCountrySource: 'checkingPayrollAccount_loadTaxCountryCmb',
            imtSource: 'checkingPayrollAccount_loadImtCmb',
            value: []
        },
        your_job: {
            id_component: 'radio-button',
            id: '###your_job',
            target: '###your_job',
            label: 'contractsView-yourJob',
            type: 'single',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            options: [
                {
                    label: 'contractsView-homeWork',
                    id: 1
                },
                {
                    label: 'contractsView-unemployed',
                    id: 2
                },
                {
                    label: 'contractsView-student',
                    id: 0
                }
            ],
            order: 1,
            required: true,
            msgError: 'contractsView-listRequiredError',
            errorMessage: 'contractsView-listRequiredError'
        },
        income_origin_label: {
            id_component: 'label',
            id: '###income_origin_label',
            target: '###income_origin_label',
            label: 'fiocSection-incomeOriginLabel',
            subLabel: 'fiocSection-incomeOriginSubLabel'
        },
        income_origin_tooltip: {
            id_component: 'tooltip',
            id: '###income_origin_tooltip',
            type: 'tooltip',
            label: 'fiocSection-mbMessageNewQuestionTitleFioc',
            subLabel: 'fiocSection-mbMessageNewQuestionBodyFioc',
        },
        income_origin_benefits: {
            id_component: 'checkbox',
            id: '###income_origin_benefits',
            target: '###income_origin_benefits',
            label: 'fiocSection-incomeOriginCheckbox1',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        income_origin_family_help: {
            id_component: 'checkbox',
            id: '###income_origin_family_help',
            target: '###income_origin_family_help',
            label: 'fiocSection-incomeOriginCheckbox2',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        income_origin_financial_investments: {
            id_component: 'checkbox',
            id: '###income_origin_financial_investments',
            target: '###income_origin_financial_investments',
            label: 'fiocSection-incomeOriginCheckbox3',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
        income_origin_investment_property: {
            id_component: 'checkbox',
            id: '###income_origin_investment_property',
            target: '###income_origin_investment_property',
            label: 'fiocSection-incomeOriginCheckbox4',
            onChangeFn: 'checkingPayrollAccount_handleCheck',
            validateGroup: true
        },
    },
    personalData: {
        full_name: {
            id_component: 'full_name',
            id: '###full_name',
            target: '###full_name',
            label: 'contractsView-fullName',
            msg_error: 'contractsView-requiredFieldError',
            value: '',
            regex: ONLY_LETTERS_REGEX
        },
        document_type: {
            id_component: 'input-safe',
            id: '###document_type',
            target: '###document_type',
            label: 'contractsView-documentType',
            type_format: 'text',
            type: 'single',
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            msg_error: 'contractsView-requiredFieldError',
            value: '',
            disabled: true
        },
        document_number: {
            id_component: 'input-safe',
            id: '###document_number',
            target: '###document_number',
            label: 'contractsView-documentNumber',
            type_format: 'text',
            type: 'single',
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            msg_error: 'contractsView-requiredFieldError',
            value: '',
            disabled: true
        },
        birthdate: {
            id_component: 'date-picker',
            id: '###birthdate',
            target: '###birthdate',
            label: 'contractsView-birthdate',
            type_format: 'text',
            type: 'single',
            onChangeFn: 'checkingPayrollAccount_handleDatePickerCallback',
            default_value: moment().subtract(18, 'years'),
            yearsDropDown: true,
            maxDate: {
                amount: 18,
                type: 'years'
            },
            minDate: {
                amount: 118,
                type: 'years'
            },
            required: true,
            errorMessage:
                contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DATE_CHECKING_PAYROLL,
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_DATE
                }
            ]
        },
        country_of_birth: {
            id_component: "combo",
            id: "###country_of_birth",
            target: "###country_of_birth",
            label: "contractsView-countryOfBirth",
            type_format: "text",
            type: "single",
            source: "checkingPayrollAccount_loadCmbNationality",
            addFunction: {
                lib: "checkingCountryOfBirthPayrollAccount_onChangeSelect",
                element: "holder_1_province_of_birth",
                type: "openModal",
                value: DEFAULT_COUNTRY,
            },
            onBlurFn: true,
            onBlurValidateFn: 'checkingPayrollAccount_onBlurValidateCondition',
            onChangeFn: "checkingPayrollAccount_onChangeCmbPurpose",
            placeholder: "contractsView-juniorAccountCombosPlaceholder",
            size: 4,
            required: true,
            default_value: "",
            errorMessage:
                contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_CHECKING_PAYROLL,
        },
        province_of_birth: {
            id_component: "combo",
            id: "###province_of_birth",
            target: "###province_of_birth",
            label: "contractsView-provinceOfBirth",
            type: "single",
            placeholder: "contractsView-combosPlaceholder",
            onChangeFn: "checkingPayrollAccount_onChangeCmbPurpose",
            source: "checkingPayrollAccount_loadCmbProvince",
            options: [],
            order: 3,
            default_value: "",
            size: 4,
            required: true,
            extraValidateComponents: ["postal_code"],
            errorMessage:
                contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_CHECKING_PAYROLL,
        },
        town_of_birth: {
            id_component: "input-safe",
            target: "###town_of_birth",
            id: "###town_of_birth",
            label: "contractsView-townOfBirth",
            type_format: "text",
            type: "single",
            regex: ONLY_LETTERS_AND_SPACES_REGEX,
            maxLength: 40,
            onBlurFn: "checkingPayrollAccount_handleBlur",
            size: 4,
            required: true,
        },
        resident_country: {
            id_component: 'combo',
            id: '###resident_country',
            target: '###resident_country',
            label: 'contractsView-residentCountry',
            type_format: 'text',
            type: 'single',
            source: 'checkingPayrollAccount_loadCmbNationality',
            addFunction: {
                lib: 'checkingPayrollAccount_onChangeSelect',
                element: 'resident_country',
                type: 'openModal',
                value: DEFAULT_COUNTRY
            },
            onBlurFn: true,
            onBlurValidateFn: 'checkingPayrollAccount_onBlurValidateCondition',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            openModal: false,
            skipImmediateValidation: true,
            placeholder: 'contractsView-juniorAccountCombosPlaceholder',
            size: 4,
            required: true,
            default_value: '',
            errorMessage:
                contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_CHECKING_PAYROLL
        }
    },
    contactData: {
        email: {
            id_component: 'input-safe',
            target: '###email',
            id: '###email',
            label: 'contractsView-email',
            type_format: 'text',
            type: 'single',
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            required: true,
            maxLength: 200,
            errorMessage: contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_EMAIL,
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_EMAIL
                }
            ],
            value: ''
        },
        mobile_phone: {
            id_component: 'phone-line',
            target: '###mobile_phone',
            id: '###mobile_phone',
            label: 'contractsView-mobilePhoneLabel',
            type_format: 'text',
            type: 'single',
            maxLength: 14,
            regex: NUMBER_REGEX,
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            required: true,
            errorMessage:
                contractsValidationsErrorMessages.ERROR_MESSAGE_PHONE_NUMBER_CHECKING_PAYROLL,
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_PHONE_NUMBER,
                    errorMessage:
                        contractsValidationsErrorMessages.ERROR_MESSAGE_PHONE_NUMBER_CHECKING_PAYROLL
                }
            ]
        },
        address_label: {
            id_component: 'special-label',
            id: '###address_label',
            target: '###address_label',
            label: 'contractsView-addressLabel'
        },
        type_of_road: {
            id_component: 'combo',
            id: '###type_of_road',
            target: '###type_of_road',
            label: 'contractsView-typeOfRoad',
            type: 'single',
            placeholder: 'contractsView-combosPlaceholder',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            source: 'checkingPayrollAccount_loadTypeOfRoad',
            options: [],
            order: 3,
            default_value: 'CL',
            size: 4,
            required: true,
            errorMessage:
                contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_CHECKING_PAYROLL
        },
        road_name: {
            id_component: 'input-safe',
            target: '###road_name',
            id: '###road_name',
            label: 'contractsView-roadName',
            type_format: 'text',
            type: 'single',
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            onChangeRoadNameFn: 'checkingPayrollAccount_handleChangeRoadName',
            maxLength: 45,
            size: 8,
            required: true
        },
        address_number: {
            id_component: 'input-safe',
            target: '###address_number',
            id: '###address_number',
            label: 'contractsView-addressNumber',
            type_format: 'text',
            type: 'single',
            maxLength: 3,
            regex: NUMBER_REGEX,
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            size: 4,
            required: true
        },
        address_others: {
            id_component: 'input-safe',
            target: '###address_others',
            id: '###address_others',
            label: 'contractsView-addressOthers',
            type_format: 'text',
            type: 'single',
            msgError: contractsValidationsErrorMessages.ERROR_MESSAGE_ADDRESS_OTHERS,
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            size: 8
        },
        country: {
            id_component: 'input-safe',
            target: '###country',
            id: '###country',
            label: 'contractsView-country',
            type_format: 'text',
            type: 'single',
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            //The following Line is bad, I cannot think of a way to make all of this non-static at the moment. Ale de la Viña
            //OKFRONT-6872
            default_value: 'España',
            disabled: true
        },
        province: {
            id_component: 'combo',
            id: '###province',
            target: '###province',
            label: 'contractsView-province',
            type: 'single',
            placeholder: 'contractsView-combosPlaceholder',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            source: 'checkingPayrollAccount_loadCmbProvince',
            options: [],
            order: 3,
            default_value: '',
            size: 4,
            required: true,
            extraValidateComponents: ['postal_code'],
            errorMessage:
                contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_CHECKING_PAYROLL
        },
        town: {
            id_component: 'input-safe',
            target: '###town',
            id: '###town',
            label: 'contractsView-town',
            type_format: 'text',
            type: 'single',
            regex: ONLY_LETTERS_AND_SPACES_REGEX,
            maxLength: 40,
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            size: 4,
            required: true
        },
        postal_code: {
            id_component: 'input-safe',
            target: '###postal_code',
            id: '###postal_code',
            label: 'contractsView-postalCode',
            type_format: 'text',
            type: 'single',
            onBlurFn: 'checkingPayrollAccount_handleBlur',
            size: 4,
            required: true,
            regex: NUMBER_REGEX,
            maxLength: 5,
            // buildCondition: 'checkingAndPayrollAccount_isHolder', THIS WILL STAY COMMENTED UNTIL THE POSTAL CODE ISN'T REQUIRED FOR 'AUTORIZADO' HIRING
            validations: [
                {
                    validationFunction: contractsValidations.VALIDATE_POSTAL_CODE,
                    errorMessage: contractsValidationsErrorMessages.ERROR_MESSAGE_IS_REQUIRED_FIELD
                },
                {
                    associatedComponent: {
                        id: 'province',
                        type: 'single'
                    },
                    validationFunction:
                        contractsValidations.VALIDATE_POSTAL_CODE_PROVINCE_CHECKING_PAYROLL,
                    errorMessage: contractsValidationsErrorMessages.VALIDATE_POSTAL_CODE_PROVINCE
                }
            ]
        }
    },
    generalData: {
        gender: {
            id_component: 'radio-button',
            id: '###gender',
            target: '###gender',
            label: 'contractsView-juniorAccountFormGenre',
            type: 'single',
            options: [
                {
                    label: 'contractsView-juniorAccountFormGenreFemale',
                    id: 1
                },
                {
                    label: 'contractsView-juniorAccountFormGenreMale',
                    id: 0
                }
            ],
            order: 3,
            size: 4,
            default_value: 0,
            required: true,
            errorMessage:
                contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_RADIO_BUTTON_CHECKING_PAYROLL
        },
        nationality: {
            id_component: 'combo',
            id: '###nationality',
            target: '###nationality',
            label: 'contractsView-juniorAccountFormNationality',
            type: 'single',
            placeholder: 'contractsView-juniorAccountCombosPlaceholder',
            source: 'checkingPayrollAccount_loadCmbNationality',
            options: [],
            order: 3,
            msg_error: 'contractsView-listRequiredError',
            default_value: 'ES', //@hardcoded
            disabled: true,
            size: 4,
            visible: {
                target: '###document_type',
                value: ['DNI']
            }
        },
        nationality_other: {
            id_component: 'combo',
            id: '###nationality_other',
            target: '###nationality_other',
            label: 'contractsView-juniorAccountFormNationality',
            type: 'single',
            placeholder: 'contractsView-combosPlaceholder',
            onChangeFn: 'checkingPayrollAccount_onChangeCmbPurpose',
            source: 'checkingPayrollAccount_loadCmbNationalityWithSpainDisabled',
            options: [],
            order: 3,
            required: true,
            errorMessage:
                contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_CHECKING_PAYROLL,
            visible: {
                target: '###document_type',
                value: ['NIE', 'Tarjeta de Residencia']
            },
            size: 4
        }
    },
    anotherAccount: {
        input_iban: {
            id_component: 'input-iban',
            id: '###input_iban',
            target: '###input_iban',
            label: 'contractsView-anotherAccountIbanDescriptions',
            labelYes: 'contractsView-anotherAccountIbanYes',
            labelNo: 'contractsView-anotherAccountIbanNo',
            default_value: null,
            msg_error:
                contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_RADIO_BUTTON_CHECKING_PAYROLL
        }
    }
};
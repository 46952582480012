// @ vendors
const moment = require('moment');
const cloneDeep = require('lodash/lang/cloneDeep');
// @ utilites
const { formatText } = require('core/i18n').i18n;
const formHelper = require('./formHelper');
const { alphanumericRegExp } = require('commonsUtilities/validations');
const mockDataHelper = require('./mockDataHelper');
import { fiocFormErrorTagging, fiocCompanyNameTagging } from 'utilities/taggingInfo/fiocTags';
// @ constants
const {
    DEFAULT_COUNTRY,
    DOCUMENT_NUMBER_NIE_REGEX,
    DOCUMENT_NUMBER_NIF_PASSPORT_REGEX,
    DOCUMENT_TYPE_NIE,
    DOCUMENT_TYPE_NIE_CITIZEN_REGISTRATION,
    DOCUMENT_TYPE_NIE_RESIDENT_CARD,
    DOCUMENT_TYPE_NIF,
    DOCUMENT_TYPE_PASSPORT,
    EMAIL_REGEX,
    PHONE_NUMBER_REGEX,
    PHONE_NUMBER_START_DIGIT,
    POSTAL_CODE_REGEX
} = require('constants/index');
const { contractsValidationsErrorMessages } = require('constants/contractsValidations');
const ERROR_MESSAGES = {
    'date-picker': contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DATE,
    'dropdown-accounts': contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS,
    'input-number-documents': contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DOCUMENT_NUMBER,
    'method-of-payment': contractsValidationsErrorMessages.ERROR_MESSAGE_METHOD_OF_PAYMENT
};

function setError (shouldSetError, isElementValid, message, validation) {
    shouldSetError && fiocFormErrorTagging({ id: validation.id ,message: formatText(message) });
    isElementValid && isElementValid.element && isElementValid.element && 'company_name'
    
    isElementValid.errorMessage = shouldSetError ?
        validation.errorMessage || message :
        null;
    return !shouldSetError;
}

function updateCardDetailElementError (isElementValid) {
    const { element, errorMessage } = isElementValid;

    element.isErrorVisible = !!errorMessage;
    element.msgError = errorMessage;
}

function validateEmail (isElementValid, component, validation) {
    return setError(
        !EMAIL_REGEX.test(isElementValid.element.value),
        isElementValid,
        contractsValidationsErrorMessages.ERROR_MESSAGE_EMAIL,
        validation
    );
}

function isFieldEmpty (element) {
    let isEmpty = true;
    if (element) {
        isEmpty = Array.isArray(element.value) ?
            !element.value.length :
            !element.value && element.value !== 0;
    }
    return isEmpty;
}

function validatePostalCode (isElementValid, component, validation) {
    return setError(
        !POSTAL_CODE_REGEX.test(isElementValid.element.value),
        isElementValid,
        contractsValidationsErrorMessages.ERROR_MESSAGE_POSTAL_CODE,
        validation
    );
}

function isValidPostalCodeProvince (postalCode, province) {
    let isValidPostalCodeProvince = true;

    if (province) {
        isValidPostalCodeProvince = province === postalCode.substring(0,2);
    }

    return isValidPostalCodeProvince;
}

function validatePostalCodeProvince (isElementValid, component, validation) {
    const province = formHelper.getField(isElementValid.cardDetails, validation.associatedComponent);

    return setError(
        !isValidPostalCodeProvince(isElementValid.element.value, province.value),
        isElementValid,
        contractsValidationsErrorMessages.ERROR_MESSAGE_POSTAL_CODE_PROVINCE,
        validation
    );
}

const validateDescriptionsOrCompany = (isElementValid, component, validation) => {
    const inputsErrorMsgs = {
        "capital_description": contractsValidationsErrorMessages.ERROR_MESSAGE_INVALID_CAPITAL_DESCRIPTION,
        "income_description": contractsValidationsErrorMessages.ERROR_MESSAGE_INVALID_INCOME_DESCRIPTION,
        "company_name": contractsValidationsErrorMessages.ERROR_MESSAGE_INVALID_COMPANY_NAME
    }

    const shouldSetError = !alphanumericRegExp.test(isElementValid.element.value);
    !shouldSetError && fiocCompanyNameTagging({ id: component.id, value: isElementValid.element.value });

    return setError(
        shouldSetError, 
        isElementValid, 
        inputsErrorMsgs[component.id], 
        validation
    )
}


function validatePostalCodeProvinceCheckingPayroll (isElementValid, component, validation) {
    let cloneValidation = cloneDeep(validation);
    cloneValidation.associatedComponent.id = `${component.id.substring(0,9)}${cloneValidation.associatedComponent.id}`

    const associatedComponent = formHelper.getField(isElementValid.cardDetails, cloneValidation.associatedComponent);

    return setError(
        !isValidPostalCodeProvince(isElementValid.element.value, associatedComponent.value),
        isElementValid,
        contractsValidationsErrorMessages.ERROR_MESSAGE_POSTAL_CODE_PROVINCE,
        validation
    );
}

function validateDocumentType (isElementValid, component, validation) {
    let documentType = formHelper.getField(isElementValid.cardDetails, validation.associatedComponent);

    documentType.value = isElementValid.element.value === DEFAULT_COUNTRY ? DOCUMENT_TYPE_NIF : DOCUMENT_TYPE_NIE;
}

// If NIE is selected and the selected country is Spain, it erases the country selection.
// If NIE is selected and the selected country is different than Spain, it keeps the country selection.
// If NIF document type is selected, then Spain is selected in the country field.
function validateDocumentCountry (isElementValid, component, validation) {
    let birthCountry = formHelper.getField(isElementValid.cardDetails, validation.associatedComponent);
    const nieCountry = birthCountry.value === DEFAULT_COUNTRY ? '' : birthCountry.value;
    birthCountry.value = isElementValid.element.value === DOCUMENT_TYPE_NIF ? DEFAULT_COUNTRY : nieCountry;
}

function isValidDocumentNumberOfType (documentNumber, documentType) {
    let isValidDocumentNumber = false;
    const upperCaseDocumentNumber = documentNumber && documentNumber.toString().toUpperCase();
    const isNifOrPassport = [DOCUMENT_TYPE_NIF, DOCUMENT_TYPE_PASSPORT];
    const isNie = [DOCUMENT_TYPE_NIE, DOCUMENT_TYPE_NIE_RESIDENT_CARD, DOCUMENT_TYPE_NIE_CITIZEN_REGISTRATION];

    if (isNifOrPassport.includes(documentType)) {
        isValidDocumentNumber = DOCUMENT_NUMBER_NIF_PASSPORT_REGEX.test(upperCaseDocumentNumber);
    } else if (isNie.includes(documentType)) {
        isValidDocumentNumber = DOCUMENT_NUMBER_NIE_REGEX.test(upperCaseDocumentNumber);
    }

    return isValidDocumentNumber;
}

function validateDocumentNumber (isElementValid, component, validation) {
    const typeInputName = formHelper.getField(isElementValid.cardDetails, validation.associatedComponent);

    return setError(
        !isValidDocumentNumberOfType(isElementValid.element.value, typeInputName.value),
        isElementValid,
        contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DOCUMENT_NUMBER,
        validation
    );
}

function validateExistingOYCardDocumentNumber (isElementValid, component, validation) {
    const updatedDocumentNumber = isElementValid.cardDetails.find(cardDetailsItem => cardDetailsItem.id === isElementValid.element.id);
    return setError(
        updatedDocumentNumber.isErrorVisible,
        isElementValid,
        contractsValidationsErrorMessages.ERROR_MESSAGE_EXISTING_OYCARD_DOCUMENT_NUMBER,
        validation
    );
}

function validateDocumentTypeNotEmpty (isElementValid, component, validation) {
    const documentNumber = formHelper.getField(isElementValid.cardDetails, validation.associatedComponent);
    const cardDetails = isElementValid.cardDetails;
    let isValid = true;

    if (documentNumber.value) {
        let isDocumentTypeValid = {
            cardDetails,
            errorMessage: null,
            element: formHelper.getField(cardDetails, validation.associatedComponent)
        };

        isValid = setError(
            !isValidDocumentNumberOfType(documentNumber.value, isElementValid.element.value),
            isDocumentTypeValid,
            contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DOCUMENT_NUMBER,
            validation
        );

        updateCardDetailElementError(isDocumentTypeValid);
    }

    return isValid;
}

function validateIsRequiredField (isElementValid, component) {
    const errorMessage = ERROR_MESSAGES[component.id] || ERROR_MESSAGES[component.id_component] ||
        contractsValidationsErrorMessages.ERROR_MESSAGE_IS_REQUIRED_FIELD;

    return setError(
        isFieldEmpty(isElementValid.element),
        isElementValid,
        errorMessage,
        component
    );
}

function validateDate (isElementValid, component, validation) {
    return setError(
        isElementValid.element.value.isSame(moment(), 'day'),
        isElementValid,
        contractsValidationsErrorMessages.ERROR_MESSAGE_DATE,
        validation
    );
}

function validatePhoneNumber (isElementValid, component, validation) {
    const elementValue = isElementValid.element.value;
    let errorMessage = contractsValidationsErrorMessages.ERROR_MESSAGE_PHONE_NUMBER;
    if(PHONE_NUMBER_START_DIGIT.test(elementValue)) {
        errorMessage = contractsValidationsErrorMessages.ERROR_MESSAGE_PHONE_NUMBER_INCOMPLETE;
    }
    return setError(
        !PHONE_NUMBER_REGEX.test(elementValue),
        isElementValid,
        errorMessage,
        validation
    );
}

function validateMethodOfPayment (isElementValid, component, validation) {
    const methodOfPaymentValue = isElementValid.element ? isElementValid.element.value : null;
    const methodOfPaymentType = methodOfPaymentValue ? methodOfPaymentValue.methodOfPayment : null;
    const fixedFeeAmount = methodOfPaymentValue ? methodOfPaymentValue.fixedFeeAmount : null;
    const percentageAmount = methodOfPaymentValue ? methodOfPaymentValue.percentageAmount : null;
    const { FIXED_FEE, PERCENTAGE } = mockDataHelper.methodOfPaymentValues;

    return setError(
        !methodOfPaymentValue || (methodOfPaymentType === FIXED_FEE && !fixedFeeAmount) || (methodOfPaymentType === PERCENTAGE && !percentageAmount),
        isElementValid,
        contractsValidationsErrorMessages.ERROR_MESSAGE_METHOD_OF_PAYMENT_FEE_AMOUNT,
        validation
    );
 }

 function validateExistingOYAccountDocumentNumber (isElementValid, component, validation) {
    const updatedDocumentNumber = isElementValid.cardDetails.find(cardDetailsItem => cardDetailsItem.id === isElementValid.element.id);
    return setError(
        updatedDocumentNumber.isErrorVisible,
        isElementValid,
        contractsValidationsErrorMessages.ERROR_MESSAGE_EXISTING_OYACCOUNT_DOCUMENT_NUMBER,
        validation
    );
}

module.exports = {
    isFieldEmpty,
    isValidDocumentNumberOfType,
    updateCardDetailElementError,
    validateDate,
    validateDocumentCountry,
    validateDocumentNumber,
    validateDocumentType,
    validateDocumentTypeNotEmpty,
    validateEmail,
    validateExistingOYAccountDocumentNumber,
    validateExistingOYCardDocumentNumber,
    validateIsRequiredField,
    validateMethodOfPayment,
    validatePhoneNumber,
    validatePostalCode,
    validatePostalCodeProvince,
    validatePostalCodeProvinceCheckingPayroll,
    validateDescriptionsOrCompany
}

const { contractsValidationsErrorMessages } = require('constants/contractsValidations');
const {
    EURO_SYMBOL,
    INTERVENER_LABEL_HOLDER,
    INTERVENER_LABEL_AUTHORIZED,
    INTERVENER_LABEL_EMPOWERED
} = require('constants/index');

module.exports = {
    title: 'contractsView-depositWNMStep2Title',
    order: 2,
    footer_text: '',
    disableNext: 'depositpayroll_nextButtonDisabled',
    position: 'middle',
    components:
        [
            {
                id_component: 'dropdown-deposits',
                target: 'installment',
                label: 'contractsView-depositWNMdepositsDropdownLabel',
                placeholder: 'contractsView-depositWNMdepositsDropdownPlaceholder',
                required: true,
                refreshFunction: 'refreshMainBalanceNoNewMoneyDepositHireInformationDepositDropdown',
                extraClasses: ['col-md-12', 'contract-view__wizard-field-no-left-padding'],
            },
            {
                id_component: 'dropdown-accounts',
                label: 'contractsView-depositWNMStep2Account',
                options: [],
                type: 'single',
                target: 'cta_seleccionada',
                targetIntervener: 'intervinientes_list',
                intervenersSpecial: true,
                isIntervenerAvailable: true,
                renderInterveners: 'depositpayroll_shouldBuild',
                isMaxAmountLabelAvailable: false,
                intervenersHeader: 'contractsView-depositWNMIntervenersHeader',
                maxAmountValue: 0,
                required: true,
                errorMessage: contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_WNM,
                accountChangeExtraFn: 'refreshMainBonifiedNoNewMoneyDepositHireInformation',
                intervenersHierarchicalTypeSelection: true,
                intervenerOptions: [
                    INTERVENER_LABEL_HOLDER,
                    INTERVENER_LABEL_AUTHORIZED,
                    INTERVENER_LABEL_EMPOWERED
                ],
                showIntervenerControls: true,
                restoreDefaultIntervenersEnabled: true,
                buildCondition: 'depositpayroll_dropdwonAccountShouldBuild',
                showSavingsAccountsMessage: true,
            },
            {
                id_component: 'import-amount-input',
                id: 'import-input',
                label: 'contractsView-depositWNMStep2Amount',
                subLabel: null,
                type: 'form',
                target: 'monto_traspasar',
                onChangeFn: 'depositpayroll_onChangeAmount',
                importMin: 0,
                importMax: 0,
                inputType: 'float',
                amountZeroAlertVisible: false,
                extraValueValidation: true,
                source: 'depositpayroll_loadAmountInputAmounts',
                subLabelBuildCondition: 'depositpayroll_shouldInputSubLabelBuild',
                defaultValue: 1,
                order: 1,
                infoMessageComplete: 'contractsView-depositWNMStep2ImportInputComplete',
                infoMessageMinimum: 'contractsView-depositWNMStep2ImportInputMin',
                format_type: EURO_SYMBOL,
                buildCondition: 'depositpayroll_shouldBuild',
                errorMessage: 'contractsView-depositWNMStep2ImportInputErrorMessage',
            },
            {
                id_component: 'interest-band'
            },
            {
                id_component: 'disclaimer-personal-info',
                messageKey: 'depositWNMMsg',
                linkTextKey: 'depositWNMLinkText',
                linkUrlKey: 'depositWNMLinkUrl'
            }
        ]
};

const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    isFetching: false,
    error: false,
    warrantChart: [],
    underlyingChart: [],
    isfetchingCatalogue: false,
    fetchedCatalogue: false,
    stockName: '',
    provider: ''
});

function brokerWarrantsProductFileReducer(state = initialState, action) {
    switch (action.type) {
      case actionTypes.BROKER_GET_HISTORICAL_FETCHING:
        return state.set('isFetching', true).set('error', false);
      case actionTypes.BROKER_GET_HISTORICAL_FAILURE:
        return state.set('isFetching', false).set('error', action.payload.data);
      case actionTypes.BROKER_GET_HISTORICAL_SUCCESS:
        return state
          .set('isFetching', false)
          .set('error', false)
          .set('warrantChart', Immutable.fromJS(action.payload.data.warrant))
          .set('underlyingChart', Immutable.fromJS(action.payload.data.underlying));
      case actionTypes.BROKER_WARRANT_CATALOGUE_FETCHING:
        return state.set('isFetchingCatalogue', true)
                    .set('fetchedCatalogue', false);
      case actionTypes.BROKER_WARRANT_CATALOGUE_SUCCESS:
            const stockName = action.payload.warrants.instrumentsList[0].name;
            const provider = action.payload.warrants.instrumentsList[0].provider;
        return state
          .set('isFetchingCatalogue', false)
          .set('fetchedCatalogue', true)
          .set('stockName', stockName)
          .set('provider', provider);

      case actionTypes.BROKER_WARRANT_CATALOGUE_FAILURE:
        return state
          .set('isFetchingCatalogue', false)
          .set('complexity', false)
          .set('fetchedCatalogue', false);
      default:
        return state;
    }
}

module.exports = brokerWarrantsProductFileReducer;

const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP,
    THIRD_STEP,
    FOURTH_STEP,
    FIFTH_STEP
} = require('constants/index');
const hireNewCreditCardStep1 = require('./hireNewCreditCard/hireNewCreditCardStep1');
const hireNewCreditCardInformationCcStep1 = require('./hireNewCreditCard/hireNewCreditCardInformationCcStep1');
const hireNewCreditCardStep2 = require('./hireNewCreditCard/hireNewCreditCardStep2');
const hireNewCreditCardStep4 = require('./hireNewCreditCard/hireNewCreditCardStep4');
const hireCardOpenCreditStepINE = require('./hireCardOpenCredit/hireCardOpenCreditStepINE');
const { isPrintOfficeMode } = require('utilities/contactCenter/printerHelper');

const initialState = Immutable.fromJS({
    allAccountsInactive: false,
    error: false,
    errorDir: false,
    errorMsg: '',
    hiringStatus: '',
    isFetching: false,
    stepsCC: [
        hireNewCreditCardInformationCcStep1(),
        hireNewCreditCardStep1(),
        hireNewCreditCardStep2(),
        hireNewCreditCardStep4()

    ],
    stepsOFI: [
        hireNewCreditCardInformationCcStep1(),
        hireNewCreditCardStep1(),
        hireNewCreditCardStep2(),
        hireCardOpenCreditStepINE(),
        hireNewCreditCardStep4()

    ],
    steps: [
        hireNewCreditCardStep1(),
        hireNewCreditCardStep2(),
        hireCardOpenCreditStepINE(),
        hireNewCreditCardStep4()

    ],
    standard: '',
    submitSuccessful: false,
    subtype: 0,
    type: 0,
    visibleStep: 1,
    willCancel: false,
    loadingFlag: false,
    documentINE: false,
    errorINE: false,
    errorIinit: false,
    scrollDown: false,
    checkButtonRadioPolicy: false,
    printDocument: false,
    printDocumentPolicy: false,
    cardsDocumentsPrintedAndSigned: undefined,
    cardsDocumentsPrintedAndSignedPolicy: null,
    cardsDocumentsPrintedAndSignedIneDir: null,
    mailSent: false,
    checkboxChecked: false,
    hasBeenSentEmail: false,
    scrollDownPolicy: false,
    cards: [{ type: '', subtype: '', standard: '' }],
    cardsImages: [],
    agreeIneCheckbox: false,
    initialDocumentsList: [],
    allDocumentRead: true,
    cardData: false,
    closeModal: false,
    showModal: false,
    agreeCheckboxPaymentMethod: null,
    agreementChecked: false
});

const STEP_FUNCTIONS_CC = {
    [FIRST_STEP]: hireNewCreditCardInformationCcStep1,
    [SECOND_STEP]: hireNewCreditCardStep1,
    [THIRD_STEP]: hireNewCreditCardStep2,
    [FOURTH_STEP]: hireNewCreditCardStep4,
};

const STEP_FUNCTIONS_OFI = {
    [FIRST_STEP]: hireNewCreditCardInformationCcStep1,
    [SECOND_STEP]: hireNewCreditCardStep1,
    [THIRD_STEP]: hireNewCreditCardStep2,
    [FOURTH_STEP]: hireCardOpenCreditStepINE,
    [FIFTH_STEP]: hireNewCreditCardStep4
};

const STEP_FUNCTIONS = {
    [FIRST_STEP]: hireNewCreditCardStep1,
    [SECOND_STEP]: hireNewCreditCardStep2,
    [THIRD_STEP]: hireCardOpenCreditStepINE,
    [FOURTH_STEP]: hireNewCreditCardStep4
};

function updateStep(index, steps, action) {
    let functions;
    if (__CONTACT_CENTER__) {
        functions = isPrintOfficeMode() === true ? STEP_FUNCTIONS_OFI : STEP_FUNCTIONS_CC;
    } else {
        functions = STEP_FUNCTIONS;
    }

    return steps.update(index, step => functions[index] ? functions[index](step, action) : step);
}

function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;
    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps) {
        nextStep = currentStep;
    }
    return nextStep;
}
const updateDocumentsState = (documents, dmsDocumentType, documentChanges) =>
    documents.map(document =>
        document.dmsDocumentType === dmsDocumentType
            ? {
                ...document,
                ...documentChanges,
            }
            : document
    );
function hireNewCreditCard(state = initialState, action = { type: null }) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.HIRE_NEW_CREDIT_CARD_RESET:
            return initialState;
        case actionTypes.HIRE_NEW_CREDIT_CARD_SET_DATA_CARD:
            return state.merge({
                cards: action.payload.map(card => ({
                    standard: card.standard.substr(-7),
                    subtype: String(card.subtype),
                    type: String(card.type),
                })),
                cardImageIds: action.payload.map(card =>
                    `B.${card.type}.${card.subtype}.${card.standard.substr(-3)}`
                ),
                standard: String(action.payload[0].standard.substr(-7)),
                subtype: String(action.payload[0].subtype),
                type: String(action.payload[0].type),
                cardData: true
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_RESET_CHECKBOX:
            return state.merge({
                scrollDown: false,
                agreeIneCheckbox: false
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_RESET_DOCUMENTS_INE_DIR:
            return state.merge({
                initialDocumentsList: []
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_PRINT_DOCUMENT:
            return state.merge({
                printDocument: action.payload.value,
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_CHECK_RADIO_BUTTON:
            return state.merge({
                checkButtonRadio: action.payload.check,
                cardsDocumentsPrintedAndSigned: action.payload.check
            })
        case actionTypes.HIRE_NEW_CREDIT_CARD_LOADING:
            return state.merge({
                loadingFlag: action.payload.loadingFlag
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_FAILURE:
            return state.merge({
                error: true,
                errorMsg: action.payload.errorMsg,
                hiringStatus: action.payload.hiringStatus,
                isFetching: false
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_VALIDATE_STEP:
            const currentStep = action.payload.step;
            let visibleStep;
            const getSteps = () => {
                return __CONTACT_CENTER__
                    ? (isPrintOfficeMode()
                        ? state.get('stepsOFI')
                        : state.get('stepsCC'))
                    : state.get('steps');
            };
            const updateAndMergeState = (stepConstant) => {
                const partialSteps = updateStep(stepConstant, getSteps(), action);
                const visibleStep = getNextVisibleStep(partialSteps, currentStep);
                return state.merge({
                    steps: partialSteps,
                    visibleStep
                });
            };
            switch (currentStep) {
                case 1:
                    return updateAndMergeState(FIRST_STEP);
                case 2:
                    return updateAndMergeState(SECOND_STEP);
                case 3:
                    return updateAndMergeState(THIRD_STEP);
                case 4:
                    return updateAndMergeState(FOURTH_STEP);
                case 5:
                    return updateAndMergeState(FIFTH_STEP);
                default:
                    return state;
            }
        case actionTypes.HIRE_CARD_CREDIT_CARD_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_SET_ACCOUNT:
        case actionTypes.HIRE_NEW_CREDIT_CARD_VALIDATE_ACCOUNT:
            return state.merge({
                steps: updateStep(__CONTACT_CENTER__ ? THIRD_STEP : SECOND_STEP, state.get('steps'), action),
                stepsOFI: updateStep(__CONTACT_CENTER__ ? THIRD_STEP : SECOND_STEP, state.get('stepsOFI'), action),
                stepsCC: updateStep(__CONTACT_CENTER__ ? THIRD_STEP : SECOND_STEP, state.get('stepsCC'), action)
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_SHOW_MODAL:
            return state.merge({
                showModal: true
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_CLOSE_MODAL:
            return state.merge({
                showModal: false
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_NEXT_CLOSE_MODAL:
            return state.merge({
                showModal: false,
                closeModal: true
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_DOCUMENT_READ:
            return state.merge({
                ...state,
                initialDocumentsList: updateDocumentsState(action.payload.documentsList, action.payload.dmsDocumentType, action.payload.documentChanges)
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_ALL_DOCUMENT_READ:
            return state.merge({
                allDocumentRead: false,
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_SAVE_DOCUMENT_ACCEPTED:
            return state.merge({
                ...state,
                initialDocumentsList: updateDocumentsState(action.payload.documentsList, action.payload.dmsDocumentType, action.payload.documentChanges)
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_CHECK_RADIO_BUTTON_INE_DIR:
            const currentDocument = action.payload.currentDocument
            const documentType = action.payload.documentType;
            const indexDocument = state.get('initialDocumentsList').findIndex(doc => doc.dmsDocumentType === currentDocument);
            if (indexDocument !== -1) {
                const updatedDocumentsList = state.get('initialDocumentsList').map((doc, i) => {
                    i === indexDocument ? { ...doc, radioButton: documentType } : doc
                }
                );
                return state.merge({
                    initialDocumentsList: updatedDocumentsList
                });
            }
        case actionTypes.HIRE_NEW_CREDIT_CARD_CHECK_RADIO_BUTTON:
            return state.merge({
                checkButtonRadio: action.payload.check,
                cardsDocumentsPrintedAndSigned: action.payload.check
            })
        case actionTypes.HIRE_NEW_CREDIT_CARD_PRINT_DOCUMENT:
            return state.merge({
                printDocument: action.payload.value,
                cardsDocumentsPrintedAndSigned: undefined
            });
        case actionTypes.HIRE_CARD_CREDIT_DIR_ERROR:
            return state.merge({
                errorDir: true,
            });
        case actionTypes.HIRE_CARD_CREDIT_INIT_SUCCESS:
            return state.merge({
                operationProcessIdInit: action.payload.response.operationProcessId,
                contractNumberAccount: action.payload.contractNumberAccount,
            });
        case actionTypes.HIRE_CARD_CREDIT_INE_SUCCESS:
            const newDocumentIne = {
                dmsDocumentType: "INE",
                documentName: "documentNameIne",
                file: 'data:application/pdf;base64,' + action.payload.base64,
                isAccepted: false,
                isRead: false,
                title: "hireCreditCard-titleINE",
                fileName: "INE_document",
                radioButton: null

            };
            return state.merge({
                documentINE: action.payload.base64,
                documentIdINE: action.payload.documentId,
                initialDocumentsList: state.get('initialDocumentsList').concat(newDocumentIne)
            });
        case actionTypes.HIRE_CARD_CREDIT_DIR_SUCCESS:
            const newDocumentDir = {
                dmsDocumentType: "DIR",
                documentName: "documentNameDir",
                file: 'data:application/pdf;base64,' + action.payload.base64,
                isAccepted: false,
                isRead: false,
                title: "hireCreditCard-titleDIR",
                fileName: "DIR_document",
                radioButton: null
            };
            return state.merge({
                documentDIR: action.payload.base64,
                documentIdDIR: action.payload.documentId,
                initialDocumentsList: state.get('initialDocumentsList').concat(newDocumentDir)
            });
        case actionTypes.CARDS_REQUEST_CONFIRM_SCROLL_DOWN:
            return state.merge({
                scrollDown: true,
            });
        case actionTypes.CARDS_CHECKED_DOCUMENT:
            return state.merge({
                agreeIneCheckbox: !state.get('agreeIneCheckbox'),
            });
        case actionTypes.HIRE_CARD_CREDIT_POLICY_SUCCESS:
            return state.merge({
                documentPolicy: action.payload.base64,
                documentIdPolicy: action.payload.documentId,
            });
        case actionTypes.HIRE_CARD_CREDIT_INIT_POLICY_SUCCESS:
            return state.merge({
                operationProcessIdInitPolicy: action.payload.response.operationProcessId,
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_REQUEST:
            return state.merge({
                error: false,
                errorMsg: '',
                isFetching: true,
                submitSuccessful: false
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_SUCCESS:
            return state.merge({
                hiringStatus: action.payload.hiringStatus,
                isFetching: false,
                submitSuccessful: true
            });
        case actionTypes.RESET_INIT_CARDS_DOCUMENTS:
            return state.merge({
                initialDocumentsList: [],
                allDocumentRead: true
            });
        case actionTypes.HIRE_CARD_CREDIT_CARD_AGREECHECKBOXCC:
            return state.merge({
                agreeCheckboxPaymentMethod: action.payload.agreeCheckboxPaymentMethod,
            });
        case actionTypes.HIRE_CARD_CREDIT_INE_ERROR:
            return state.merge({
                error: true,
            });
        case actionTypes.HIRE_CARD_CREDIT_CARD_SET_AGREEMENT_CHECKBOX_CC:
            return state.merge({
                agreeIneCheckbox: !state.get('agreeIneCheckbox')
            });
        case actionTypes.HIRE_CARD_CREDIT_CARD_SET_AGREEMENT_CHECKBOX:
            const { stepToUpdate = FOURTH_STEP } = action.payload;
            return state.merge({
                steps: updateStep(stepToUpdate, state.get('steps'), action),
                stepsOFI: updateStep(stepToUpdate, state.get('stepsOFI'), action),
                stepsCC: updateStep(stepToUpdate, state.get('stepsCC'), action)
            });
        case actionTypes.HIRE_CARD_CREDIT_CARD_RESET_INIT_SEND_EMAIL:
            return state.merge({
                hasBeenSentEmail: false,
                mailSent: false
            });
        case actionTypes.HIRE_CARD_CREDIT_CARD_PROCCESS_EMAIL_SUCCESS:
            return state.merge({
                mailSent: true,
                hasBeenSentEmail: true,
                isFetching: false
            });
        case actionTypes.HIRE_CARD_CREDIT_CARD_CREDIT_PROCCESS_EMAIL_SUCCESS:
            return state.merge({
                mailSent: true,
                hasBeenSentEmail: true,
                isFetching: false
            });
        case actionTypes.HIRE_CARD_CREDIT_CARD_CREDIT_PROCCESS_EMAIL_ERROR:
            return state.merge({
                error: true,
            });
        case actionTypes.HIRE_CARD_CREDIT_CARD_SAVE_DOCUMENT_ACCEPTED:
            return state.merge({
                ...state,
                initialDocumentsList: updateDocumentsState(action.payload.documentsList, action.payload.dmsDocumentType, action.payload.documentChanges)
            });
        default:
            return state;
    }
}

module.exports = hireNewCreditCard;

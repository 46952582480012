// @ constants
import {
    INTERVENER_TYPE,
    TEXT_TARGET_TYPE,
    TINTAE_TYPE,
    FORMATTEDTEXT_TYPE
} from 'constants/index'


const WITH_TERM_DETAILS = [
    [
        {
            className: 'contract-summary__border-separator',
            columns: 5,
            details: [
                {
                    columns: 12,
                    field: 'description',
                    target: 'cta_seleccionada',
                    title: 'contractSummary-associatedAccount',
                    type: TEXT_TARGET_TYPE
                },
                {
                    columns: 12,
                    title: 'contractSummary-profitability',
                    type: TINTAE_TYPE
                },
                {
                    columns: 12,
                    title: 'contractSummary-installment',
                    target: 'account_term',
                    type: FORMATTEDTEXT_TYPE
                },
            ]
        },
        {
            columns: 6,
            details: [
                {
                    columns: 12,
                    interveners: [],
                    offset: 1,
                    title: 'contractsView-interveners',
                    type: INTERVENER_TYPE
                }
            ]
        }
    ]
]

module.exports = {
    title: 'contractSummary-eAccountSuccessTitle',
    withoutBonificationWithTerm_title: 'contractSummary-eAccountSuccessTitle',
    withBonificationWithTerm_title: 'contractSummary-eAccountSuccessTitle',
    accountNumber: 'contractSummary-accountNumber',
    setSuccessStepPrefix: 'eAccount_setSuccessStepPrefix',
    backButtonText: 'contractSummary-backTextAccount',
    backButtonUrl: '/myprofile/accounts',
    customInstructions: 'eAccount_successStepCustomInstructions',
    details: [
        [
            {
                className: 'contract-summary__border-separator',
                columns: 5,
                details: [
                    {
                        columns: 12,
                        field: 'description',
                        target: 'cta_seleccionada',
                        title: 'contractSummary-associatedAccount',
                        type: TEXT_TARGET_TYPE
                    },
                    {
                        columns: 12,
                        title: 'contractSummary-profitability',
                        type: TINTAE_TYPE
                    },
                ]
            },
            {
                columns: 6,
                details: [
                    {
                        columns: 12,
                        interveners: [],
                        offset: 1,
                        title: 'contractsView-interveners',
                        type: INTERVENER_TYPE
                    }
                ]
            }
        ]
    ],
    withoutBonificationWithTerm_details: WITH_TERM_DETAILS,
    withBonificationWithTerm_details: WITH_TERM_DETAILS,
    warningMessage: [
        [
            {
                text: 'contractSummary-eAccountSuccessStepMessageWithoutEmail'
            }
        ]
    ],
    successWithEmail_warningMessage: [
        [
            {
                text: 'contractSummary-eAccountSuccessStepEmail'
            }
        ]
    ]
};

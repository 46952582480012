//@ vendors
const Immutable = require("immutable");
//@ utilities
const get = require("lodash/object/get");
const { hash } = require("utilities/hash");
const trim = require("lodash/string/trim");
const isEmpty = require("lodash/lang/isEmpty");
//@ constants
const actionTypes = require("constants/actionTypes");
const { DATE_FILTER_NONE, EURO_TEXT } = require("constants/index");

function mapFields(cardId, movements) {
    let byId = {};
    let byOrder = [];

    movements.forEach((movement, index) => {
        if (isEmpty(movement)) return false;

        const mappedMovement = {
            cardId,
            extra: {
                balanceConcept: movement.conceptoSaldo,
                petitionState: movement.estadoPeticion,
                movementNoteDay: movement.fechaAnotacionMovimiento,
                operationDate: movement.fechaOperacion,
                dayMovementNumber: movement.numeroMovimintoEnDia,
                bankOperationCode: movement.codOperacionBancaria,
                basicOperationCode: movement.codigoOperacionBasica,
                paymentModality: get(movement, "modalidad.codmodalidadpago", 0),
                paymentMethod: get(movement, "modalidad.formapagotarjeta", ""),
            },
            canBeRecategorized: !get(movement, "categoriaGanadora.online"),
            time: movement.horaMovimiento,
            category: get(movement, "categoriaGanadora.category", ""),
            categoryCode: get(movement, "categoriaGanadora.categoryCode", ""),
            subCategoryCode: get(
                movement,
                "categoriaGanadora.subcategoryCode",
                ""
            ),
            subcategory: get(movement, "categoriaGanadora.subcategory", ""),
            description: trim(movement.txtCajero || movement.txtComercio || ""),
            contractStatus: trim(
                get(movement, "situacionContratoDESSITUA", "")
            ).toLowerCase(),
            date: movement.fechaMovimiento,
            settlementDate: movement.fechaLiquidacion,
            import: {
                currency: get(movement, "impOperacion.divisa", EURO_TEXT),
                amount: get(movement, "impOperacion.importe", 0),
            },
            deferPaymentIndicator: movement.indPagoFacil,
            isNFC: movement.indNfc === "S" ? true : false,
            isExpanded: movement.selected,
        };

        // jorge.zapata I there's no horaMovimiento in the service response, we set the current time plus movement index, in order to generate unique id for the movement
        mappedMovement.id = hash([
            mappedMovement.extra.movementNoteDay,
            mappedMovement.extra.dayMovementNumber,
            mappedMovement.description,
            mappedMovement.import.amount,
            mappedMovement.time || new Date().getTime() + index,
        ]);

        if (
            byOrder.filter((transaction) => transaction === mappedMovement.id)
                .length > 0
        ) {
            mappedMovement.id = mappedMovement.id + index;
        }

        byId[mappedMovement.id] = mappedMovement;
        byOrder.push(mappedMovement.id);
    });

    return {
        byOrder: byOrder,
        byId: byId,
    };
}

const initialState = Immutable.fromJS({
    filters: {
        date: {
            isActive: false,
            selected: DATE_FILTER_NONE,
            from: "",
            to: "",
        },
        situation: {
            isActive: false,
            selected: "",
        },
        state: {
            isActive: false,
            selected: ''
        },
        description:{
            isActive: false,
            selected: ''
        },
        amount: {
            isActive: false,
            from: {
                amount: null,
                currency: EURO_TEXT
            },
            to: {
                amount: null,
                currency: EURO_TEXT
            }
        },
    }
});

function cardsTransactionReducer(state = initialState, action) {
    const cardId = get(action.payload, "cardId");
    let processedMovements;
    let nextPage;
    let scaRequired;
    let byOrder;
    let byId;

    switch (action.type) {
        case actionTypes.FETCH_CARDS_TRANSACTIONS_REQUEST:
            return state.set(
                cardId,
                Immutable.fromJS({
                    loading: true,
                    error: false,
                    byOrder: [],
                    byId: {},
                    isFetchingNextPage: false,
                    isOutdated: false,
                    scaRequired: false,
                })
            );
        case actionTypes.FETCH_CARDS_TRANSACTIONS_SUCCESS:
            processedMovements = mapFields(cardId, action.payload.movements);

            scaRequired = action.payload.scaRequired;
            nextPage = action.payload.nextPage || scaRequired;

            return state.set(
                cardId,
                Immutable.fromJS({
                    nextPage,
                    byOrder: processedMovements.byOrder,
                    byId: processedMovements.byId,
                    repagination: action.payload.repagination,
                    loading: false,
                    scaRequired,
                    monthsBefore: action.payload.monthsBefore,
                })
            );
        case actionTypes.FETCH_CARDS_TRANSACTIONS_FAILURE:
            return state.mergeDeep({
                [cardId]: {
                    loading: false,
                    error: action.payload.data,
                    isFetchingNextPage: false,
                    scaRequired: false,
                    monthsBefore: action.payload.monthsBefore,
                },
            });
        case actionTypes.FETCH_CARDS_TRANSACTIONS_PAGE_REQUEST:
            return state.mergeDeep({
                [cardId]: {
                    isFetchingNextPage: true,
                    loading: false,
                    error: false,
                    scaRequired: false,
                },
            });
        case actionTypes.FETCH_CARDS_TRANSACTIONS_PAGE_SUCCESS:
            processedMovements = mapFields(cardId, action.payload.movements);
            const currentMovements = state.get(cardId);

            scaRequired = action.payload.scaRequired;
            nextPage = action.payload.nextPage || scaRequired;
            byOrder = scaRequired
                ? currentMovements.get("byOrder")
                : currentMovements
                      .get("byOrder")
                      .concat(Immutable.fromJS(processedMovements.byOrder));
            byId = scaRequired
                ? currentMovements.toJS().byId
                : processedMovements.byId;

            return state.mergeDeep({
                [cardId]: {
                    nextPage,
                    ...(!scaRequired && {
                        repagination: action.payload.repagination,
                    }),
                    byOrder,
                    byId,
                    isFetchingNextPage: false,
                    error: false,
                    scaRequired,
                },
            });
        case actionTypes.CARDS_TRANSACTIONS_SET_FILTERS:
            return state.set(
                "filters",
                Immutable.fromJS(action.payload.filters)
            );
        case actionTypes.RESET_CARD_TRANSACTIONS_FILTER:
            const { filterKey } = action.payload;
            return state.setIn(
                ["filters", filterKey],
                initialState.getIn(["filters", filterKey])
            );
        case actionTypes.FETCH_CARD_TRANSACTIONS_FILTERS_CLEAR:
            return state.set('filters', initialState.get('filters'));

        case actionTypes.FETCH_CARD_TRANSACTIONS_FILTERS_CLEAR_NO_RECORD:
            return state.set("filters", initialState.get("filters"));
        case actionTypes.CARDS_TRANSACTIONS_CHANGE_CATEGORY:
            return state.mergeDeepIn(
                [cardId, "byId", action.payload.transactionId],
                Immutable.fromJS({
                    category: action.payload.category
                        .toString()
                        .includes("category")
                        ? action.payload.category
                        : `category_${action.payload.category}`,
                    subcategory: action.payload.subcategory
                        .toString()
                        .includes("category")
                        ? action.payload.subcategory
                        : `category_${action.payload.subcategory}`,
                })
            );
        case actionTypes.CARDS_TRANSACTIONS_OUTDATED:
            return state.map((item, key) => {
                if (key !== "filters") {
                    return item.set("isOutdated", true);
                }

                return item;
            });
        default:
            return state;
    }
}

module.exports = cardsTransactionReducer;

const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const moment = require('moment');
const max = require('lodash/math/max');
// @ utilities
const { isSepa, getPeriodicityNextValidDate } = require('utilities/transfersHelper');
const { DEFAULT_ORIGIN_COUNTRY } = require('constants/countries');

const {
    BENEFICIARY_TYPE_EXISTING,
    BENEFICIARY_TYPE_NEW,
    BENEFICIARY_TYPE_NGO,
    BENEFICIARY_BANK_OPTION_BIC,
    BENEFICIARY_BANK_OPTION_NAME,
    EURO_TEXT,
    TRANSFER_PAYMENT_BY_BENEFICIARY_CODE,
    PERIODICITY_CONSTANTLY,
    PERIODICITY_COUNT,
    PERIODICITY_MONTHLY,
    PERIODICITY_COUNT_DEFAULT,
    TRANSFER_COSTS_SHARED,
    TRANSFER_EMITTED_DAY_BEFORE,
    BENEFICIARY_IS_RESIDENT
} = require('constants/index');
// @ contact-center

const {
    CC_PERIODICITY_WEEKLY,
    CC_PERIODICITY_CUSTOM,
    CC_PERIODICITY_RANGE_DESCRIPTION_MONTH,
    CC_PERIODICITY_RANGE_SPECIFICATION_DAY,
    CC_PERIODICITY_RANGE_SPECIFICATION_DAYS,
    SELECTED_WEEK_DAY_DEFAULT,
    CC_TRANSFER_TYPE_ORDINARY,
} = require('constants/contactCenter/transfers');

const {
    TRANSFER_SET_WEEK_DAY,
    TRANSFER_RESET_WEEK_DAY,
    TRANSFER_CUSTOM_REPEAT_ADD_MONTH_DAY,
    TRANSFER_CUSTOM_REPEAT_REMOVE_MONTH_DAY,
    TRANSFER_CUSTOM_REPEAT_SET_MONTH_DAY,
    TRANSFER_SET_PERIODICITY_RANGE_QUANTITY,
    TRANSFER_SET_PERIODICITY_RANGE_DESCRIPTION,
    TRANSFER_SET_PERIODICITY_RANGE_SPECIFICATION,
    TRANSFER_SET_TRANSFER_TYPE,
} = require('constants/contactCenter/actionTypes');

function defaultBeneficiaryNew(country=DEFAULT_ORIGIN_COUNTRY) {
    return {
        valid: false,
        validationRan: false,
        addBeneficiary: false,
        isEditingBeneficiaryExisting: false,
        currency: '',
        name: '',
        nameIsDirty: false,
        alias: '',
        aliasIsDirty: false,
        aliasIsValid: false,
        country,
        city: '',
        address: '',
        iban: '',
        ibanIsDirty: false,
        ibanIsValid: false,
        accountNumber: '',
        accountNumberIsValid: false,
        accountNumberIsDirty: false,
        bankOption: BENEFICIARY_BANK_OPTION_BIC,
        bic: '',
        bicIsDirty: false,
        bicIsValid: false,
        bankName: '',
        bankNameIsDirty: false,
        bankAddress: '',
        bankCity: '',
        notDeductibleNGOMessage: false,
        immediateTransfer: false
    };
}

function setInitialState(originCountry=DEFAULT_ORIGIN_COUNTRY) {
    return Immutable.fromJS({
        valid: false,
        fromAccount: '',
        fromAccountBalance: '',
        fromAccountCurrency: '',
        fromAccountIsValid: false,
        fromAccountIsDirty: false, //avoid showing error message before first user interaction
        emitter: '',
        oldEmitter: '', //this flag is loaded from reusing or editing scheduled transfer
        amount: 0,
        amountIsValid: false,
        amountIsDirty: false, //avoid showing error message before first user interaction
        concept: '',
        costs: TRANSFER_COSTS_SHARED,
        isResident: BENEFICIARY_IS_RESIDENT,
        minDate: moment().startOf('day'), // ignore the date time
        date: moment().startOf('day'), // ignore the date time
        isToday: true,
        isNGOValid: false,
        beneficiary: {
            type: BENEFICIARY_TYPE_EXISTING,
            notDeductibleNGOMessage: false,
            email: {
                value: '',
                isValid: true //email is not required so is valid if empty.
            },
            existing: {
                id: '',
                currency: EURO_TEXT,
                isDirty: false,
                iban: ''
            },
            new: defaultBeneficiaryNew(originCountry)
        },
        originCountry,
        paymentBy: TRANSFER_PAYMENT_BY_BENEFICIARY_CODE,
        periodicityEnabled: false,
        periodicityRange: PERIODICITY_MONTHLY,
        periodicityType: PERIODICITY_CONSTANTLY,
        periodicityMinDate: moment().startOf('day').add(1, 'month'),
        periodicityDate: moment().startOf('day').add(1, 'month'),
        periodicityEstimatedEndDate: moment().startOf('day').add(1, 'month'),
        periodicityCount: PERIODICITY_COUNT_DEFAULT,
        periodicityCountIsValid: true,
        periodicityTreatmentNonWorkableDay: TRANSFER_EMITTED_DAY_BEFORE,
        periodicityRangeQuantity: 1,
        periodicityRangeDescription: CC_PERIODICITY_RANGE_DESCRIPTION_MONTH,
        periodicityRangeSpecified: CC_PERIODICITY_RANGE_SPECIFICATION_DAY,
        periodicityCodeFromService: null,
        selectedWeekDay: SELECTED_WEEK_DAY_DEFAULT,
        transferType: CC_TRANSFER_TYPE_ORDINARY,
        customSelectedMonthDay: 1,
        customSelectedMonthDays: [],
        selectedSingleWeekDayIsInvalid: false,
        customRepeatIsInvalid: false,
        isEditingScheduled: false, //flag to determinate which api use on post,
        isReusingTransfer: false,
        fromAccountIban: '',
        fromAccountIbanMatchBeneficiaryIban: false,
        immediateTransfer: false,
        showAccountLimitReachedModal: false,
    });
}

function getCustomPeriodicityNextSuggestedDate(transferDate, selectedMonthDays) {
    if (selectedMonthDays.length === 0) {
        return moment(transferDate);
    }

    const maxSelectedMonthDay = max(selectedMonthDays.map(selectedMonthDay => parseInt(selectedMonthDay, 10)));

    // To avoid issues with months with less than 31 days we start in January and create the offset to work from January
    return moment({ date: maxSelectedMonthDay, month: 0, year: transferDate.year() }).add(transferDate.month() + 1, 'months');
}

function validateNewBeneficiary(step) {
    const immBeneficiary = step.getIn(['beneficiary', 'new']);
    let valid = !!immBeneficiary.get('name');

    if (valid) {
        const insideSepa = isSepa(immBeneficiary.get('country'), immBeneficiary.get('currency'));
        if (insideSepa) {
            valid = immBeneficiary.get('ibanIsValid');
        } else {
            valid = immBeneficiary.get('accountNumberIsValid');
            if (valid) {
                const bankOption = immBeneficiary.get('bankOption');
                if (bankOption === BENEFICIARY_BANK_OPTION_BIC) {
                    valid = immBeneficiary.get('bicIsValid');
                } else {
                    valid = !!immBeneficiary.get('bankName');
                }
            }
        }

        if (valid && !step.get('isReusingTransfer') && !step.get('isEditingScheduled') && immBeneficiary.get('addBeneficiary')) {
            valid = immBeneficiary.get('aliasIsValid');
        }
    }

    return step.setIn(['beneficiary', 'new', 'valid'], valid);
}

function validateFromAccount(step) {
    function checkFromAccount() {
        const fromAccountBalance = step.get('fromAccountBalance');
        const isToday = step.get('isToday');

        if (typeof fromAccountBalance === 'number' && fromAccountBalance > 0) {
            // original account balance has funds
            return true;
        } else if (!isToday) {
            // the transfer will be on a future date
            return true;
        }
        return false;
    }

    return step.merge({
        fromAccountIsValid: !!step.get('fromAccount') && checkFromAccount()
    });
}

function validateAmount(step) {
    function checkAmount() {
        const isToday = step.get('isToday');
        const amount = step.get('amount');
        const maxTransferLimit = step.get('maxTransferLimit');
        const fromAccountBalance = step.get('fromAccountBalance');
        if(maxTransferLimit && amount > maxTransferLimit){
            return false;
        } 
        else {
        if (amount > 0) {
            // If no original account has been selected yet, we asume valid until we have a value to compare
            if (typeof fromAccountBalance !== 'number') {
                return true;
            }

            // original account has been selected
            if (amount <= fromAccountBalance) {
                // Original account has been selected and has enough balance
                return true;
            } else if (!isToday) {
                // Original account has not enough balance, but the transfer is future so we omit checking
                return true;
            } else {
                const immBeneficiary = step.get('beneficiary');
                const beneficiaryCurrency =
                    immBeneficiary.get('type') === BENEFICIARY_TYPE_EXISTING
                        ? immBeneficiary.getIn(['existing', 'currency'])
                        : immBeneficiary.getIn(['new', 'currency']);

                if (step.get('fromAccountCurrency') !== beneficiaryCurrency) {
                    // If origin account currency is different than destination currency
                    // we cannot make a conversion between currencies, so we assume is valid.
                    return true;
                }
            }
        }
    }
        return false;
    }

    return step.merge({
        amountIsValid: checkAmount()
    });
}

function validateMatchIban(state) {
    const beneficiaryType = state.getIn(['beneficiary', 'type']);
    const fromAccountIban = state.get('fromAccountIban');
    let beneficiaryIban;
    if (beneficiaryType === BENEFICIARY_TYPE_EXISTING) {
        beneficiaryIban = state.getIn(['beneficiary', 'existing', 'iban']);
    } else {
        beneficiaryIban = state.getIn(['beneficiary', 'new', 'iban']);
    }

    return state.merge({
        fromAccountIbanMatchBeneficiaryIban:
            !!fromAccountIban && !!beneficiaryIban && fromAccountIban === beneficiaryIban
    });
}

function validateStep(state) {
    // Validate from account section
    let currentState = validateFromAccount(state);

    // Validate amount section
    currentState = validateAmount(currentState);

    let valid = currentState.get('fromAccountIsValid') && currentState.get('amountIsValid');

    // Validate email notification
    valid = valid && currentState.getIn(['beneficiary', 'email', 'isValid']);

    // Validate beneficiary section
    const beneficiaryType = currentState.getIn(['beneficiary', 'type']);

    if (beneficiaryType === BENEFICIARY_TYPE_EXISTING) {
        valid = valid && !!currentState.getIn(['beneficiary', 'existing', 'id']);
    } else if (beneficiaryType === BENEFICIARY_TYPE_NGO) {
        valid = valid && currentState.get('isNGOValid');
        const immBeneficiary = currentState.getIn(['beneficiary', 'new']);
        if (valid && !currentState.get('isReusingTransfer') && !currentState.get('isEditingScheduled') && immBeneficiary.get('addBeneficiary')) {
            valid = immBeneficiary.get('aliasIsValid');
        }
    } else {
        currentState = validateNewBeneficiary(currentState);
        const beneficiaryNewIsValid = currentState.getIn(['beneficiary', 'new', 'valid']);
        valid = valid && beneficiaryNewIsValid;
    }

    // Validate periodicity section
    const periodicityEnabled = currentState.get('periodicityEnabled');
    if (periodicityEnabled) {
        const periodicityType = currentState.get('periodicityType');
        // Periodicty is valid if enabled, periodicty type is not count, or periodicity count is valid
        valid =
            valid &&
            (periodicityType !== PERIODICITY_COUNT || currentState.get('periodicityCountIsValid'));
    }

    //Validate single weekday input
    let selectedSingleWeekDayIsInvalid;
    if (__CONTACT_CENTER__ && currentState.get('periodicityRange') === CC_PERIODICITY_WEEKLY) {
        valid = valid && currentState.get('selectedWeekDay');
        selectedSingleWeekDayIsInvalid = !currentState.get('selectedWeekDay');
    }

    //Validate single weekday input
    let customRepeatIsInvalid;
    if (__CONTACT_CENTER__ && currentState.get('periodicityRange') === CC_PERIODICITY_CUSTOM) {
        const customSelectedMonthDays = currentState.get('customSelectedMonthDays');
        const periodicityRangeSpecified = currentState.get('periodicityRangeSpecified');

        customRepeatIsInvalid = !(
            currentState.get('periodicityRangeQuantity') && (
                periodicityRangeSpecified === CC_PERIODICITY_RANGE_SPECIFICATION_DAY ||
                (periodicityRangeSpecified === CC_PERIODICITY_RANGE_SPECIFICATION_DAYS && customSelectedMonthDays.size)
            )
        );
        valid = valid && !customRepeatIsInvalid;
    }

    currentState = validateMatchIban(currentState);
    valid = valid && !currentState.get('fromAccountIbanMatchBeneficiaryIban');

    return currentState.mergeDeep({
        valid,
        validationRan: true,
        fromAccountIsDirty: true,
        amountIsDirty: true,
        beneficiary: {
            existing: {
                isDirty: true
            },
            new: {
                nameIsDirty: true,
                aliasIsDirty: true,
                ibanIsDirty: true,
                accountNumberIsDirty: true,
                bicIsDirty: true,
                bankNameIsDirty: true
            }
        },
        selectedSingleWeekDayIsInvalid,
        customRepeatIsInvalid,
    });
}

function transferStep1(state = setInitialState(), action) {
    const today = moment().startOf('day');
    let newState, nextDate, insideSepa, nextInsideSepa, immBeneficiaryNew;
    switch (action.type) {
        case actionTypes.TRANSFER_RESET:
            return setInitialState(state.get('originCountry'));
        case actionTypes.TRANSFER_VALIDATE_UI_STEP:
            return validateStep(state);
        case actionTypes.TRANSFER_FROM_ACCOUNT:
            newState = state.merge({
                fromAccount: action.payload.accountId,
                fromAccountBalance: action.payload.amount,
                fromAccountCurrency: action.payload.currency,
                fromAccountIsDirty: true,
                fromAccountIban: action.payload.iban,
                fromAccountOriginSubType: action.payload.accountOriginSubType,
                fromAccountOriginType: action.payload.accountOriginType

            });
            return validateAmount(validateFromAccount(validateMatchIban(newState)));
        case actionTypes.TRANSFER_BENEFICIARY_TYPE:
            newState = state.mergeDeep({
                beneficiary: {
                    type: action.payload.type,
                    existing: {
                        id: '',
                        currency: '',
                        isDirty: false,
                        iban: ''
                    },
                    ...(action.payload.type === BENEFICIARY_TYPE_NEW && { new: {...defaultBeneficiaryNew(state.get('originCountry')), currency: EURO_TEXT, country: DEFAULT_ORIGIN_COUNTRY} } ),
                    ...(action.payload.type !== BENEFICIARY_TYPE_NEW && { new: {...defaultBeneficiaryNew(state.get('originCountry')), currency: action.payload.type === BENEFICIARY_TYPE_NGO ? EURO_TEXT : '', country: DEFAULT_ORIGIN_COUNTRY} } ),
                }
            });
            return validateAmount(newState);
        case actionTypes.TRANSFER_BENEFICIARY_EXISTING:
            newState = state.mergeDeep({
                beneficiary: {
                    existing: {
                        id: action.payload.id,
                        currency: action.payload.currency || EURO_TEXT,
                        isDirty: true,
                        iban: action.payload.iban
                    }
                },
                periodicityEnabled: action.payload.isSepa ? state.get('periodicityEnabled') : false
            });
            return validateAmount(validateMatchIban(newState));
        case actionTypes.TRANSFER_SET_COSTS:
            return state.set('costs', action.payload.costs);
        case actionTypes.ADD_BENEFICIARY_NOT_DEDUCTIBLE_MESSAGE:
            return state.setIn(['beneficiary', 'notDeductibleNGOMessage'], action.payload.showMessage);
        case actionTypes.TRANSFER_SET_NGO_VALIDITY:
            return state.set('isNGOValid', action.payload.isValid);
        case actionTypes.TRANSFER_SET_TREATMENT_NON_WORKABLE_DAY:
            return state.set('periodicityTreatmentNonWorkableDay', action.payload.flag);
        case actionTypes.TRANSFER_PAYMENT_BY_CHANGED:
            return state.set('paymentBy', action.payload.value);
        case actionTypes.TRANSFER_SET_ALL_FIELDS:
            // jorge: we don't set fromAccountIban value here because the TRANSFER_FROM_ACCOUNT action is triggered
            // when transferView is loaded for reusing a transfer
            return state.mergeDeep(action.payload.fields);
        case actionTypes.TRANSFER_SET_EMAIL_NOTIFICATION:
            return state.mergeDeep({
                beneficiary: {
                    email: {
                        value: action.payload.email,
                        isValid: action.payload.isValid
                    }
                }
            });
        case actionTypes.TRANSFER_BENEFICIARY_NAME:
            return state.mergeDeep({
                beneficiary: {
                    new: {
                        name: action.payload.name,
                        nameIsDirty: true
                    }
                }
            });
        case actionTypes.TRANSFER_BENEFICIARY_ALIAS:
            return state.mergeDeep({
                beneficiary: {
                    new: {
                        alias: action.payload.alias,
                        aliasIsDirty: true,
                        aliasIsValid: action.payload.isValid
                    }
                }
            });
        case actionTypes.RESET_TRANSFER_DATES:
            return state.mergeDeep({
                minDate: moment().startOf('day'),
                date: moment().startOf('day'),
                periodicityEnabled: false,
                periodicityRange: PERIODICITY_MONTHLY,
                periodicityType: PERIODICITY_CONSTANTLY,
                periodicityMinDate: moment().startOf('day').add(1, 'month'),
                periodicityDate: moment().startOf('day').add(1, 'month'),
                periodicityEstimatedEndDate: moment().startOf('day').add(1, 'month'),
                periodicityCount: PERIODICITY_COUNT_DEFAULT,
                periodicityCountIsValid: true,
                periodicityTreatmentNonWorkableDay: TRANSFER_EMITTED_DAY_BEFORE
            })
        case actionTypes.TRANSFER_BENEFICIARY_COUNTRY:
            immBeneficiaryNew = state.getIn(['beneficiary', 'new']);
            const currency = immBeneficiaryNew.get('currency');
            insideSepa = isSepa(immBeneficiaryNew.get('country'), currency);
            nextInsideSepa = isSepa(action.payload.country, currency);

            newState = state.setIn(['beneficiary', 'new', 'country'], action.payload.country);

            if (insideSepa !== nextInsideSepa) {
                newState = newState.mergeDeep({
                    beneficiary: {
                        new: {
                            iban: '',
                            ibanIsDirty: false,
                            ibanIsValid: false,
                            bankOption: BENEFICIARY_BANK_OPTION_BIC,
                            accountNumber: '',
                            accountNumberIsValid: false,
                            accountNumberIsDirty: false,
                            bic: '',
                            bicIsDirty: false,
                            bicIsValid: false,
                            bankName: '',
                            bankNameIsDirty: false,
                            bankAddress: '',
                            bankCity: ''
                        }
                    },
                    periodicityEnabled: false
                });
            }
            return newState;
        case actionTypes.TRANSFER_BENEFICIARY_BIC:
            return state.mergeDeep({
                beneficiary: {
                    new: {
                        bic: action.payload.bic,
                        bicIsDirty: true,
                        bicIsValid: action.payload.isValid
                    }
                }
            });
        case actionTypes.TRANSFER_BENEFICIARY_SET_IBAN_ON_COUNTRY_CHANGE:
            newState = state.mergeDeep({
                beneficiary: {
                    new: {
                        iban: '',
                        ibanIsDirty: action.ibanIsDirty,
                        ibanIsValid: false
                    }
                }
            });
            return validateMatchIban(newState);
        case actionTypes.TRANSFER_BENEFICIARY_IBAN:
            newState = state.mergeDeep({
                beneficiary: {
                    new: {
                        iban: action.payload.iban,
                        ibanIsDirty: !!action.payload.ibanIsDirty,
                        ibanIsValid: action.payload.isValid
                    }
                }
            });
            return validateMatchIban(newState);
        case actionTypes.TRANSFER_BENEFICIARY_ACCOUNT_NUMBER:
            return state.mergeDeep({
                beneficiary: {
                    new: {
                        accountNumber: action.payload.accountNumber,
                        accountNumberIsValid: action.payload.isValid,
                        accountNumberIsDirty: true
                    }
                }
            });
        case actionTypes.TRANSFER_BENEFICIARY_CURRENCY:
            immBeneficiaryNew = state.getIn(['beneficiary', 'new']);
            const country = immBeneficiaryNew.get('country');
            insideSepa = isSepa(country, immBeneficiaryNew.get('currency'));
            nextInsideSepa = isSepa(country, action.payload.currency);

            newState = state.setIn(
                ['beneficiary', 'new', 'currency'],
                action.payload.currency || EURO_TEXT
            );

            if (insideSepa !== nextInsideSepa) {
                newState = newState.mergeDeep({
                    beneficiary: {
                        new: {
                            iban: '',
                            ibanIsDirty: false,
                            ibanIsValid: false,
                            bankOption: BENEFICIARY_BANK_OPTION_BIC,
                            accountNumber: '',
                            accountNumberIsValid: false,
                            accountNumberIsDirty: false,
                            bic: '',
                            bicIsDirty: false,
                            bicIsValid: false,
                            bankName: '',
                            bankNameIsDirty: false,
                            bankAddress: '',
                            bankCity: ''
                        }
                    },
                    periodicityEnabled: false
                });
            }
            return validateAmount(newState);
        case actionTypes.TRANSFER_BENEFICIARY_BANK_OPTION:
            return state.mergeDeep({
                beneficiary: {
                    new: {
                        bankOption: action.payload.option,
                        bic: '',
                        bicIsDirty: false,
                        bicIsValid: false,
                        bankName: '',
                        bankNameIsDirty: false,
                        bankAddress: '',
                        bankCity: ''
                    }
                }
            });
        case actionTypes.TRANSFER_BENEFICIARY_BANK_NAME:
            return state.mergeDeep({
                beneficiary: {
                    new: {
                        bankName: action.payload.name,
                        bankNameIsDirty: true
                    }
                }
            });
        case actionTypes.TRANSFER_BENEFICIARY_BANK_ADDRESS:
            return state.setIn(['beneficiary', 'new', 'bankAddress'], action.payload.address);
        case actionTypes.TRANSFER_BENEFICIARY_BANK_CITY:
            return state.setIn(['beneficiary', 'new', 'bankCity'], action.payload.city);
        case actionTypes.TRANSFER_BENEFICIARY_ADDRESS:
            return state.setIn(['beneficiary', 'new', 'address'], action.payload.address);
        case actionTypes.TRANSFER_BENEFICIARY_CITY:
            return state.setIn(['beneficiary', 'new', 'city'], action.payload.city);
        case actionTypes.TRANSFER_BENEFICIARY_ADD_AGENDA:
            return state.mergeDeep({
                beneficiary: {
                    new: {
                        addBeneficiary: action.payload.enabled,
                        alias: '',
                        aliasIsDirty: false,
                        aliasIsValid: false
                    }
                }
            });
        case actionTypes.TRANSFER_SET_EMITTER:
            return state.merge({
                emitter: action.payload.emitter
            });
        case actionTypes.TRANSFER_LOAD_OLD_EMITTER:
            return state.merge({
                emitter: action.payload.emitter,
                oldEmitter: ''
        });
        case actionTypes.TRANSFER_AMOUNT:
            newState = state.merge({
                amount: action.payload.amount,
                amountIsDirty: true,
                maxTransferLimit:action.payload.maxTransferLimit
            });
            return validateAmount(newState);
        case actionTypes.TRANSFER_CONCEPT:
            return state.merge({
                concept: action.payload.concept
            });
        case actionTypes.TRANSFER_DATE:
            nextDate = getPeriodicityNextValidDate(
                state.get('periodicityRange'),
                action.payload.date,
                state.get('selectedWeekDay'),
                state.get('periodicityRangeQuantity'),
                state.get('periodicityRangeSpecified')
            );
            newState = state.mergeDeep({
                date: action.payload.date,
                periodicityType: state.get('periodicityType') || PERIODICITY_CONSTANTLY,
                periodicityMinDate: nextDate,
                periodicityDate: state.get('periodicityDate') || nextDate,
                periodicityCount: PERIODICITY_COUNT_DEFAULT,
                periodicityCountIsValid: true,
                isToday: action.payload.date.isSame(today),
                beneficiary: {
                    email: {
                        value: '',
                        isValid: true
                    }
                },
                selectedWeekDay: 0
            });
            return validateAmount(validateFromAccount(newState));
        case actionTypes.TRANSFER_SET_PERIODICITY_ENABLED:
            return state.mergeDeep({
                periodicityEnabled: action.payload.enabled,
                beneficiary: {
                    email: {
                        value: '',
                        isValid: true
                    }
                }
            });
        case TRANSFER_SET_WEEK_DAY:
            return state.merge({
                selectedWeekDay: action.payload.day
            });
        case TRANSFER_CUSTOM_REPEAT_SET_MONTH_DAY:
            return state.merge({
                customSelectedMonthDay: action.payload.day
            });
        case TRANSFER_CUSTOM_REPEAT_REMOVE_MONTH_DAY:
            const customSelectedMonthDays = state.get('customSelectedMonthDays');
            return state.merge({
                periodicityType: PERIODICITY_CONSTANTLY,
                customSelectedMonthDays: customSelectedMonthDays.delete(
                    customSelectedMonthDays.findIndex(day => day == action.payload.day)
                ),
                periodicityCount: PERIODICITY_COUNT_DEFAULT,
                periodicityCountIsValid: true
            });
        case TRANSFER_CUSTOM_REPEAT_ADD_MONTH_DAY:
            const day = action.payload.day;

            return state.get('customSelectedMonthDays').includes(day) ? state : state.merge({
                periodicityType: PERIODICITY_CONSTANTLY,
                customSelectedMonthDays: state.get('customSelectedMonthDays').push(day).sort(),
                customRepeatIsInvalid: false,
                periodicityCount: PERIODICITY_COUNT_DEFAULT,
                periodicityCountIsValid: true
            });
        case TRANSFER_RESET_WEEK_DAY:
            return state.merge({
                selectedWeekDay: 0
            });
        case actionTypes.TRANSFER_SET_PERIODICITY_TYPE:
            const suggestedDate = (state.get('periodicityRange') !== CC_PERIODICITY_CUSTOM || state.get('periodicityRangeSpecified') === CC_PERIODICITY_RANGE_SPECIFICATION_DAY)
                ? moment(state.get('periodicityMinDate'))
                : getCustomPeriodicityNextSuggestedDate(
                    state.get('date'),
                    state.get('customSelectedMonthDays').toJS()
                );
            return state.merge({
                periodicityType: action.payload.type,
                periodicityCount: PERIODICITY_COUNT_DEFAULT,
                periodicityCountIsValid: true,
                periodicityDate: suggestedDate
            });
        case actionTypes.TRANSFER_SET_PERIODICITY_RANGE:
            nextDate = getPeriodicityNextValidDate(
                action.payload.range,
                state.get('date'),
                state.get('selectedWeekDay'),
                state.get('periodicityRangeQuantity'),
                state.get('periodicityRangeSpecified')
            );
            return state.merge({
                periodicityRange: action.payload.range,
                periodicityType: PERIODICITY_CONSTANTLY,
                periodicityMinDate: nextDate,
                periodicityDate: nextDate,
                periodicityCount: PERIODICITY_COUNT_DEFAULT,
                periodicityCountIsValid: true
            });
        case TRANSFER_SET_PERIODICITY_RANGE_QUANTITY:
            const { rangeQuantity } = action.payload;
            const rangeSpecified = rangeQuantity != 1 ? CC_PERIODICITY_RANGE_SPECIFICATION_DAY : state.get('periodicityRangeSpecified');
            nextDate = getPeriodicityNextValidDate(
                state.get('periodicityRange'),
                state.get('date'),
                state.get('selectedWeekDay'),
                rangeQuantity,
                rangeSpecified
            );
            return state.merge({
                periodicityType: PERIODICITY_CONSTANTLY,
                periodicityMinDate: nextDate,
                periodicityDate: nextDate,
                periodicityRangeQuantity: rangeQuantity,
                periodicityRangeSpecified: rangeSpecified,
                periodicityCount: PERIODICITY_COUNT_DEFAULT,
                periodicityEstimatedEndDate: action.payload.estimatedDate,
                periodicityCountIsValid: true
            });
        case TRANSFER_SET_PERIODICITY_RANGE_DESCRIPTION:
            return state.merge({
                periodicityRangeDescription: action.payload.rangeDescription,
            });
        case TRANSFER_SET_PERIODICITY_RANGE_SPECIFICATION:
            nextDate = getPeriodicityNextValidDate(
                state.get('periodicityRange'),
                state.get('date'),
                state.get('selectedWeekDay'),
                state.get('periodicityRangeQuantity'),
                action.payload.rangeSpecified
            );
            return state.merge({
                periodicityType: PERIODICITY_CONSTANTLY,
                periodicityMinDate: nextDate,
                periodicityDate: nextDate,
                periodicityRangeSpecified: action.payload.rangeSpecified,
                periodicityCount: PERIODICITY_COUNT_DEFAULT,
                periodicityCountIsValid: true
            });
        case actionTypes.TRANSFER_SET_PERIODICITY_DATE:
            return state.merge({
                periodicityDate: action.payload.date
            });
        case actionTypes.TRANSFER_SET_PERIODICITY_COUNT:
            return state.merge({
                periodicityCount: action.payload.count,
                periodicityEstimatedEndDate: action.payload.estimatedDate,
                periodicityCountIsValid: action.payload.count > 0
            });
        case actionTypes.TRANSFER_FILL_FORM:
            const {
                amount,
                concept,
                currency: scheduledCurrency,
                minDate,
                date,
                oldEmitter,
                beneficiaryName,
                beneficiaryCountry,
                plainBeneficiaryIban,
                periodicityEnabled,
                periodicityRange,
                periodicityRangeQuantity,
                periodicityRangeSpecified,
                periodicityCodeFromService,
                periodicityType,
                periodicityMinDate,
                periodicityDate,
                periodicityTreatmentNonWorkableDay,
                scheduledId
            } = action.payload;

            // jorge: we don't set fromAccountIban value here because the TRANSFER_FROM_ACCOUNT action is triggered
            // when transferView is loaded for editing a scheduled transfer
            newState = state.mergeDeep({
                scheduledId, // we need the id to access scheduledTransfer reducer later
                amount,
                concept,
                minDate,
                date,
                oldEmitter,
                periodicityEnabled,
                periodicityRange,
                periodicityRangeQuantity,
                periodicityRangeSpecified,
                periodicityCodeFromService,
                periodicityType,
                periodicityMinDate,
                periodicityDate,
                periodicityTreatmentNonWorkableDay,
                isEditingScheduled: true,
                isToday: date.isSame(today),
                beneficiary: {
                    type: BENEFICIARY_TYPE_NEW,
                    new: {
                        name: beneficiaryName,
                        nameIsDirty: true,
                        iban: plainBeneficiaryIban,
                        ibanIsDirty: true,
                        ibanIsValid: true,
                        country: beneficiaryCountry,
                        currency: scheduledCurrency
                    }
                }
            });

            newState = validateFromAccount(newState);
            newState = validateAmount(newState);
            newState = validateNewBeneficiary(newState);

            return newState;
        case actionTypes.TRANSFER_BENEFICIARY_FILL_EDIT_FORM:
            return state.mergeDeep({
                beneficiary: {
                    type: BENEFICIARY_TYPE_NEW,
                    new: {
                        isEditingBeneficiaryExisting: true,
                        accountNumber: action.payload.accountNumber,
                        accountNumberIsValid: !!action.payload.accountNumber,
                        accountNumberIsDirty: false,
                        address: action.payload.address,
                        alias: action.payload.alias,
                        aliasIsValid: true,
                        aliasIsDirty: false,
                        bankOption: action.payload.bic
                            ? BENEFICIARY_BANK_OPTION_BIC
                            : BENEFICIARY_BANK_OPTION_NAME,
                        bankAddress: action.payload.bankAddress,
                        bankName: action.payload.bankName,
                        bankNameIsDirty: false,
                        bankCity: action.payload.bankCity,
                        bic: action.payload.bic,
                        bicIsDirty: false,
                        bicIsValid: !!action.payload.bic,
                        city: action.payload.city,
                        country: action.payload.country,
                        currency: action.payload.currency,
                        iban: action.payload.iban,
                        ibanIsDirty: false,
                        ibanIsValid: !!action.payload.iban,
                        name: action.payload.name,
                        nameIsDirty: false,
                        valid: true
                    }
                }
            });
        case actionTypes.TRANSFER_BENEFICIARY_CANCEL_EDIT_FORM:
            newState =  state.mergeDeep({
                beneficiary: {
                    type: BENEFICIARY_TYPE_EXISTING,
                    new: defaultBeneficiaryNew(state.get('originCountry'))
                }
            });
            return validateAmount(newState);
        case TRANSFER_SET_TRANSFER_TYPE:
            return state.merge({
                transferType: action.payload.value
            });
        case actionTypes.FLAG_BASED_FEATURES_SET_ORIGIN_COUNTRY:
            return state.mergeDeep({
                beneficiary: {
                    type: BENEFICIARY_TYPE_EXISTING,
                    new: {
                        ...defaultBeneficiaryNew('')
                    }
                },
                originCountry: action.originCountry.toUpperCase()
            });
        case actionTypes.TRANSFER_ACCOUNT_LIMIT_MODAL_TOGGLE:
            return state.merge({
                showAccountLimitReachedModal: !state.get('showAccountLimitReachedModal')
            });
        default:
            return state;
    }
}

module.exports = transferStep1;

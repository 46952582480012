//@vendor
const Immutable = require('immutable');
const max = require('lodash/math/max');
const min = require('lodash/math/min');

//@constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP, SECOND_STEP } = require('constants/index');
//@reducers
const cardModifyNewLimitStep1 = require('./cardModifyLimit/cardModifyNewLimitStep1');
const cardModifyLimitStep2 = require('./cardModifyLimit/cardModifyLimitStep2');
const hireCardOpenCreditStepINE = require('./hireCardOpenCredit/hireCardOpenCreditStepINE');

const initialState = Immutable.fromJS({
    steps: [
        cardModifyNewLimitStep1(undefined, { type: null }),
        hireCardOpenCreditStepINE(),
        cardModifyLimitStep2(undefined, { type: null })
    ],
    maximumLimit: 0,
    minimumLimit: 0,
    visibleStep: 1,
    success: false,
    willLeave: false,
    willCancel: false,
    error: false,
    errorCode: '',
    isFetching: false,
    increaseLimits: {},
    decreaseLimits: {},
    limits: [],
    approvedAmount: 0,
    operationType: false,
    operationStatus: false,
    operationErrorMessage: '',
    initialDocumentsList: [],
    agreeIneCheckbox: false,
    allDocumentRead: true,

});

const mapCardReferenceData = referenceData => ({
    channel: referenceData.canal || '',
    standard: referenceData.standard || '',
    subtype: referenceData.subtipo || '',
    type: referenceData.tipo || ''
});

const mapIncreaseLimits = ({ creditLimitList, cardReferenceData, maximumLimitAutorize }) => {
    const limitsList = creditLimitList.map(limit => parseInt(limit, 10));
    const minValueFromList = min(limitsList);
    const parsedMaximumLimitAutorize = parseFloat(maximumLimitAutorize);
    let newMaximumLimitAutorize;

    if (parsedMaximumLimitAutorize < minValueFromList) {
        newMaximumLimitAutorize = 0;
    } else {
        newMaximumLimitAutorize = parsedMaximumLimitAutorize;
    }

    return {
        limitsList,
        cardReferenceData: mapCardReferenceData(cardReferenceData),
        maximumLimitAutorize: newMaximumLimitAutorize
    };
};


function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardModifyNewLimitStep1(step, action);
            case SECOND_STEP:
                return hireCardOpenCreditStepINE(step, action);
            case THIRD_STEP:
                return cardModifyLimitStep2(step, action);
        }
    })
}
function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps) {
        nextStep = currentStep;
    }

    return nextStep;
}
const updateDocumentsState = (documents, dmsDocumentType, documentChanges) =>
    documents.map(document =>
        document.dmsDocumentType === dmsDocumentType
            ? {
                ...document,
                ...documentChanges,
            }
            : document
    );
const getLimits = state => {
    const currentLimit = state.getIn(['steps', FIRST_STEP, 'currentLimit']);
    const comparer = (firstValue, secondValue) => firstValue - secondValue;
    const isCurrentValue = value => value === currentLimit;
    let increaseLimits = state.getIn(['increaseLimits', 'limitsList']);

    if (!increaseLimits.find(isCurrentValue)) {
        increaseLimits = increaseLimits.push(currentLimit);
    }

    const limits = increaseLimits.sort(comparer).toJS()
        .filter(function (elem, index, self) { return index == self.indexOf(elem); });

    return state.mergeDeep({
        limits,
        maximumLimit: max(limits),
        minimumLimit: min(limits)
    });
};


function cardModifyLimitReducer(state = initialState, action) {
    let partialSteps;
    let visibleStep;

    switch (action.type) {
        case actionTypes.CARD_MODIFY_NEW_LIMIT_VALIDATE_STEP:
            const currentStep = action.payload.step;
            switch (action.payload.step) {
                case 1: ;
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;
        case actionTypes.CARD_INCREASE_LIMITS_REQUEST:
            return state.merge({
                success: false,
                error: false,
                isFetching: true
            });
        case actionTypes.CARD_MODIFY_LIMIT_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.CARD_MODIFY_NEW_LIMIT_CARD_ID:
            return state.merge({
                cardId: action.payload.cardId
            })

        case actionTypes.CARD_INCREASE_LIMITS_SUCCESS:
            const increaseLimits = mapIncreaseLimits(action.payload);
            return state.mergeDeep({
                increaseLimits: increaseLimits,
                isFetching: false,
                success: false,
                error: false
            })
                .setIn(['steps', FIRST_STEP, 'maximumLimitAutorize'], increaseLimits.maximumLimitAutorize);
        case actionTypes.CARD_REQUEST_LIMITS_FAILURE:
            return state.merge({
                success: false,
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.CARD_MODIFY_LIMIT_INCREASE_REQUEST:
        case actionTypes.CARD_MODIFY_LIMIT_DECREASE_REQUEST:
            return state.merge({
                success: false,
                error: false,
                isFetching: true
            });
        case actionTypes.CARD_MODIFY_LIMIT_INCREASE_SUCCESS:
            return state.merge({
                success: true,
                error: false,
                isFetching: false,
                operationStatus: action.payload.operationStatus,
                approvedAmount: action.payload.approvedAmount
            });
        case actionTypes.CARD_MODIFY_LIMIT_DECREASE_SUCCESS:
            return state.merge({
                success: true,
                error: false,
                isFetching: false,
                approvedAmount: action.payload.approvedAmount
            });
        case actionTypes.CARD_MODIFY_LIMIT_INCREASE_FAILURE:
            return state.merge({
                success: false,
                error: true,
                errorCode: action.payload.errorCode,
                isFetching: false,
                operationErrorMessage: action.payload.operationErrorMessage,
                operationStatus: action.payload.operationStatus
            });
        case actionTypes.CARD_MODIFY_LIMIT_DECREASE_FAILURE:
            return state.merge({
                success: false,
                error: action.payload.error,
                isFetching: false
            });
        case actionTypes.CARD_GET_LIMITS:
            return getLimits(state);


        case actionTypes.CARD_MODIFY_LIMIT_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.CARD_MODIFY_LIMIT_CLEAR:
            return initialState;
        case actionTypes.CARD_MODIFY_LIMIT_RESET_STEP:
            return state.setIn(['steps', FIRST_STEP, 'tentativeLimit'], action.payload.limit);
        case actionTypes.CARD_MODIFY_LIMIT_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                steps: updateStep(SECOND_STEP, state, action)
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_SET_DATA_CARD:
            return state.merge({
                cards: action.payload.map(card => ({
                    standard: card.standard.substr(-7),
                    subtype: String(card.subtype),
                    type: String(card.type),
                })),
                cardImageIds: action.payload.map(card =>
                    `B.${card.type}.${card.subtype}.${card.standard.substr(-3)}`
                ),
                standard: String(action.payload[0].standard.substr(-7)),
                subtype: String(action.payload[0].subtype),
                type: String(action.payload[0].type),
                cardData: true
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_DOCUMENT_READ:
            return state.merge({
                ...state,
                initialDocumentsList: updateDocumentsState(action.payload.documentsList, action.payload.dmsDocumentType, action.payload.documentChanges)
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_ALL_DOCUMENT_READ:
            return state.merge({
                allDocumentRead: false,
            });
        case actionTypes.RESET_INIT_CARDS_DOCUMENTS:
            return state.merge({
                initialDocumentsList: [],
                allDocumentRead: true
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_SAVE_DOCUMENT_ACCEPTED:
            return state.merge({
                ...state,
                initialDocumentsList: updateDocumentsState(action.payload.documentsList, action.payload.dmsDocumentType, action.payload.documentChanges)
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_CHECK_RADIO_BUTTON_INE_DIR:
            const currentDocument = action.payload.currentDocument
            const documentType = action.payload.documentType;
            const indexDocument = state.get('initialDocumentsList').findIndex(doc => doc.dmsDocumentType === currentDocument);
            if (indexDocument !== -1) {
                const updatedDocumentsList = state.get('initialDocumentsList').map((doc, i) => {
                    i === indexDocument ? { ...doc, radioButton: documentType } : doc
                }
                );
                return state.merge({
                    initialDocumentsList: updatedDocumentsList
                });
            }
        case actionTypes.HIRE_CARD_CREDIT_INIT_SUCCESS:
            return state.merge({
                operationProcessIdInit: action.payload.response.operationProcessId,
                contractNumberAccount: action.payload.contractNumberAccount,
            });
        case actionTypes.HIRE_CARD_CREDIT_INE_SUCCESS:
            const newDocumentIne = {
                dmsDocumentType: "INE",
                documentName: "documentNameIne",
                file: 'data:application/pdf;base64,' + action.payload.base64,
                isAccepted: false,
                isRead: false,
                title: "hireCreditCard-titleINE",
                fileName: "INE_document",
                radioButton: null

            };
            return state.merge({
                documentINE: action.payload.base64,
                documentIdINE: action.payload.documentId,
                initialDocumentsList: state.get('initialDocumentsList').concat(newDocumentIne)
            });
        case actionTypes.HIRE_CARD_CREDIT_DIR_SUCCESS:
            const newDocumentDir = {
                dmsDocumentType: "DIR",
                documentName: "documentNameDir",
                file: 'data:application/pdf;base64,' + action.payload.base64,
                isAccepted: false,
                isRead: false,
                title: "hireCreditCard-titleDIR",
                fileName: "DIR_document",
                radioButton: null
            };
            return state.merge({
                documentDIR: action.payload.base64,
                documentIdDIR: action.payload.documentId,
                initialDocumentsList: state.get('initialDocumentsList').concat(newDocumentDir)
            });
        case actionTypes.HIRE_CARD_CREDIT_POLICY_SUCCESS:
            return state.merge({
                documentPolicy: action.payload.base64,
                documentIdPolicy: action.payload.documentId,
            });
        case actionTypes.RESET_INIT_CARDS_DOCUMENTS:
            return state.merge({
                initialDocumentsList: [],
                documentPolicy: false,
                allDocumentRead: true
            });
        default:
            return state;
    }
}

module.exports = cardModifyLimitReducer;

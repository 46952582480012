const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    valid: __CONTACT_CENTER__,
    agreementChecked: false,
    agreementIsDirty: false,
});

function validateStep(state) {
    return state.merge({
        valid: true,
        agreementIsDirty: false,

    });
}

function hireNewCreditCardStep4(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_NEW_CREDIT_CARD_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);
        default:
            return state;
    }
}

module.exports = hireNewCreditCardStep4;

module.exports = {
    title: 'contractsView-contractsView_checkingAccountCustomizeCardTitle',
    order: 4,
    footer_text: '',
    position: 'middle',
    override_validation_step: 5,
    components: [
        {
            id_component: 'card-image-selector',
            id: 'card-image-selector',
            cardImageIds: {
                DE: [
                    'B.506.013.011',
                    'B.506.013.012',
                    'B.506.013.013',
                ],
                NL: [
                    'B.506.019.011',
                    'B.506.019.012',
                    'B.506.019.013',
                ],
                PT: [
                    'B.506.015.011',
                    'B.506.015.012',
                    'B.506.015.013',
                ],
            },
            cardImageIdsFF: {
                DE: [
                    'B.506.013.011',
                    'B.506.013.014',
                    'B.506.013.015',
                    'B.506.013.018',
                    'B.506.013.013',
                    'B.506.013.012',
                    
                ],
                NL: [
                    'B.506.014.011',
                    'B.506.014.017',
                    'B.506.014.015',
                    'B.506.014.018',
                    'B.506.014.013',
                    'B.506.014.012',
                ],
                PT: [
                    'B.506.015.011',
                    'B.506.015.014',
                    'B.506.015.015',
                    'B.506.015.018',
                    'B.506.015.013',
                    'B.506.015.012',
                ],
                ES:[
                    "B.506.002.020", 
                ],
            },
            cardImageIdsStandardOff: {
                DE: [
                    'B.506.013.006',
                    'B.506.013.007',
                    'B.506.013.008',
                ],
                NL: [
                    'B.506.019.006',
                    'B.506.019.007',
                    'B.506.019.008',
                ],
                PT: [
                    'B.506.015.006',
                    'B.506.015.007',
                    'B.506.015.008',
                ],
            },
            cardImageIdsStandardOffFF: {
                DE: [
                    'B.506.013.006',
                    'B.506.013.009',
                    'B.506.013.010',
                    'B.506.013.017',
                    'B.506.013.008',
                    'B.506.013.007',
                ],
                NL: [
                    'B.506.014.006',
                    'B.506.014.009',
                    'B.506.014.010',
                    'B.506.014.017',
                    'B.506.014.008',
                    'B.506.014.007',
                ],
                PT: [
                    'B.506.015.006',
                    'B.506.015.009',
                    'B.506.015.010',
                    'B.506.015.017',
                    'B.506.015.008',
                    'B.506.015.007',
                ],
                ES: [
                    "B.506.002.020", 
                ],
            },
            onChange: 'checkingPayrollAccount_handleCardImageChange',
        },
        {
            id_component: 'card-pin-change',
            id: 'card-pin-change',
            onBlur: 'checkingPayrollAccount_handlePinBlur',
        },
    ]
};
const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    ...(__CONTACT_CENTER__ ? {
        agreementChecked: false,
        agreementIsDirty: false,
        valid: false,
        hireRestriction: false,
    }
        : {
            valid: false,
            hireRestriction: false,
        }),
});

function validateStep(state, action, validFlag) {
    const { isRevolving } = action.payload;
    if (validFlag !== undefined) {
        if (validFlag) {
            return state.merge({
                valid: false,
                hireRestriction: true,
            });
        } else {
            return state.merge({
                valid: true,
                hireRestriction: false,
            });
        }
    }

    if (isRevolving) {
        return state.merge({
            valid: true,
            agreementChecked: true,
            agreementIsDirty: false,
        });
    }

    return state.merge({
        valid: state.get('agreementChecked'),
        agreementIsDirty: true
    });
}

function hireNewCreditCardStep1(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_NEW_CREDIT_CARD_VALIDATE_STEP:
            return validateStep(state, action, action.payload.valid);
        default:
            return state;
    }
}

module.exports = hireNewCreditCardStep1;

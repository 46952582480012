module.exports = {
    timeoutError: {
        errorTitle: 'contractsView-payrollAccountHireTimeoutErrorTitle',
        errorBody: 'contractsView-payrollAccountHireTimeoutErrorBody',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    },
    fiocError: {
        errorTitle: 'contractsView-checkingAccountHire_contractualErrorTitle',
        errorBody: 'contractsView-accountContract_fiocErrorDescription',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    }
}

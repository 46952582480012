// @helpers
const includes = require('lodash/collection/includes');
const isArray = require('lodash/lang/isArray');
const some = require('lodash/collection/some');
const get = require('lodash/object/get');
// @utils
const { recordCommercialSpaceV2 } = require('utilities/tagging');
// @ constants
const { COMMERCIAL_ADS_TYPES, CROSS_SELLING_PRODUCTS } = require('constants/commercialAds');
const {
    ADOBE_NOT_INTERESTED_STATUS_CODE,
    ADOBE_INTERESTED_STATUS_CODE,
    ADOBE_REJECTED_STATUS_CODE,
} = require('constants/adobe.js')
const { COMMERCIAL_ADS_CHANNEL_ES, CMS_PRIVATE_PREFIX, CMS_PUBLIC_PREFIX } = require('constants/index');
const { VIRTUAL_DEBIT_CANCER_CARDART, VIRTUAL_DEBIT_PRIDE_CARDART } = require('constants/flagBasedFeatures');
const {
    COMMERCIAL_ADS_ACTION_EVENT,
    COMMERCIAL_ADS_CLICK_TEALIUM_EVENT,
    COMMERCIAL_ADS_EVENT_CATEGORY,
} = require('constants/tagging')

const pathRegex = /^\/(myprofile)?\/?/;

const getCommercialDataFromPath = (path, type, section) => {
    let commercialAdsType = COMMERCIAL_ADS_TYPES[path.replace(pathRegex, '')];

    if (isArray(commercialAdsType)) {
        if (section) {
            commercialAdsType = commercialAdsType.find((o) => (type === o.type && section === o.section));
        } else {
            commercialAdsType = commercialAdsType.find((o) => (type === o.type));
        }
    }

    return commercialAdsType;
};

const isAdobeFinalStatus = (status) => {
    const adobeFinalStatus = [ADOBE_NOT_INTERESTED_STATUS_CODE, ADOBE_INTERESTED_STATUS_CODE, ADOBE_REJECTED_STATUS_CODE];
    return adobeFinalStatus.includes(status)
}

const getIdsFromPath = (path, excludeType) => {
    const ids = [];
    const commercialAdsType = COMMERCIAL_ADS_TYPES[path.replace(pathRegex, '')];
    const id = get(commercialAdsType, 'id');

    if (isArray(commercialAdsType)) {
        commercialAdsType.forEach((ad) => {
            if (ad.type !== excludeType) {
                ids.push(ad.id);
            }
        });
    } else if (id) {
        ids.push(id)
    }

    return ids.toString().split(',');
};

const getFilteredCrossSellProduct = (id) => {
    const results = CROSS_SELLING_PRODUCTS.filter((product) => (id === product.id));

    return results ? results[0] : null;
};

const immAdIsNotEmpty = (immAd) => {
    return immAd && immAd.size && immAd.get('fieldImageLarge') && !immAd.get('fieldImageLarge').isEmpty();
};

const getCardIdAd = (immCard, commercialData) => {
    const typeCard = immCard.getIn(['contract', 'product']);
    const subTypeCard = immCard.getIn(['criteria', 'c1', 'subtipoproducto']);
    // I need only three last digit
    // const standardCodeCard =  immCard.getIn(['criteria', 'c1', 'estand']).slice(-3);
    let standardCodeCard = immCard.getIn(['criteria', 'c1', 'estand']);
    standardCodeCard = standardCodeCard && standardCodeCard.slice(-3);
    commercialData.id = `B.${typeCard}.${subTypeCard}.${standardCodeCard}`;

    return commercialData;
};

const getCardId = (immCard) => {
    const type = immCard.getIn(['contract', 'product']);
    const subtype = immCard.getIn(['criteria', 'c1', 'subtipoproducto']);
    const standard = immCard.getIn(['criteria', 'c1', 'estand']).slice(-3);

    return `B.${type}.${subtype}.${standard}`;
};

const getDefaultCardArtList = (immFlagBasedFeatures) => {
    let defaultCards = [
        'B.506.005.001', // AT HOME ABROAD
        'B.506.011.001', // CHARITY
        'B.501.002.003', // DIAMOND PACK
        'B.506.008.003', // DIAMOND PACK EXTRA IMAGE
        'B.506.003.002', // ECARD
        'B.506.016.001', // ECARD DE
        'B.506.017.001', // ECARD NL
        'B.506.018.001', // ECARD PT
        'B.500.804.001', // HORMIGUERO CARD
        'B.506.007.002', // MINICARD
        'B.500.801.012', // OPEN CREDIT
        'B.506.002.020', // OPEN DEBIT
        'B.506.014.001', // OPEN DEBIT NL
        'B.506.015.001', // OPEN DEBIT PT
        'B.506.016.001', // OPEN DEBIT DE
        'B.506.013.012', // OPEN DEBIT R42 DE STANDARD VIP
        'B.506.015.012', // OPEN DEBIT R42 PT STANDARD VIP
        'B.506.014.017', // OPEN DEBIT R42 NL STANDARD VIP
        "B.506.013.007", // OPEN DEBIT R42 DE OFF
        "B.506.014.017", // OPEN DEBIT R42 NL OFF
        "B.506.015.007", // OPEN DEBIT R42 PT OFF
        'B.506.019.012', // MAESTRO NL STANDARD VIP
        "B.506.019.007", // MAESTRO NL OFF
        'B.506.020.001', // METALICA DE
        'B.506.021.001', // METALICA NL
        'B.506.022.001', // METALICA PT
        'B.500.802.014', // NERUDA
        'B.506.010.001', // OPEN YOUNG
        'B.501.003.003', // PREMIUM PACK
        'B.506.009.003', // PREMIUM PACK EXTRA IMAGE
        'B.500.003.001', // REVOLVING
        'B.500.805.001', // VANITY FAIR
        'B.532.001.001', // VIA-T
        'B.500.803.001', // VIRTUAL CREDIT
    ];

    if (immFlagBasedFeatures.get(VIRTUAL_DEBIT_CANCER_CARDART)) {
        defaultCards.push('B.506.012.004'); // VIRTUAL DEBIT CARD CANCER CARD ART
    } else if (immFlagBasedFeatures.get(VIRTUAL_DEBIT_PRIDE_CARDART)) {
        defaultCards.push('B.506.012.003'); // VIRTUAL DEBIT CARD PRIDE CARD ART
    } else {
        defaultCards.push('B.506.012.001'); // VIRTUAL DEBIT CARD
    }

    return defaultCards;
};

const getNerudaCardArtList = [
    'B.500.802.007',
    'B.500.802.008',
    'B.500.802.009',
    'B.500.802.010',
    'B.500.802.011',
    'B.500.802.012',
];// NERUDA OFF Я42


const getCommercialCardsList = (immCards, immFlagBasedFeatures, immProfile) => {
    let commercialCardsList = !!immProfile && immProfile.get('isNerudaUser') ? getNerudaCardArtList : getDefaultCardArtList(immFlagBasedFeatures);

    immCards.get('byOrder').map((cardId) => {
        const immCard = immCards.getIn(['byId', cardId]);
        const typeCard = immCard.getIn(['contract', 'product']);
        const subTypeCard = immCard.getIn(['criteria', 'c1', 'subtipoproducto']);
        // I need only three last digit
        let standardCodeCard = immCard.getIn(['criteria', 'c1', 'estand']);
        standardCodeCard = standardCodeCard && standardCodeCard.slice(-3);
        const commercialCardId = `B.${typeCard}.${subTypeCard}.${standardCodeCard}`;

        if (!includes(commercialCardsList, commercialCardId)) {
            commercialCardsList.push(commercialCardId);
        }
    });

    return commercialCardsList;
};

const hasPublicUrl = (bannerUri) => {
    const publicUrls = [
        /* REMOVE-IN-PROD:START */
        'aws.ok-cloud.net',
        /* REMOVE-IN-PROD:END */
        'openbank.es',
        'openbank.de',
        'openbank.nl',
        'openbank.pt',
        CMS_PUBLIC_PREFIX
    ];

    return some(publicUrls, (publicUrl) => includes(bannerUri, publicUrl));
};

const hasPrivateUrl = (url) => url.indexOf(CMS_PRIVATE_PREFIX) > -1;

const formatRedirectUrl = (bannerUri) => {
    return `${bannerUri}?channel=${COMMERCIAL_ADS_CHANNEL_ES}&referer=${window.location.href}`;
};

const doTaggingForCommercialAdsClick = (immAd, isAdobeV2Enabled = false) => {
    if (isAdobeV2Enabled) {
        const taggingParams = {
            categoriaEvento: COMMERCIAL_ADS_EVENT_CATEGORY + immAd.getIn(['offerCode']),
            accionEvento: COMMERCIAL_ADS_ACTION_EVENT,
            tealiumEvent: COMMERCIAL_ADS_CLICK_TEALIUM_EVENT,
        }
        const taggingCustomData = {
            promotion_id: [immAd.get('fieldCmsId')],
            promotion_name: [immAd.get('title')],
            promotion_creative: [`<div>${immAd.getIn(['body', 'value'], '')}</div>`],
            promotion_position: [immAd.get('adId')],
        }
        recordCommercialSpaceV2(taggingParams, taggingCustomData);
    }
}

module.exports = {
    doTaggingForCommercialAdsClick,
    formatRedirectUrl,
    getCardId,
    getCardIdAd,
    getCommercialCardsList,
    getCommercialDataFromPath,
    getFilteredCrossSellProduct,
    getIdsFromPath,
    hasPrivateUrl,
    hasPublicUrl,
    immAdIsNotEmpty,
    isAdobeFinalStatus
};

// @ helpers
import { i18n } from 'core/i18n'
// @constants
import { SAVINGS_ACCOUNT_TYPE, SAVINGS_ACCOUNT_STANDARD, SAVINGS_ACCOUNT_SUBTYPE, PERCENTAGE_SYMBOL } from 'constants/index'
import { E_ACCOUNT, messages } from 'constants/operationTypes'
import { E_ACCOUNT_LOAN_PRODUCT_ALIAS, SAVING_ACCOUNT_ALIAS, BANKING_SAVING_ACCOUNT_CONTRACTUAL, BANKING_SAVING_ACCOUNT_PRECONTRACTUAL } from 'constants/hiringProductInformation'
import { E_ACCOUNT_LOAN_GDPR_PID } from 'constants/gdprPlacementIds'
// @steps
import step1 from './step1'
import step2 from './step2'
import step3 from './step3'
import successStep from './successStep'

// @Config
import { stepsWebConfig, stepsCCConfig } from './steps-config'
import customErrors from './customErrors'

const { E_ACCOUNT_AND_GDPR } = messages
const { formatText } = i18n
const stepsConfig = __CONTACT_CENTER__ ? stepsCCConfig : stepsWebConfig;

const getContract = (notAllowedTAECountriesEAccountLoan = true, notAllowedTINCountriesEAccountLoan = true) => ({
    ...stepsConfig,
    title: 'contractsView-eAccountTitle',
    service: '....',
    onCancelURL: '/myprofile/accounts',
    successStepType: 'dynamic',
    SignParamsFn: 'eAccount_SignParams',
    showHelpBar: true,
    errorTitle: 'contractsView-errorTitle',
    errorMessage: 'contractsView-errorMessage',
    errorBackButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
    errorBackButtonLink: '/myprofile/accounts',
    accountAlias: SAVING_ACCOUNT_ALIAS,
    type: SAVINGS_ACCOUNT_TYPE,
    subtype: SAVINGS_ACCOUNT_SUBTYPE,
    standard: SAVINGS_ACCOUNT_STANDARD,
    warningContractMessage: 'contractsView-eAccountWarningMessage',
    operativeName:'operatives-eAccountLoan',
    termsAndCondsKey: E_ACCOUNT_LOAN_PRODUCT_ALIAS,
    biggerLegalTexts: true,
    preContractualAlias: BANKING_SAVING_ACCOUNT_PRECONTRACTUAL,
    contractualAlias: BANKING_SAVING_ACCOUNT_CONTRACTUAL,
    hasPolling: true,
    tealiumStepsLabels: [
        ...(!__CONTACT_CENTER__ ? ['precontractual'] : []),
        'legalinfo',
        'selection',
        'confirmation',
    ],
    tealiumName: 'contractingAccount',
    customTagging: 'deposittransfer_customTagging',
    code_op: E_ACCOUNT,
    product: E_ACCOUNT_LOAN_PRODUCT_ALIAS,
    alternativePositionedCheckboxes: false,
    breadcrumb: [
        {
            text: 'breadcrumb-accounts',
            url: '/myprofile/accounts'
        },
        {
            text: 'contractsView-eAccountBreadcrumb'
        }
    ],
    state: {
        card_details_title: 'contractsView-cardDetailsTitle',
        card_details: [
            // This need to stay commented until service improvement

            // {
            //     id: 'monto_traspasar',
            //     label: 'contractsView-amountToBeTransferred',
            //     value: null,
            //     type: 'single',
            //     format_type: EURO_SYMBOL,
            //     formatNumber: true,
            //     order: 1,
            // confirmation_step: {
            //     columns: 4,
            //     highlighted: true,
            //     order: 1,
            //     row: 1
            // },
            //     visible: [
            //         {
            //             step: 1,
            //             order: 1
            //         },
            //         {
            //             step: 2,
            //             order: 1
            //         },
            //         {
            //             step: 3,
            //             order: 1
            //         }
            //     ]
            // },
            {
                id: 'tin',
                label: 'contractsView-tin',
                format_type: PERCENTAGE_SYMBOL,
                value: null,
                type: 'single',
                notImportantValue: true,
                order: 2,
                visible: notAllowedTINCountriesEAccountLoan && [
                    {
                        step: 1,
                        order: 2
                    },
                    {
                        step: 2,
                        order: 2
                    },
                    {
                        step: 3,
                        order: 2
                    },
                    {
                        step: 4,
                        order: 2
                    }
                ],
            },
            {
                id: 'tinConfirmationStep',
                label: 'contractsView-tinConfirmationStep',
                format_type: PERCENTAGE_SYMBOL,
                value: null,
                type: 'single',
                notImportantValue: true,
                order: 5,
                confirmation_step: {
                    columns: 3,
                    order: 1,
                    row: 1
                },
                visible: []
            },
            {
                id: 'tae',
                label: 'contractsView-tae',
                value: null,
                format_type: PERCENTAGE_SYMBOL,
                type: 'single',
                order: 3,
                notImportantValue: true,
                visible: notAllowedTAECountriesEAccountLoan && [
                    {
                        step: 1,
                        order: 3
                    },
                    {
                        step: 2,
                        order: 3
                    },
                    {
                        step: 3,
                        order: 3
                    },
                    {
                        step: 4,
                        order: 3
                    }
                ],
                confirmation_step: notAllowedTAECountriesEAccountLoan && {
                    columns: 3,
                    order: 2,
                    row: 1
                },
            },
            {
                id: 'account_term',
                label: 'contractsView-accountTerm',
                value: null,
                type: 'single',
                format_type: '',
                not_null: false,
                isLanguageString: true,
                injectedStringValues: null,
                order: 4,
                confirmation_step: {
                    columns: 2,
                    order: 3,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 4
                    },
                    {
                        step: 2,
                        order: 4
                    },
                    {
                        step: 3,
                        order: 4
                    },
                    {
                        step: 4,
                        order: 4
                    }
                ]
            },
            {
                type: 'separator',
                order: 5,
                visible: [
                    {
                        step: 1,
                        order: 5
                    },
                    {
                        step: 2,
                        order: 5
                    },
                    {
                        step: 3,
                        order: 5
                    },
                    {
                        step: 4,
                        order: 5
                    },
                ]
            },
            {
                id: 'cta_seleccionada',
                label: 'contractsView-associatedAccount',
                value: null,
                type: 'single',
                format_type: '',
                order: 6,
                confirmation_step: {
                    columns: 4,
                    order: 4,
                    row: 2
                },
                visible: [
                    {
                        step: 1,
                        order: 6
                    },
                    {
                        step: 2,
                        order: 6
                    },
                    {
                        step: 3,
                        order: 6
                    },
                    {
                        step: 4,
                        order: 6
                    }
                ],
                isErrorVisible: false
            },
            {
                id: 'intervinientes_list',
                label: 'contractsView-interveners',
                value: null,
                type: 'interveners',
                format_type: '',
                order: 7,
                visible: [
                    {
                        step: 1,
                        order: 7
                    },
                    {
                        step: 2,
                        order: 7
                    },
                    {
                        step: 3,
                        order: 7
                    },
                    {
                        step: 4,
                        order: 7
                    }
                ]
            },
            {
                id: 's_accounts',
                label: '',
                value: null,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'n_steps',
                label: '',
                value: __CONTACT_CENTER__ ? 3 : 4,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            }
        ],
        confirmationCheckboxes: {
            areValid: false,
            list: [
                {
                    id: 'service_contract_checkbox',
                    idName: 'serviceContract',
                    value: false,
                    isRequired: true,
                    errorState: false,
                    errorMessage: 'contractsView-serviceContractErrorMessage',
                    labels: [
                        {
                            text: 'contractsView-eAccountServiceContractLabelFirst'
                        },
                        {
                            text: 'contractsView-eAccountServiceContractLabelAccountPrecontractual',
                            id: 'btn_contractsView_saving-document-dynamic'
                        },
                        {
                            text: 'contractsView-eAccountServiceContractLabelAccountAndThe',
                        },
                        {
                            text: 'contractsView-eAccountServiceContractLabelAccountDIC',
                            id: 'btn_contractsView_saving-dic-document',
                            href: null,
                        }
                    ]
                }
            ]
        },
        preContractDocuments : {
            preContractPdfFileName: "documentosCuentadeAhorroOpenbank",
            preContractPdfTitle: formatText('preContractDocument-preContractDocument_eAccountPdfTitle')
        },
        precontractualConfirmationLabel: [
            {
                text: 'contractsView-contractsView_savingAccountPrecontractualText1'
            }
        ],
        preContractConditionsDocuments: {
            preContractPdfFileName: "documentosPreContractualesCuentadeAhorroOpenbank",
            preContractPdfTitle: formatText('preContractDocument-preContractDocument_checkingAccountPdfTitle'),
        },
    },
    steps: [
        step1,
        ...(!__CONTACT_CENTER__ ? [step2] : []),
        step3,
    ],
    successStep,
    customErrors,
});

module.exports = {
    getContract
};

// @ vendors
const Immutable = require('immutable');
//utilities
const formValidationHelper = require('../utilities/formValidationHelper');
const { findElementbyId } = require('../utilities/contractStateHelper');
// @ constants
const {
    ACCOUNT_USE_PROFESSIONAL,
    ACCOUNT_USE_PROFESSIONAL_INVESTMENT,
    RENTIER_CODE,
    TAX_IDENTIFICATION_NUMBER_MIN_LENGTH,
    WITHOUT_ACTIVITY_CODE
} = require('constants/index');

const SIGNIFICATIVE_OPERATIONS = [
    'operations_dangerous_countries',
    'checks_holder',
    'checks_traveler',
    'buying_and_selling_foreing_banknotes',//Typo "foreing" instead of "foreign" in the original field name
    'delivery_or_cash_withdrawals',
    'foreing_trade_operations',//Typo "foreing" instead of "foreign" in the original field name
    'none_of_the_above',
    'payment_orders_or_collection_abroad'
];

function tdIncomeAnswer(cardDetails, prefix) {
    let string = '';

    const income_activities = findElementbyId(cardDetails, prefix + 'income_activities');
    const income_business = findElementbyId(cardDetails, prefix + 'income_business');
    const income_description = findElementbyId(cardDetails, prefix + 'income_description');
    const income_other = findElementbyId(cardDetails, prefix + 'income_other');
    const income_realState = findElementbyId(cardDetails, prefix + 'income_realState');
    const income_work = findElementbyId(cardDetails, prefix + 'income_work');

    income_work && (string += '76$');
    income_activities && (string += '77$');
    income_business && (string += '78$');
    income_realState && (string += '79$');
    string += income_other ? `${income_description}\$` : ' ';

    return (string += '#');
}

function tdCapitalAnswer(cardDetails, prefix) {
    let string = '';

    const capital_activities = findElementbyId(cardDetails, prefix + 'capital_activities');
    const capital_business = findElementbyId(cardDetails, prefix + 'capital_business');
    const capital_description = findElementbyId(cardDetails, prefix + 'capital_description');
    const capital_heritage = findElementbyId(cardDetails, prefix + 'capital_heritage');
    const capital_other = findElementbyId(cardDetails, prefix + 'capital_other');
    const capital_realState = findElementbyId(cardDetails, prefix + 'capital_realState');
    const capital_work = findElementbyId(cardDetails, prefix + 'capital_work');
    const capital_gambling = findElementbyId(cardDetails, prefix + 'capital_gambling');

    capital_work && (string += '66$');
    capital_activities && (string += '67$');
    capital_heritage && (string += '68$');
    capital_business && (string += '69$');
    capital_realState && (string += '70$');
    capital_gambling && (string += '104$');
    string += capital_other ? `${capital_description}\$` : ' ';

    return (string += '#');
}

function tdIncomeOriginAnswer(cardDetails, prefix) {
    let string = '';

    const income_origin_benefits = findElementbyId(cardDetails, prefix + 'income_origin_benefits');
    const income_origin_family_help = findElementbyId(cardDetails, prefix + 'income_origin_family_help');
    const income_origin_financial_investments = findElementbyId(cardDetails, prefix + 'income_origin_financial_investments');
    const income_origin_investment_property = findElementbyId(cardDetails, prefix + 'income_origin_investment_property');

    income_origin_benefits && (string += '252$');
    income_origin_family_help && (string += '253$');
    income_origin_financial_investments && (string += '254$');
    income_origin_investment_property && (string += '255$');

    return (string += '#');
}

function tdAnnualIncome(cardDetails, prefix) {
    const incomes_annual = findElementbyId(cardDetails, prefix + 'incomes_annual') || ' # ';

    return `${incomes_annual}#`;
}

function tdPublicCharges(cardDetails, prefix) {
    let string = '';

    const has_pep = findElementbyId(cardDetails, prefix + 'has_pep');

    if (has_pep) {
        const has_pep_details = findElementbyId(cardDetails, prefix + 'has_pep_details');

        string += 'SI$';
        has_pep_details && (string += `${has_pep_details}\$`);
        string += '#';
    } else {
        string += '75#';
    }

    return string;
}

function tdThirdPartyAnswer(cardDetails, prefix) {
    const operations_account = findElementbyId(cardDetails, prefix + 'operations_account');
    return operations_account ? '81#' : '82#';
}

function tdPurpose(cardDetails, prefix) {
    const account_use = findElementbyId(cardDetails, prefix + 'account_use');
    let string = '';

    account_use === 1 && (string += '83#');
    account_use === 2 && (string += '84#');
    account_use === 3 && (string += '85#');

    return string;
}

function tdPurpose2(cardDetails, prefix) {
    const account_use = findElementbyId(cardDetails, prefix + 'account_use_2');
    let string = '';

    account_use === 1 && (string += '83#');
    account_use === 3 && (string += '85#');

    return string;
}

function tdOperationsAnswer(cardDetails, prefix) {
    let string = '';

    const operations_dangerous_countries = findElementbyId(
        cardDetails,
        prefix + 'operations_dangerous_countries'
    );

    const checks_holder = findElementbyId(
        cardDetails,
        prefix + 'checks_holder'
    );

    const checks_traveler = findElementbyId(
        cardDetails,
        prefix + 'checks_traveler'
    );

    const buying_and_selling_foreing_banknotes = findElementbyId(
        cardDetails,
        prefix + 'buying_and_selling_foreing_banknotes'
    );

    const delivery_or_cash_withdrawals = findElementbyId(
        cardDetails,
        prefix + 'delivery_or_cash_withdrawals'
    );
    const foreing_trade_operations = findElementbyId(
        cardDetails,
        prefix + 'foreing_trade_operations'
    );
    const none_of_the_above = findElementbyId(cardDetails, prefix + 'none_of_the_above');
    const payment_orders_or_collection_abroad = findElementbyId(
        cardDetails,
        prefix + 'payment_orders_or_collection_abroad'
    );

    operations_dangerous_countries && (string += '105$');
    checks_holder && (string += '106$');
    checks_traveler && (string += '86$');
    delivery_or_cash_withdrawals && (string += '87$');
    buying_and_selling_foreing_banknotes && (string += '88$');
    payment_orders_or_collection_abroad && (string += '89$');
    foreing_trade_operations && (string += '90$');
    none_of_the_above && (string += '91$');

    return (string += '#');
}

function getTrabajasPor(cardDetails, prefix = '') {
    let trabajasPor = findElementbyId(cardDetails, prefix + 'fioc_ocupation');
    switch (trabajasPor) {
        case 0:
            trabajasPor = '01';
            break;
        case 1:
            trabajasPor = '02';
            break;
        case 2:
        case 3:
        case 4:
            trabajasPor = '05';
    }
    return trabajasPor;
}

function getProfession(cardDetails, prefix = '') {
    const ocupation = findElementbyId(cardDetails, prefix + 'fioc_ocupation');
    let profession;

    switch (parseInt(ocupation)) {
        case 0: // self employed / cuenta propia
            profession = findElementbyId(cardDetails, prefix + 'profession_cnae') ||
                findElementbyId(cardDetails, prefix + 'profession_current');
            profession = profession.substr(1);
            break;

        case 1: // employee / cuenta ajena
            profession = findElementbyId(cardDetails, prefix + 'fioc_profession_category');
            break;

        case 2: // Rentier / rentista
            profession = 'X200B';
            break;

        case 3: // Pensioner / Pensionista
            profession = 'PJ00';
            break;

        case 4: // Unenployed / desempleado
            const your_job = findElementbyId(cardDetails, prefix + 'your_job');
            const professions = ['X300B', 'X200A', 'X300A'];
            profession = professions[your_job];
            break;
    }

    return profession;
}

function getActivity(cardDetails, prefix = '') {
    const ocupation = findElementbyId(cardDetails, prefix + 'fioc_ocupation');
    const activity = parseInt(ocupation) === 1 ? findElementbyId(cardDetails, prefix + 'fioc_profession') : "";
    return activity;
}

function buildTerceraDirectiva(cardDetails, prefix = '') {

    const trabajasPor = findElementbyId(cardDetails, prefix + 'fioc_ocupation');
    let has_pep = findElementbyId(cardDetails, prefix + 'has_pep');
    let string = ' #';
    let account_use = '';
    let income_origin_string = ' #';

    switch (trabajasPor) {
        case 0: // self employed / cuenta propia
            account_use = findElementbyId(cardDetails, prefix + 'account_use');

            string += tdCapitalAnswer(cardDetails, prefix);
            string += tdAnnualIncome(cardDetails, prefix);
            string += tdPublicCharges(cardDetails, prefix);
            string += has_pep ? tdIncomeAnswer(cardDetails, prefix) : ' #';
            string += tdThirdPartyAnswer(cardDetails, prefix);
            string += tdPurpose(cardDetails, prefix);
            string +=
                account_use === 2 || account_use === 3
                    ? tdOperationsAnswer(cardDetails, prefix)
                    : ' #';

            if (!has_pep && [2, 3].includes(account_use)) {
                string += tdIncomeAnswer(cardDetails, prefix);
            } else {
                string += ' #';
            }

            break;

        case 1: // employee / cuenta ajena
            account_use = findElementbyId(cardDetails, prefix + 'account_use_2');

            string += tdCapitalAnswer(cardDetails, prefix);
            string += tdAnnualIncome(cardDetails, prefix);
            string += tdPublicCharges(cardDetails, prefix);
            string += has_pep ? tdIncomeAnswer(cardDetails, prefix) : ' #';
            string += tdThirdPartyAnswer(cardDetails, prefix);
            string += tdPurpose2(cardDetails, prefix);
            string +=
                account_use === 2 || account_use === 3
                    ? tdOperationsAnswer(cardDetails, prefix)
                    : ' #';

            if (!has_pep && account_use === 3) {
                string += tdIncomeAnswer(cardDetails, prefix);
            } else {
                string += ' #';
            }

            break;

        case 2: // Rentier / rentista
            account_use = findElementbyId(cardDetails, prefix + 'account_use_2');

            string += tdCapitalAnswer(cardDetails, prefix);
            string += tdAnnualIncome(cardDetails, prefix);
            string += tdPublicCharges(cardDetails, prefix);
            string += tdIncomeAnswer(cardDetails, prefix);
            string += tdThirdPartyAnswer(cardDetails, prefix);
            string += tdPurpose2(cardDetails, prefix);
            string += account_use === 3 ? tdOperationsAnswer(cardDetails, prefix) : ' #';
            string += ' #';

            break;

        case 3: // Pensioner / Pensionista
            account_use = findElementbyId(cardDetails, prefix + 'account_use_2');

            string += tdCapitalAnswer(cardDetails, prefix);
            string += tdAnnualIncome(cardDetails, prefix);
            string += tdPublicCharges(cardDetails, prefix);
            string += ' #';
            string += tdThirdPartyAnswer(cardDetails, prefix);
            string += tdPurpose2(cardDetails, prefix);
            string += account_use === 3 ? tdOperationsAnswer(cardDetails, prefix) : ' #';

            if (!has_pep && account_use === 3) {
                string += tdIncomeAnswer(cardDetails, prefix);
            } else {
                string += ' #';
            }

            break;

        case 4: // Unenployed / desempleado
            account_use = findElementbyId(cardDetails, prefix + 'account_use_2');

            string += tdCapitalAnswer(cardDetails, prefix);
            string += ' #';
            string += tdPublicCharges(cardDetails, prefix);
            string += ' #';
            string += tdThirdPartyAnswer(cardDetails, prefix);
            string += tdPurpose2(cardDetails, prefix);
            string += account_use === 3 ? tdOperationsAnswer(cardDetails, prefix) : ' #';

            if (!has_pep && account_use === 3) {
                string += tdIncomeAnswer(cardDetails, prefix);
            } else {
                string += ' #';
            }

            income_origin_string = tdIncomeOriginAnswer(cardDetails, prefix);

            break;
    }

    return string + ' # # # ' + income_origin_string;
}

function shouldValidate(prefix, componentsIds) {
    const mandatoryFields = [];

    componentsIds.forEach(componentId => {
        const id = prefix + componentId;

        document.getElementById(id) && mandatoryFields.push(id);
    });

    return mandatoryFields;
}

function getSignificativeOperations(prefix) {
    return SIGNIFICATIVE_OPERATIONS.map(option => prefix + option);
}

function validateForeignTax(cardDetails, validFields, prefix = '') {
    const mandatoryArrayField = findElementbyId(cardDetails, prefix + 'foreign_tax');
    const isValid = mandatoryArrayField !== null && mandatoryArrayField !== '';
    let validations = [];

    validFields.push(isValid);
    validations[0] = !isValid;

    if (isValid && mandatoryArrayField.get(0)) {
        validations[1] = [];
        mandatoryArrayField.get(1).forEach((element, index) => {
            validations[1][index] = [];
            element.forEach((value, key) => {
                let isValid = value !== null && value !== '';
                if (isValid && key === 'tin') {
                    isValid = value.trim().length >= TAX_IDENTIFICATION_NUMBER_MIN_LENGTH;
                }
                validFields.push(isValid);
                validations[1][index][key] = !isValid;
            });
        });
    }

    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        prefix + 'foreign_tax',
        validations
    );

    return {
        cardDetails,
        validFields
    };
}

function validateFields(cardDetails, validFields, mandatoryFields) {
    mandatoryFields.forEach(mandatoryField => {
        const criteria = findElementbyId(cardDetails, mandatoryField, undefined, false);
        let isVisible = true;
        let validatedCardDetails = {
            cardDetails,
            isValid: true
        };

        if (criteria && criteria.get('isVisible')) {
            const isVisibleTarget = criteria.getIn(['isVisible', 'target']);
            const associatedComponent = cardDetails.find(
                component => component.get('target') && component.get('target') === isVisibleTarget
            );
            const associatedComponentValue =
                associatedComponent && associatedComponent.get('value');

            isVisible =
                associatedComponentValue &&
                criteria.getIn(['isVisible', 'value']).includes(associatedComponentValue);
        }

        if (isVisible && (criteria.get('required') || criteria.get('validations'))) {
            validatedCardDetails = formValidationHelper.validateFields(
                cardDetails,
                criteria.toJS()
            );
        }

        cardDetails = formValidationHelper.updateValue(
            Immutable.fromJS(validatedCardDetails.cardDetails),
            mandatoryField,
            !validatedCardDetails.isValid
        );
        validFields.push(validatedCardDetails.isValid);
    });

    return {
        cardDetails,
        validFields
    };
}

function validateCheckFields(cardDetails, validFields, mandatoryCheckFields) {
    let isChecksValid = true;

    mandatoryCheckFields.find(mandatoryField => {
        isChecksValid = !!findElementbyId(cardDetails, mandatoryField);
        cardDetails = formValidationHelper.updateValue(cardDetails, mandatoryField, !isChecksValid);

        return isChecksValid;
    });

    validFields.push(isChecksValid);

    return {
        cardDetails,
        validFields
    };
}

function validateOnlyIfPreviousCompleted(cardDetails, validFields, previousFieldId, currentFieldId, prefix = '') {
    let response = {
        cardDetails,
        validFields
    };
    const previousFieldValue = findElementbyId(cardDetails, prefix + previousFieldId);
    if (previousFieldValue) {
        response = validateFields(cardDetails, validFields, [prefix + currentFieldId]);
    }
    return response;
}

function validateSelfEmployedProfession(cardDetails, validFields, prefix = '') {
    let mandatoryFields = [prefix + 'fioc_cnae'];
    switch (findElementbyId(cardDetails, prefix + 'fioc_cnae')) {
        case 1:
            mandatoryFields.push(prefix + 'profession_cnae');
            break;
        case 2:
            mandatoryFields.push(prefix + 'profession_category');
            break;
    }
    return validateFields(cardDetails, validFields, mandatoryFields);
}

function validateCurrentProfession(cardDetails, validFields, prefix = '') {
    return validateOnlyIfPreviousCompleted(cardDetails, validFields, 'profession_category', 'profession_current', prefix);
}

function validateProfessionalSector(cardDetails, validFields, prefix = '') {
    return validateOnlyIfPreviousCompleted(cardDetails, validFields, 'profession_sector_categories', 'profession_sector_specific', prefix);
}

function validateCategory(cardDetails, validFields, prefix = '') {
    let response = {
        cardDetails,
        validFields
    };
    response = validateFields(cardDetails, validFields, [`${prefix}fioc_profession_category`]);
    return response;
}

function validateCapital(cardDetails, validFields, prefix = '') {
    prefix += 'capital_';
    const hasCapitalOther = findElementbyId(cardDetails, prefix + 'other');
    const componentsIds = [
        'activities',
        'business',
        'heritage',
        'label',
        'other',
        'realState',
        'work',
        'gambling'
    ];
    const mandatoryCheckFields = shouldValidate(prefix, componentsIds);
    let result = {};

    if (hasCapitalOther) {
        result = validateFields(cardDetails, validFields, [prefix + 'description']);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    result = validateCheckFields(cardDetails, validFields, mandatoryCheckFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateIncome(cardDetails, validFields, prefix = '') {
    prefix += 'income_';
    const hasIncomeOther = findElementbyId(cardDetails, prefix + 'other');
    const componentsIds = ['activities', 'business', 'label', 'other', 'realState', 'work'];
    const mandatoryCheckFields = shouldValidate(prefix, componentsIds);
    let result = {};

    if (hasIncomeOther) {
        result = validateFields(cardDetails, validFields, [prefix + 'description']);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    result = validateCheckFields(cardDetails, validFields, mandatoryCheckFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateHasPep(cardDetails, validFields, prefix = '') {
    const hasPep = findElementbyId(cardDetails, prefix + 'has_pep');
    const mandatoryFields = shouldValidate(prefix, ['has_pep']);
    const ocupation = findElementbyId(cardDetails, 'fioc_ocupation');
    let result = {};

    if (hasPep) {
        mandatoryFields.push(prefix + 'has_pep_details');

        if (ocupation !== RENTIER_CODE && ocupation !== WITHOUT_ACTIVITY_CODE) {
            result = validateIncome(cardDetails, validFields, prefix);
            cardDetails = result.cardDetails;
            validFields = result.validFields;
        }
    }

    result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateAccountUse(cardDetails, validFields, prefix = '') {
    const accountUseValue = findElementbyId(cardDetails, prefix + 'account_use');
    const hasPepValue = findElementbyId(cardDetails, prefix + 'has_pep');
    const mandatoryFields = shouldValidate(prefix, ['account_use']);
    let result = {};

    if ([ACCOUNT_USE_PROFESSIONAL, ACCOUNT_USE_PROFESSIONAL_INVESTMENT].includes(accountUseValue)) {
        result = validateCheckFields(cardDetails, validFields, getSignificativeOperations(prefix));
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    if (accountUseValue === ACCOUNT_USE_PROFESSIONAL_INVESTMENT && hasPepValue === 0) {
        result = validateIncome(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateAccountUse2HasPep(cardDetails, validFields, prefix = '') {
    const accountUse2Value = findElementbyId(cardDetails, prefix + 'account_use_2');
    const hasPepValue = findElementbyId(cardDetails, prefix + 'has_pep');
    const mandatoryFields = shouldValidate(prefix, ['account_use_2']);
    let result = {};

    if (accountUse2Value === 2 || accountUse2Value === ACCOUNT_USE_PROFESSIONAL_INVESTMENT) {
        result = validateCheckFields(cardDetails, validFields, getSignificativeOperations(prefix));
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    if (accountUse2Value === ACCOUNT_USE_PROFESSIONAL_INVESTMENT && hasPepValue === 0) {
        result = validateIncome(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateProfession(cardDetails, validFields, prefix = '') {
    return validateFields(cardDetails, validFields, [`${prefix}fioc_profession_category`]);
}

function validateSelfEmployed(cardDetails, validFields, prefix = '', isNeruda = false) {
    const componentsIdsDefault = [
        'activities',
        'company_name',
        'incomes_annual',
        'profession_cnae',
        `${prefix}fioc_profession_category`
    ];
    const mandatoryFields = shouldValidate(prefix, componentsIdsDefault);

    let result = validateSelfEmployedProfession(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCurrentProfession(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateProfessionalSector(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCapital(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    if (!isNeruda) {
        result = validateHasPep(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateAccountUse(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateForeignTax(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    return {
        cardDetails,
        validFields
    };
}

function validateEmployed(cardDetails, validFields, prefix = '', isNeruda = false) {
    const componentsIdsDefault = [
        'company_name',
        'incomes_annual',
        'profession_cno',
    ];
    const componentsIds = !isNeruda && [...componentsIdsDefault, 'operations_account'];
    const mandatoryFields = shouldValidate(prefix, componentsIds);

    let result = validateProfession(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCategory(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateCapital(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    if (!isNeruda) {
        result = validateHasPep(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateAccountUse2HasPep(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateForeignTax(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    return {
        cardDetails,
        validFields
    };
}

function validateRentier(cardDetails, validFields, prefix = '', isNeruda = false) {
    const componentsIdsDefault = ['incomes_annual'];
    const componentsIds = !isNeruda && [...componentsIdsDefault, 'operations_account'];
    const mandatoryFields = shouldValidate(prefix, componentsIds);

    let result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    result = validateIncome(cardDetails, validFields, prefix);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    if (!isNeruda) {
        result = validateCapital(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateHasPep(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateAccountUse(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateForeignTax(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    return {
        cardDetails,
        validFields
    };
}

function validatePensioner(cardDetails, validFields, prefix = '', isNeruda = false) {
    const componentsIdsDefault = ['incomes_annual'];
    const componentsIds = !isNeruda && [...componentsIdsDefault, 'operations_account'];
    const mandatoryFields = shouldValidate(prefix, componentsIds);

    let result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    if (!isNeruda) {
        result = validateCapital(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateHasPep(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateAccountUse(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateForeignTax(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    return {
        cardDetails,
        validFields
    };
}

function validateIncomeOrigin(cardDetails, validFields, prefix = '') {
    prefix += 'income_origin_';
    const componentsIds = [
        'benefits',
        'family_help',
        'financial_investments',
        'investment_property',
    ];
    const mandatoryCheckFields = shouldValidate(prefix, componentsIds);
    let result = {};

    result = validateCheckFields(cardDetails, validFields, mandatoryCheckFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    return {
        cardDetails,
        validFields
    };
}

function validateWithoutActivity(cardDetails, validFields, prefix = '', isNeruda = false) {
    const componentsIdsDefault = ['operations_account'];
    const componentsIds = !isNeruda && [...componentsIdsDefault, 'your_job'];
    const mandatoryFields = shouldValidate(prefix, componentsIds);

    let result = validateFields(cardDetails, validFields, mandatoryFields);
    cardDetails = result.cardDetails;
    validFields = result.validFields;

    if (!isNeruda) {

        result = validateCapital(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateIncomeOrigin(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateHasPep(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateAccountUse(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;

        result = validateForeignTax(cardDetails, validFields, prefix);
        cardDetails = result.cardDetails;
        validFields = result.validFields;
    }

    return {
        cardDetails,
        validFields
    };
}

module.exports = {
    buildTerceraDirectiva,
    getProfession,
    getActivity,
    getTrabajasPor,
    validateSelfEmployed,
    validateEmployed,
    validateRentier,
    validatePensioner,
    validateWithoutActivity,
    validateFields
}
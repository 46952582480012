module.exports = {
    title: 'contractsView-contractsView_checkingAccountCustomizeCardTitle',
    order: 3,
    footer_text: '',
    position: 'middle',
    override_validation_step: 5,
    components: [
        {
            id_component: 'card-image-selector',
            id: 'card-image-selector',
            cardImageIds: {
                DE: [
                    'B.506.013.001',
                    'B.506.013.002',
                    'B.506.013.003',
                ],
                NL: [
                    'B.506.019.001',
                    'B.506.019.002',
                    'B.506.019.003',
                ],
                PT: [
                    'B.506.015.001',
                    'B.506.015.002',
                    'B.506.015.003',
                ],
            },
            cardImageIdsFF: {
                DE: [
                    'B.506.013.011',
                    'B.506.013.014',
                    'B.506.013.015',
                    'B.506.013.018',
                    'B.506.013.013',
                    'B.506.013.012',
                    
                ],
                NL: [
                    'B.506.014.011',
                    'B.506.014.017',
                    'B.506.014.015',
                    'B.506.014.018',
                    'B.506.014.013',
                    'B.506.014.012',
                ],
                PT: [
                    'B.506.015.011',
                    'B.506.015.014',
                    'B.506.015.015',
                    'B.506.015.018',
                    'B.506.015.013',
                    'B.506.015.012',
                ],
                ES: [
                    "B.506.002.020", 
                ],
            },
            onChange: 'checkingPayrollAccount_handleCardImageChange',
        },
        {
            id_component: 'card-pin-change',
            id: 'card-pin-change',
            onBlur: 'checkingPayrollAccount_handlePinBlur',
        },
    ]
};
const Immutable = require('immutable');
const actionTypes = require('constants/actionTypes');

const initialState = Immutable.fromJS({
    valid: false,
});
function validateStep(step) {
    return step.merge({
        valid: true
    });
}

function cardModifyLimitStep1(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CARD_MODIFY_NEW_LIMIT_VALIDATE_STEP:
            return validateStep(state);
        default:
            return state;
    }
}

module.exports = cardModifyLimitStep1;

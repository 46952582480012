const {
    DEPOSIT_DATA_IMPOSITION_FAILURE,
    DEPOSIT_DATA_IMPOSITION_SUCCESS,
    FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_SUCCESS,
    FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_FAILURE,
    FETCH_DEPOSIT_ASOCIATED_ACCOUNT_FAILURE,
    FETCH_DEPOSIT_ASOCIATED_ACCOUNT_SUCCESS,
    DEPOSIT_RENOVATION_TO_CANCEL_SUCCESS,
    DEPOSIT_RENOVATION_TO_CANCEL_FAILURE
} = require('constants/contactCenter/actionTypes')

const {
    CONTRACT_METHOD_GET_CONTRACT_INFORMATION,
    CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE,
    CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS,
    CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE,
    FETCH_DEPOSIT_INTERVENERS_FAILURE,
    FETCH_DEPOSIT_INTERVENERS_SUCCESS,
    HIRING_PRODUCT_INFORMATION_FAILURE,
    HIRING_PRODUCT_INFORMATION_SUCCESS,
    HIRE_CONTRACT_FAILURE,
    HIRE_CONTRACT_SUCCESS,
} = require('constants/actionTypes')

const {
    FLOW_TYPE_ONLY_ACCESS,
    OPERATION_LOG_DONE,
    OPERATION_LOG_FAIL,
} = require('utilities/contactCenter/wrapUp/constants')

const ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS = 5056
const ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS_LEAK_RETENTION = 5177
const ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS_DUE_DATES = 5178
const ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS_EXCEPTIONS = 5275;
const ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS_FIXED_MATURITIES = 5176
const ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS_RENEWAL_EXCEPTIONS = 5276;
const ACTION_CODE_HIRE_DEPOSIT_TWELVE_MONTH = 201611128;
const ACTION_CODE_ADD_IMPOSITION = 5060
const ACTION_CODE_EARLY_CANCEL_IMPOSITION = 5062
const ACTION_CODE_DEPOSITS = 5057
const ACTION_CODE_DEPOSITS_CLOSED = 5058
const ACTION_CODE_DEPOSITS_DETAILS = 5059
const ACTION_CODE_DEPOSITS_CHANGE_ACCOUNT = 5061
const ACTION_CODE_DEPOSITS_SIMULATE_CANCELLATION = 5063
const ACTION_CODE_DEPOSITS_IMPOSITION = 5064
const ACTION_CODE_DEPOSIT_CANCEL_RENEWAL = 5065
const ACTION_CODE_DEPOSIT_RENEWAL = 5181
const ACTION_CODE_TAX_AND_RENTS = 5164
const ACTION_CODE_MAIN_DEPOSIT_NEW_MONEY = 201613130
const ACTION_CODE_MAIN_DEPOSIT_WITHOUT_NEW_MONEY = 201613136
const ACTION_CODE_MAIN_DEPOSIT_TRANSFER = 201611139
const ACTION_CODE_MAIN_DEPOSIT_RENEWAL = 201611188
const ACTION_CODE_DEPOSIT_RENOVATION_CANCELLATION = 202611189
const ACTION_CODE_DEPOSIT_PAYROLL = 201611464

module.exports = {
    [ACTION_CODE_DEPOSITS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/deposits'
        }
    },
    [ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS]: {
        entry: {
            path: '/myprofile/purchase/deposit-thirteen-months',
            failure: [
                { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE }
            ],
            success: [
                { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS }
            ]
        },
    },
    [ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS_LEAK_RETENTION]: {
        entry: {
            path: '/myprofile/purchase/deposit-thirteen-months-leak-retention',
            failure: [
                { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE }
            ],
            success: [
                { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS }
            ]
        },
    },
    [ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS_DUE_DATES]: {
        entry: {
            path: '/myprofile/purchase/deposit-thirteen-months-due-dates',
            failure: [
                { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE }
            ],
            success: [
                { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS }
            ]
        },
    },
    [ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS_FIXED_MATURITIES]: {
        entry: {
            path: '/myprofile/purchase/deposit-thirteen-months-linked-maturities',
            failure: [
                { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE }
            ],
            success: [
                { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS }
            ]
        },
    },
    [ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS_EXCEPTIONS]: {
        entry: {
            path: '/myprofile/purchase/deposit-thirteen-months-excepciones',
            failure: [
                { action: HIRING_PRODUCT_INFORMATION_FAILURE },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE }
            ],
            success: [
                { action: HIRING_PRODUCT_INFORMATION_SUCCESS },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS }
            ]
        },
    },
    [ACTION_CODE_HIRE_DEPOSIT_THIRTEEN_MONTHS_RENEWAL_EXCEPTIONS]: {
        entry: {
            path: '/myprofile/purchase/deposit-thirteen-months-renovacion-excepciones',
            failure: [
                { action: HIRING_PRODUCT_INFORMATION_FAILURE },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE }
            ],
            success: [
                { action: HIRING_PRODUCT_INFORMATION_SUCCESS },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS }
            ]
        },
    },
    [ACTION_CODE_ADD_IMPOSITION]: {
        entry: {
            path: '/myprofile/deposits/[0-9]+/add-imposition',
            failure: [
                { action: DEPOSIT_DATA_IMPOSITION_FAILURE }
            ],
            success: [
                { action: DEPOSIT_DATA_IMPOSITION_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_DEPOSITS_CLOSED]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/deposits/closed',
        }
    },
    [ACTION_CODE_DEPOSITS_DETAILS]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/deposits/[0-9]+/details',
            failure: [
                { action: FETCH_DEPOSIT_INTERVENERS_FAILURE },
                { action: FETCH_DEPOSIT_ASOCIATED_ACCOUNT_FAILURE }
            ],
            success: [
                { action: FETCH_DEPOSIT_INTERVENERS_SUCCESS },
                { action: FETCH_DEPOSIT_ASOCIATED_ACCOUNT_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_DEPOSITS_CHANGE_ACCOUNT]: {
        entry: {
            path: '/myprofile/deposits/[0-9]+/change-account',
            failure: [
                { action: FETCH_DEPOSIT_ASOCIATED_ACCOUNT_FAILURE }
            ],
            success: [
                { action: FETCH_DEPOSIT_ASOCIATED_ACCOUNT_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_DEPOSITS_SIMULATE_CANCELLATION]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/deposits/[0-9]+/imposition/[0-9]+/simulate-early-cancellation',
            failure: [
                { action: FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_FAILURE }
            ],
            success: [
                { action: FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_DEPOSITS_IMPOSITION]: {
        type: FLOW_TYPE_ONLY_ACCESS,
        entry: {
            path: '/myprofile/deposits/[0-9]+/imposition/[0-9]+',
        }
    },
    [ACTION_CODE_EARLY_CANCEL_IMPOSITION]: {
        entry: {
            path: '/myprofile/deposits/[0-9]+/imposition/[0-9]+/early-cancel',
            failure: [
                { action: FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_FAILURE }
            ],
            success: [
                { action: FETCH_IMPOSITION_EARLY_CANCELLATION_DATA_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_DEPOSIT_CANCEL_RENEWAL]: {
        entry: {
            path: '/myprofile/deposits/[0-9]+/imposition/[0-9]+/interest-renewal/N'
        }
    },
    [ACTION_CODE_DEPOSIT_RENEWAL]: {
        entry: {
            path: '/myprofile/deposits/[0-9]+/imposition/[0-9]+/interest-renewal/S'
        }
    },
    [ACTION_CODE_TAX_AND_RENTS]: {
        entry: {
            path: '/myprofile/taxAndRents/payment-with-draft'
        }
    },
    [ACTION_CODE_HIRE_DEPOSIT_TWELVE_MONTH]: {
        entry: {
            path: '/myprofile/purchase/deposit-eighteen-months',
            failure: [
                { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION_FAILURE },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_FAILURE }
            ],
            success: [
                { action: CONTRACT_METHOD_GET_CONTRACT_INFORMATION },
                { action: CONTRACT_METHOD_FETCH_REFERENCE_STANDARD_SUCCESS }
            ]
        }
    },
    [ACTION_CODE_MAIN_DEPOSIT_NEW_MONEY]: {
        entry: {
            path: '/myprofile/purchase/depositnm'
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS,
            ],
            check: (action, state) => {
                const deposit_product = state.contracts.get('deposit_product');
                let wrapUpCode = null

                if (deposit_product) {
                    wrapUpCode = deposit_product.get('wrapup');
                }

                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: wrapUpCode,
                };
            }
        }
    },
    [ACTION_CODE_MAIN_DEPOSIT_WITHOUT_NEW_MONEY]: {
        entry: {
            path: '/myprofile/purchase/deposit',
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS,
            ],
            check: (action, state) => {
                const deposit_product = state.contracts.get('deposit_product');
                let wrapUpCode = null

                if (deposit_product) {
                    wrapUpCode = deposit_product.get('wrapup');
                }
                
                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: wrapUpCode,
                };
            }
        }
    },
    [ACTION_CODE_MAIN_DEPOSIT_TRANSFER]: {
        entry: {
            path: '/myprofile/purchase/deposittransfer',
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS,
            ],
            check: (action, state) => {
                const deposit_product = state.contracts.get('deposit_product');
                let wrapUpCode = null

                if (deposit_product) {
                    wrapUpCode = deposit_product.get('wrapup');
                }
                
                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: wrapUpCode,
                };
            }
        }
    },
    [ACTION_CODE_MAIN_DEPOSIT_RENEWAL]: {
        entry: {
            path: '/myprofile/purchase/depositrenewal',
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS,
            ],
            check: (action, state) => {
                const deposit_product = state.contracts.get('deposit_product');
                let wrapUpCode = null

                if (deposit_product) {
                    wrapUpCode = deposit_product.get('wrapup');
                }
                
                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: wrapUpCode,
                };
            }
        }
    },
    [ACTION_CODE_DEPOSIT_RENOVATION_CANCELLATION]: {
        entry: {
            path: '/myprofile/deposits/renovation/[0-9]+/[0-9]+/[0-9]+/cancel',
        },
        failure: [
            { action: DEPOSIT_RENOVATION_TO_CANCEL_FAILURE }
        ],
        success: [
            { action: DEPOSIT_RENOVATION_TO_CANCEL_SUCCESS }
        ]
    },
    [ACTION_CODE_DEPOSIT_PAYROLL]: {
        entry: {
            path: '/myprofile/purchase/depositpayroll',
        },
        finish: {
            action: [
                HIRE_CONTRACT_FAILURE,
                HIRE_CONTRACT_SUCCESS,
            ],
            check: (action, state) => {
                const deposit_product = state.contracts.get('deposit_product');
                let wrapUpCode = null

                if (deposit_product) {
                    wrapUpCode = deposit_product.get('wrapup');
                }
                
                return {
                    operationState: action.type === HIRE_CONTRACT_FAILURE ? OPERATION_LOG_FAIL : OPERATION_LOG_DONE,
                    actionCode: wrapUpCode,
                };
            }
        }
    },
}

module.exports = {
    timeoutError: {
        errorTitle: 'contractsView-checkingAccountHireTimeoutErrorTitle',
        errorBody: 'contractsView-checkingAccountHireTimeoutErrorBody',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    },
    serverErrorPrecontractual: {
        errorTitle: 'contractsView-errorTitle',
        errorBody: __CONTACT_CENTER__ ? 'contractsView-errorMessage' : 'contractsView-checkingAccountHire_precontractualErrorBody',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    },
    serverErrorContractual: {
        errorTitle: 'contractsView-checkingAccountHire_contractualErrorTitle',
        errorBody: 'contractsView-checkingAccountHire_contractualErrorBody',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    },
    createError: {
        errorTitle: 'contractsView-checkingAccountHire_contractualErrorTitle',
        errorBody: 'contractsView-checkingAccountHire_contractualErrorBody',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    },
    fiocError: {
        errorTitle: 'contractsView-checkingAccountHire_contractualErrorTitle',
        errorBody: 'contractsView-accountContract_fiocErrorDescription',
        backButtonLabel: 'contractsView-contractsView_legalWindowErrorBackButtonLabel',
        backButtonLink: '/myprofile/accounts'
    }
}

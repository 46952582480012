// @vendors
import Immutable from 'immutable'
import actionTypes from 'constants/actionTypes';
const { formatText } = require('core/i18n').i18n;

const INITIAL_STATE = Immutable.fromJS({
    fetching: false,
    fetched: false,
    error: undefined,
    periodicalFundsContributions: [],
    expandRowId: undefined,
    actualPage: 1,
    token: '',
    cancelPeriodicalContributionFetching: false,
    cancelPeriodicalContributionError: false,
    cancelPeriodicalContributionSuccess: false,
    periodicalContributionSelected: undefined,
});

const findPeriodicityLabel = (periodicity) => {
    switch (periodicity.toLowerCase()) {
        case 'weekly':
            return formatText('brokerContributionFundStep1-weeklyPeriodicity');
        case 'monthly':
            return formatText('brokerContributionFundStep1-monthlyPeriodicity');
        case 'quarterly':
            return formatText('brokerContributionFundStep1-quarterlyPeriodicity');
        case 'semiannual':
            return formatText('brokerContributionFundStep1-semestralPeriodicity');
        case 'annual':
            return formatText('brokerContributionFundStep1-annualPeriodicity');
        default:
            return '';
    }
};

const brokerPeriodicalFundsContributionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_REQUEST:
            return state.mergeDeep({
                fetching: true,
                fetched: false
            });
        case actionTypes.BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_SUCCESS:
            const contibutions = action.payload.recurringTransfers;
            return state.mergeDeep({
                fetching: false,
                fetched: true,
                totalPages: action.payload.totalPages,
                periodicalFundsContributions: !!contibutions ? contibutions : null,
            });
        case actionTypes.BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_ERROR:
            return state.mergeDeep({
                fetching: false,
                fetched: false,
                error: action.payload
            });
        case actionTypes.HANDLE_ROW_CLICK_PERIODICAL_CONTRIBUTION_TABLE:
            return state.mergeDeep({
                expandRowId: action.payload,
            });
        case actionTypes.BROKER_PERIODICAL_CONTRIBUTION_SELECTED:
            const periodicityLabel = findPeriodicityLabel(action.payload.periodicity);
            return state.mergeDeep({
                periodicalContributionSelected: {...action.payload, periodicityLabel},
            });
        case actionTypes.BROKER_PERIODICAL_CONTRIBUTION_CHANGE_PAGE:
            return state.mergeDeep({
                actualPage: action.payload,
            });
        case actionTypes.BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_FETCHING:
            return state.mergeDeep({
                cancelPeriodicalContributionFetching: true,
            });
        case actionTypes.BROKER_PERIODICAL_CONTRIBUTION_SIGNATURE_SUCCESS:
            return state.mergeDeep({
                token: action.payload,
            });
        case actionTypes.BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_ERROR:
            return state.mergeDeep({
                cancelPeriodicalContributionFetching: false,
                cancelPeriodicalContributionError: true,
                cancelPeriodicalContributionSuccess: false,
            });
        case actionTypes.BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_SUCCESS:
            return state.mergeDeep({
                cancelPeriodicalContributionFetching: false,
                cancelPeriodicalContributionError: false,
                cancelPeriodicalContributionSuccess: true,
            });
        case actionTypes.BROKER_FETCH_PERIODICAL_CONTRIBUTIONS_CANCEL_RESET:
            return state.mergeDeep({
                cancelPeriodicalContributionFetching: false,
                cancelPeriodicalContributionError: false,
                cancelPeriodicalContributionSuccess: false,
            });
        default:
            return state;
    }
}

module.exports = brokerPeriodicalFundsContributionReducer;

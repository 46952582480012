//TODO big refactor in this mess

const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const moment = require('moment');
const get = require('lodash/object/get');
const { formatAmount } = require('utilities/currencyHelper');
const sanitizeHelper = require('utilities/sanitizeHelper');
const {
    BROKER_ETFS_GS_BREAKDOWNS_BASIC_MATERIALS_TYPE,
    BROKER_ETFS_GS_BREAKDOWNS_COMMUNCATION_SERVICES_TYPE,
    BROKER_ETFS_GS_BREAKDOWNS_CONSUMER_CYCLICAL_TYPE,
    BROKER_ETFS_GS_BREAKDOWNS_CONSUMER_DEFENSIVE_TYPE,
    BROKER_ETFS_GS_BREAKDOWNS_HEALTHCARE_TYPE,
    BROKER_ETFS_GS_BREAKDOWNS_INDUSTRIALS_TYPE,
    BROKER_ETFS_GS_BREAKDOWNS_REAL_STATE_TYPE,
    BROKER_ETFS_GS_BREAKDOWNS_TECHNOLOGY_TYPE,
    BROKER_ETFS_GS_BREAKDOWNS_ENERGY_TYPE,
    BROKER_ETFS_GS_BREAKDOWNS_FINANCIAL_SERVICES_TYPE,
    BROKER_ETFS_GS_BREAKDOWNS_UTILITIES_TYPE,
    BROKER_ETFS_RESUME_DOCS_DFI_TYPE,
    BROKER_ETFS_RESUME_DOCS_BIANNUAL_TYPE,
    BROKER_ETFS_RESUME_DOCS_COMMERCIALIZATION_TYPE,
    BROKER_ETFS_WALLET_ACTIVES_TYPE_LONG,
    BROKER_ETFS_WALLET_ACTIVES_TYPE_SHORT,
    BROKER_ETFS_WALLET_ACTIVES_TYPE_NET,
    BROKER_ETFS_WALLET_ACTIVES_TYPE_STOCKS,
    BROKER_ETFS_WALLET_ACTIVES_TYPE_OBLIGATIONS,
    BROKER_ETFS_WALLET_ACTIVES_TYPE_CASH,
    BROKER_ETFS_WALLET_ACTIVES_TYPE_OTHERS,
} = require('constants/index');

const initialState = Immutable.fromJS(
    {
        isFetchingETFByIsin: false,
        fetchingETFByIsinFailed: false,
        isFetchingETFDocuments: false,
        isFetchingETFCatalogue: false,
        fetchedCatalogue: false,
        isCryptoETF: false,
        fetchingETFDocumentsFailed: false,
        inceptionDate: '',
        isin: '',
        riskAndRating: [],
        benchmark: {
            name: '',
            category: [],
            categoryPrimaryIndex: []
        },
        marketCapitalBreakDown: [],
        riskStatistics: {
            date: '',
            rSquareds: [],
            betas: [],
            alphas: [],
            bestFits: [],
            standardDeviations: {
                m12: '',
                m36: '',
                m60: ''
            },
            averageProfitability: {
                m12: '',
                m36: '',
                m60: ''
            },
            sharpeRatios: {
                m12: '',
                m36: '',
                m60: ''
            },
            sortinoRatios: {
                m12: '',
                m36: '',
                m60: ''
            },
            numberOfPositives: {
                m12: '',
                m36: '',
                m60: ''
            },
            numberOfNegatives: {
                m12: '',
                m36: '',
                m60: ''
            },
            minimumReturns: {
                m12: '',
                m36: '',
                m60: ''
            }
        },
        trailingPerformance: {
            oneDay: '',
            oneWeek: '',
            oneMonth: '',
            threeMonths: '',
            sixMonths: '',
            oneYear: '',
            theeAnualYears: '',
            fiveAnualYears: '',
            tenAnualYears: ''
        },
        priceHistory: {
            lastDate: '',
            priceDetails: []
        },
        annualProfitability: {
            yearsPeriod: [],
            historicalPerformanceSeries: [],
            benchmark: [],
            historicalPeerGroupRanks: []
        },
        commissions: {
            customBuyFee: '',
            customSellFee: '',
            managementFee: '',
            perfomanceFee: '', // NOT FOUND
            distributionFee: '',
            custodianFee: '',
            customMinimumPurchaseAmount: '',
            customMinimumPurchaseAmountUnit: '',
            customMinimumAdditionalPurchaseAmount: '',
            customMinimumAdditionalPurchaseAmountUnit: '',
            totalExpenseRatio: '',
            onGoingCharge: ''
        },
        providerCompany: {
            name: '',
            phone: '',
            homepage: '',
            address: [],
            postalCode: '',
            city: '',
            country: '',
            legalStructure: '',
            ucits: ''
        },
        managerList: [],
        growthOf10K: {},
        riskScale: 0,
        morningstarRating: 0,
        investmentStrategy: '',
        administratorCompany: {
            name: ''
        },
        netAssetValues: {
            currencyId: '',
            dayEndValue: '',
            dayEndDate: ''
        },
        assetAllocations: [],
        fundNetAssetValues: {
            currencyId: '',
            dayEndValue: '',
            dayEndDate: ''
        },
        custom: {
            customCategoryId: ''
        },
        globalStockSectorBreakdown: {
            date: '',
            basicMaterials: 0,
            communicationServices: 0,
            consumerCyclical: 0,
            consumerDefensive: 0,
            healthCare: 0,
            industrials: 0,
            realState: 0,
            technology: 0,
            energy: 0,
            financialServices: 0,
            utilities: 0
        },
        equityStatistics: {},
        documents: {
            dfiDocId: '',
            biAnnnualReportDoctId: '',
            commercializationReportDocId: ''
        },
        documentsURL: {},
        regionalExposure: [{BreakdownValues: []}],

        assetAllocationsForTable: {
            stocks: {
                typeL: 0,
                typeS: 0,
                typeN: 0
            },
            obligations: {
                typeL: 0,
                typeS: 0,
                typeN: 0
            },
            cash: {
                typeL: 0,
                typeS: 0,
                typeN: 0
            },
            others: {
                typeL: 0,
                typeS: 0,
                typeN: 0
            }
        },
        assetAllocationsChartData: [],
        complexity: false,
    }
);

function getCustomCategoryId(productData) {
    return productData.Custom != undefined && productData.Custom.CustomCategoryId != undefined
        ? productData.Custom.CustomCategoryId
        : '-';
}

function getMorningStarRating(riskAndRating) {
    // @hardcoded: Morningstar Rating uses M255 as default
    const riskAndRatingTimePeriod = 'M255';

    const indexOfRiskAndRating = riskAndRating.findIndex(item => {
      return item.TimePeriod === riskAndRatingTimePeriod;
    });

    return riskAndRating[indexOfRiskAndRating].RatingValue || 0;
}

function getMonthPeriodValue(productStatistic, monthPeriod) {
    if(!productStatistic) return false
    if (productStatistic.some(month => month.TimePeriod === `M${monthPeriod}`)) {
        const periodValue = productStatistic.find(month => month.TimePeriod === `M${monthPeriod}`);
        return periodValue.Value || periodValue.Count;
    }
}

function getTrailingPeriodValue(trailingsPerformance, timePeriod) {
    if (trailingsPerformance.some(month => month.TimePeriod === timePeriod)) {
        const periodValue = trailingsPerformance.find(month => month.TimePeriod === timePeriod);
        return periodValue.Value;
    }
}

function getYearsPeriod(priceDetails) {
    const priceDetailsByDate = priceDetails.groupBy(priceDetail => {
        return moment(priceDetail.get('Date')).year();
    });

    return priceDetailsByDate.keySeq();
}

function getGlobalStockSectorByType(globalStocks,type) {
    let value = globalStocks.find(gStock => gStock.get('Type') === type);

    return value ? value.get('Value') : '';
}

function getDocumentByType(documents,type) {
    let document = documents.filter(doc => doc.getIn(['DocumentTypes', 0]) === type);

    let doc = (document ? document.sort(function(a,b) {
                return new Date(a.EffectiveDate) - new Date(b.EffectiveDate);
             }).first() : undefined);

    return doc ? doc.get('EncodedDocumentId') : '-';
}

function getActivesValues(assetAllocations,activeType,type) {
    let assetByType = assetAllocations.find(asset => asset.get('SalePosition') === type);

    let activeByType = assetByType ? assetByType.get('BreakdownValues').find(breakdown => breakdown.get('Type') === activeType) : null;

    return activeByType ? activeByType.get('Value') : '';
}

function requestETFsProductAdapter(productData) {
    let benchmark,benchmarkCategory,benchmarkCategoryPrimaryIndex,globalStockSectors,globalStockSector,globalStockBreakdowns,documents,regionalExposure,assetAllocations,assetAllocationsChartData;
    const riskStatistics = productData && productData.RiskStatistics && productData.RiskStatistics.length ? productData.RiskStatistics[0] : [];

    const trailingPerformancePeriods = productData.TrailingPerformance ? productData.TrailingPerformance[0].Return : [];
    const priceDetails = productData.PriceHistory ? productData.PriceHistory[0].PriceDetails : [];
    const lastDatePriceHistory = priceDetails.length>0 ? priceDetails[priceDetails.length - 1].Date : null;

    const digestedGrowth = productData.GrowthOf10K ? productData.GrowthOf10K[0].HistoryDetails.map(item => {
        return {date: item.EndDate, value: item.Value}
    }) : [];

    const benchMarkName = productData.Benchmark && productData.Benchmark[0].Name ? productData.Benchmark[0].Name : '';

    const digestedDecemberGrowth = digestedGrowth.length>0 ? digestedGrowth.filter(item => moment(item.date).month() === 11) : [];
    if (productData.Benchmark) {
        benchmark = Immutable.fromJS(productData.Benchmark);
        benchmarkCategory = benchmark.find(benchType => benchType.get('Type') === 'Category');
        benchmarkCategoryPrimaryIndex = benchmark.find(benchType => benchType.get('Type') === 'CategoryPrimaryIndex');
        globalStockSectors = benchmarkCategoryPrimaryIndex ? benchmarkCategoryPrimaryIndex.getIn(['Portfolios', 0, 'GlobalStockSectorBreakdown']) : null;
        globalStockSector = globalStockSectors ? globalStockSectors.find(gSector => gSector.get('SalePosition') === 'N') : null;
        globalStockBreakdowns = globalStockSector ? globalStockSector.get('BreakdownValues') : [];
        documents = Immutable.fromJS(productData.Documents);
        regionalExposure = benchmarkCategoryPrimaryIndex ? benchmarkCategoryPrimaryIndex.getIn(['Portfolios', 0, 'RegionalExposure']) : [];
        assetAllocations = benchmarkCategoryPrimaryIndex ? benchmarkCategoryPrimaryIndex.getIn(['Portfolios', 0, 'AssetAllocations'], []) : [];

        assetAllocationsChartData = assetAllocations.length > 0 ? assetAllocations
                                            .filter(item => item.get('SalePosition') === 'N')
                                            .getIn([0, 'BreakdownValues']) : [];
    }
    const hasFrequencyY =
        !!productData.HistoricalPerformanceSeries &&
        typeof productData.HistoricalPerformanceSeries.find(
            (historicalPerformance) => historicalPerformance.Frequency === "Y"
        ) !== "undefined";
    const hasHistoricalPeerGroupRanks =
        !!productData.HistoricalPeerGroupRanks &&
        typeof productData.HistoricalPeerGroupRanks !== "undefined";

    return Immutable.fromJS({
        isFetchingETFByIsin: false,
        fetchingETFByIsinFailed: false,
        inceptionDate: productData.InceptionDate,
        isin: productData.Isin ? productData.Isin.trim() : '',
        riskAndRating: productData.RiskAndRating ? productData.RiskAndRating : [],
        marketCapitalBreakDown: productData.Portfolios && productData.Portfolios[0].MarketCapitalBreakdown || [],
        benchmark: {
            name: sanitizeHelper.sanitizeData(benchMarkName),
            category: benchmarkCategory ? benchmarkCategory.get('HistoricalPerformanceSeries', []) : [],
            categoryPrimaryIndex: benchmarkCategoryPrimaryIndex ? benchmarkCategoryPrimaryIndex.get('HistoricalPerformanceSeries', []) : []
        },
        riskStatistics: {
            date: riskStatistics.Date,
            rSquareds: riskStatistics.RSquareds,
            betas: riskStatistics.Betas,
            alphas: riskStatistics.Alphas,
            bestFits: get(productData,'RiskStatistics[1].BestFits', []),
            standardDeviations: {
                m12: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.StandardDeviations, 12) : '',
                m36: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.StandardDeviations, 36) : '',
                m60: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.StandardDeviations, 60) : ''
            },
            averageProfitability: {
                m12: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.ArithmeticMeans, 12) : '',
                m36: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.ArithmeticMeans, 36) : '',
                m60: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.ArithmeticMeans, 60) : ''
            },
            sharpeRatios: {
                m12: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.SharpeRatios, 12) : '',
                m36: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.SharpeRatios, 36) : '',
                m60: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.SharpeRatios, 60) : ''
            },
            sortinoRatios: {
                m12: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.SortinoRatios, 12) : '',
                m36: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.SortinoRatios, 36) : '',
                m60: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.SortinoRatios, 60) : ''
            },
            numberOfPositives: {
                m12: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.NumberOfPositives, 12) : '',
                m36: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.NumberOfPositives, 36) : '',
                m60: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.NumberOfPositives, 60) : ''
            },
            numberOfNegatives: {
                m12: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.NumberOfNegatives, 12) : '',
                m36: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.NumberOfNegatives, 36) : '',
                m60: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.NumberOfNegatives, 60) : ''
            },
            minimumReturns: {
                m12: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.MinimumReturns, 12) : '',
                m36: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.MinimumReturns, 36) : '',
                m60: productData.RiskStatistics ? getMonthPeriodValue(riskStatistics.MinimumReturns, 60) : ''
            },
        },
        trailingPerformance: {
            ytd: getTrailingPeriodValue(trailingPerformancePeriods, 'M0'),
            oneDay: getTrailingPeriodValue(trailingPerformancePeriods, 'D1'),
            oneWeek: getTrailingPeriodValue(trailingPerformancePeriods, 'W1'),
            oneMonth: getTrailingPeriodValue(trailingPerformancePeriods, 'M1'),
            threeMonths: getTrailingPeriodValue(trailingPerformancePeriods, 'M3'),
            sixMonths: getTrailingPeriodValue(trailingPerformancePeriods, 'M6'),
            oneYear: getTrailingPeriodValue(trailingPerformancePeriods, 'M12'),
            threeAnualYears: getTrailingPeriodValue(trailingPerformancePeriods, 'M36'),
            fiveAnualYears: getTrailingPeriodValue(trailingPerformancePeriods, 'M60'),
            tenAnualYears: getTrailingPeriodValue(trailingPerformancePeriods, 'M255')
        },
        priceHistory: {
            lastDate: lastDatePriceHistory,
            priceDetails
        },
        annualProfitability: {
            yearsPeriod: getYearsPeriod(Immutable.fromJS(priceDetails)),
            historicalPerformanceSeries: hasFrequencyY ? productData.HistoricalPerformanceSeries : [],
            historicalPeerGroupRanks: hasHistoricalPeerGroupRanks ? productData.HistoricalPeerGroupRanks : []
        },
        commissions: {
            managementFee: productData.ManagementFee || '-',
            totalExpenseRatio: productData.TotalExpenseRatio || '-',
            onGoingCharge: productData.OngoingCharge || '-',
            perfomanceFee: productData.PerformanceFee || '-' // NOT FOUND
        },
        providerCompany: {
            name: productData.ProviderCompany && productData.ProviderCompany.Name ? productData.ProviderCompany.Name.trim() : '-',
            phone: productData.ProviderCompany && productData.ProviderCompany.Phone ? productData.ProviderCompany.Phone.trim() : '-',
            homepage: productData.ProviderCompany && productData.ProviderCompany.Homepage ? productData.ProviderCompany.Homepage.trim() : '-',
            address: [
                productData.ProviderCompany && productData.ProviderCompany.AddressLine1 ? productData.ProviderCompany.AddressLine1.trim() : '-',
                productData.ProviderCompany && productData.ProviderCompany.AddressLine2 ? productData.ProviderCompany.AddressLine2.trim() : '-',
                productData.ProviderCompany && productData.ProviderCompany.AddressLine3 ? productData.ProviderCompany.AddressLine3.trim() : '-'
            ],
            postalCode: productData.ProviderCompany && productData.ProviderCompany.PostalCode ? productData.ProviderCompany.PostalCode.trim() : '-',
            city: productData.ProviderCompany && productData.ProviderCompany.City ? productData.ProviderCompany.City.trim() : '-',
            country: productData.ProviderCompany && productData.ProviderCompany.Country ? productData.ProviderCompany.Country.trim() : '-',
            legalStructure: productData.LegalStructure || '-',
            ucits: productData.FundAttributes && productData.FundAttributes.UCITS ? productData.FundAttributes.UCITS : '-'
        },
        managerList: productData.ManagerList,
        growthOf10K: {
            currencyId: productData.GrowthOf10K && productData.GrowthOf10K[0].CurrencyId ? productData.GrowthOf10K[0].CurrencyId : '-',
            startValue: productData.GrowthOf10K && productData.GrowthOf10K[0].StartValue ? productData.GrowthOf10K[0].StartValue : '-',
            decemberPoints: digestedDecemberGrowth ? digestedGrowth : '-',
            allData: digestedGrowth
        },
        riskScale: productData.KID && productData.KID.SRI
            ? productData.KID.SRI
            : productData.CollectedSRRI && productData.CollectedSRRI.Rank
                ? productData.CollectedSRRI.Rank
                : initialState.get('riskScale'),
        morningstarRating: productData.RiskAndRating ? getMorningStarRating(productData.RiskAndRating) : '-',
        investmentStrategy: productData.InvestmentStrategy ? productData.InvestmentStrategy : '-',
        administratorCompany: {
            name: productData.AdministratorCompany && productData.AdministratorCompany.Name ? productData.AdministratorCompany.Name : '-'
        },
        netAssetValues: {
            currencyId: productData.NetAssetValues && productData.NetAssetValues[0].CurrencyId ? productData.NetAssetValues[0].CurrencyId : '-',
            dayEndValue: productData.NetAssetValues && productData.NetAssetValues[0].DayEndValue ? formatAmount(productData.NetAssetValues[0].DayEndValue) : '-',
            dayEndDate: productData.NetAssetValues && productData.NetAssetValues[0].DayEndDate ? productData.NetAssetValues[0].DayEndDate : '-'
        },
        fundNetAssetValues: {
            currencyId: productData.FundNetAssetValues && productData.FundNetAssetValues[0].CurrencyId ? productData.FundNetAssetValues[0].CurrencyId : '-',
            dayEndValue: productData.FundNetAssetValues && productData.FundNetAssetValues[0].DayEndValue ? formatAmount(productData.FundNetAssetValues[0].DayEndValue) : '-',
            dayEndDate: productData.FundNetAssetValues && productData.FundNetAssetValues[0].DayEndDate ? productData.FundNetAssetValues[0].DayEndDate : '-'
        },
        assetAllocations: productData.Portfolios && productData.Portfolios[0].AssetAllocations || [],
        custom: {
            customCategoryId: getCustomCategoryId(productData)
        },
        globalStockSectorBreakdown: {
            date: benchmarkCategoryPrimaryIndex ? benchmarkCategoryPrimaryIndex.getIn(['Portfolios', 0, 'Date']) : '-',
            basicMaterials: globalStockBreakdowns ? getGlobalStockSectorByType(globalStockBreakdowns,BROKER_ETFS_GS_BREAKDOWNS_BASIC_MATERIALS_TYPE) : '-',
            communicationServices: globalStockBreakdowns ?  getGlobalStockSectorByType(globalStockBreakdowns,BROKER_ETFS_GS_BREAKDOWNS_COMMUNCATION_SERVICES_TYPE) : '-',
            consumerCyclical: globalStockBreakdowns ?  getGlobalStockSectorByType(globalStockBreakdowns,BROKER_ETFS_GS_BREAKDOWNS_CONSUMER_CYCLICAL_TYPE) : '-',
            consumerDefensive: globalStockBreakdowns ?  getGlobalStockSectorByType(globalStockBreakdowns,BROKER_ETFS_GS_BREAKDOWNS_CONSUMER_DEFENSIVE_TYPE) : '-',
            healthCare: globalStockBreakdowns ?  getGlobalStockSectorByType(globalStockBreakdowns,BROKER_ETFS_GS_BREAKDOWNS_HEALTHCARE_TYPE) : '-',
            industrials: globalStockBreakdowns ?  getGlobalStockSectorByType(globalStockBreakdowns,BROKER_ETFS_GS_BREAKDOWNS_INDUSTRIALS_TYPE) : '-',
            realState: globalStockBreakdowns ?  getGlobalStockSectorByType(globalStockBreakdowns,BROKER_ETFS_GS_BREAKDOWNS_REAL_STATE_TYPE) : '-',
            technology: globalStockBreakdowns ?  getGlobalStockSectorByType(globalStockBreakdowns,BROKER_ETFS_GS_BREAKDOWNS_TECHNOLOGY_TYPE) : '-',
            energy: globalStockBreakdowns ?  getGlobalStockSectorByType(globalStockBreakdowns,BROKER_ETFS_GS_BREAKDOWNS_ENERGY_TYPE) : '-',
            financialServices: globalStockBreakdowns ? getGlobalStockSectorByType(globalStockBreakdowns,BROKER_ETFS_GS_BREAKDOWNS_FINANCIAL_SERVICES_TYPE) : '-',
            utilities: globalStockBreakdowns ? getGlobalStockSectorByType(globalStockBreakdowns,BROKER_ETFS_GS_BREAKDOWNS_UTILITIES_TYPE) : '-'
        },
        equityStatistics: benchmarkCategoryPrimaryIndex ?  benchmarkCategoryPrimaryIndex.getIn(['Portfolios', 0, 'EquityStatistics']) : null,
        documents: {
            dfiDocId: documents ? getDocumentByType(documents,BROKER_ETFS_RESUME_DOCS_DFI_TYPE) : '-',
            biAnnnualReportDoctId: documents ? getDocumentByType(documents,BROKER_ETFS_RESUME_DOCS_BIANNUAL_TYPE) : '-',
            commercializationReportDocId: documents ?  getDocumentByType(documents,BROKER_ETFS_RESUME_DOCS_COMMERCIALIZATION_TYPE) : '-',
        },

        regionalExposure: regionalExposure,

        assetAllocationsForTable: {
            stocks: {
                typeL: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_STOCKS, BROKER_ETFS_WALLET_ACTIVES_TYPE_LONG) : '-',
                typeS: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_STOCKS, BROKER_ETFS_WALLET_ACTIVES_TYPE_SHORT) : '-',
                typeN: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_STOCKS, BROKER_ETFS_WALLET_ACTIVES_TYPE_NET) : '-'
            },
            obligations: {
                typeL: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_OBLIGATIONS, BROKER_ETFS_WALLET_ACTIVES_TYPE_LONG) : '-',
                typeS: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_OBLIGATIONS, BROKER_ETFS_WALLET_ACTIVES_TYPE_SHORT) : '-',
                typeN: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_OBLIGATIONS, BROKER_ETFS_WALLET_ACTIVES_TYPE_NET) : '-'
            },
            cash: {
                typeL: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_CASH, BROKER_ETFS_WALLET_ACTIVES_TYPE_LONG) : '-',
                typeS: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_CASH, BROKER_ETFS_WALLET_ACTIVES_TYPE_SHORT) : '-',
                typeN: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_CASH, BROKER_ETFS_WALLET_ACTIVES_TYPE_NET) : '-'
            },
            others: {
                typeL: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_OTHERS, BROKER_ETFS_WALLET_ACTIVES_TYPE_LONG) : '-',
                typeS: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_OTHERS, BROKER_ETFS_WALLET_ACTIVES_TYPE_SHORT) : '-',
                typeN: assetAllocations ? getActivesValues(assetAllocations, BROKER_ETFS_WALLET_ACTIVES_TYPE_OTHERS, BROKER_ETFS_WALLET_ACTIVES_TYPE_NET) : '-'
            }
        },
        assetAllocationsChartData
    });
}

function requestETFsProductAnotherAdapter(productData) {
    return Immutable.fromJS({
        commissions: {
            customBuyFee: productData.CustomBuyFee || '-', // NOT FOUND
            customSellFee: productData.CustomSellFee || '-', // NOT FOUND
            distributionFee: productData.DistributionFee || '-',
            custodianFee: productData.CustodianFee,
            customMinimumPurchaseAmount: productData.CustomMinimumPurchaseAmount,
            customMinimumPurchaseAmountUnit: productData.CustomMinimumPurchaseAmountUnit,
            customMinimumAdditionalPurchaseAmount: productData.CustomMinimumAdditionalPurchaseAmount,
            customMinimumAdditionalPurchaseAmountUnit: productData.CustomMinimumAdditionalPurchaseAmountUnit
        }
    });
}

function requestETFsDocuments(data) {
    const documents = data.documents;
    const dfiDocument = documents.find((doc) => doc.documentKey === "DFI");
    const iesDocument = documents.find((doc) => doc.documentKey === "IES");
    const mcDocument = documents.find((doc) => doc.documentKey === "MC");
    return Immutable.fromJS({
        fetchingETFDocumentsFailed: false,
        isFetchingETFDocuments: false,
        documentsURL: {
            dfi: dfiDocument ? dfiDocument.documentUrl : null,
            ies: iesDocument ? iesDocument.documentUrl : null,
            mc: mcDocument ? mcDocument.documentUrl : null,
        }
    });
}

function brokerETFsProductReducer(state = initialState, action) {
    switch (action.type) {

        case actionTypes.BROKER_ETF_PRODUCT_FETCHING:
            return state.set('isFetchingETFByIsin', true);

        case actionTypes.BROKER_ETF_PRODUCT_SUCCESS:
            return state.mergeDeep(requestETFsProductAdapter(action.payload.data[0]));

        case actionTypes.BROKER_ETF_PRODUCT_FAILURE:
            return state.set('isFetchingETFByIsin', false)
                        .set('fetchingETFByIsinFailed', true);

        case actionTypes.BROKER_ETF_PRODUCT_ANOTHER_SUCCESS:
            return state.mergeDeep(requestETFsProductAnotherAdapter(action.payload.data));

        case actionTypes.BROKER_ETF_PRODUCT_DOCUMENTATION_FETCHING:
            return state.set('isFetchingETFDocuments', true);

        case actionTypes.BROKER_ETF_PRODUCT_DOCUMENTATION_SUCCESS:
            return state.mergeDeep(requestETFsDocuments(action.payload.data));

        case actionTypes.BROKER_ETF_PRODUCT_DOCUMENTATION_FAILURE:
            return state.set('isFetchingETFDocuments', false)
                        .set('fetchingETFDocumentsFailed', true);

        case actionTypes.BROKER_ETF_CATALOGUE_FETCHING:
            return state.set('isFetchingETFCatalogue', true)
                .set('fetchedCatalogue', false);

        case actionTypes.BROKER_ETF_CATALOGUE_SUCCESS:
            let complexity = false;
            let isCrypto = false;
            if(action.payload.etfs.instrumentsList) {
                const rawComplexity = action.payload.etfs.instrumentsList[0].complexity;
                !!rawComplexity &&  rawComplexity !== 'NO' ?
                    complexity = true : complexity = false;
                !!rawComplexity &&  rawComplexity === 'EC-CRP' ?
                    isCrypto = true : isCrypto = false;
            }
            const riskRange = action.payload.etfs.instrumentsList[0].risk;
            const stockName = action.payload.etfs.instrumentsList[0].name;
            const provider = action.payload.etfs.instrumentsList[0].provider;
            return state.set('isFetchingETFCatalogue', false)
                        .set('complexity', complexity)
                        .set('isCryptoETF', isCrypto)
                        .set('riskRange', riskRange)
                        .set('fetchedCatalogue', true)
                        .set('stockName', stockName)
                        .set('provider', provider);

        case actionTypes.BROKER_ETF_CATALOGUE_FAILURE:
            return state.set('isFetchingETFCatalogue', false)
                        .set('complexity', false)
                        .set('fetchedCatalogue', false);

        default:
            return state;
    }
}

module.exports = brokerETFsProductReducer;

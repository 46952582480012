const { ACCOUNT_TYPES } = require('utilities/accountsSortingHelper');
import { ACCOUNTS_BY_COUNTRIES } from 'constants/accounts';

const areSameReferenceStandard = (account1, account2) => {
    const account1Standard = account1.standard;
    const account2Standard = account2.standard;
    
    const account1Type = account1.newProduct;
    const account2Type = account2.newProduct;
    
    const account1Subtype = account1.subtype;
    const account2Subtype = account2.subtype;

    return account1Standard === account2Standard
        && account1Subtype === account2Subtype
        && account1Type === account2Type;
}

const getCuentaCorrienteIndexes = (accounts, accountsMap) =>{
    return accounts.filter((accountId) => {
        const accountProduct = accountsMap.getIn([accountId, 'newProduct']);
        return accountProduct === '300' || accountProduct === '301'// CUENTA CORRIENTE
    });
}

const accountTypeIs = accountType => account => {
    const productCode = account.get('newProduct');
    const subTypeCode = account.get('accountType');
    const code = `${productCode}${subTypeCode}`;

    return code === accountType;
}

const isCuentaCorrienteIsNotDerivados = account => {
    const productCode = account.get('newProduct');
    const subTypeCode = account.get('accountType');
    const code = `${productCode}${subTypeCode}`;

    return productCode === ACCOUNT_TYPES.cuentaCorriente.otras
        && code !== ACCOUNT_TYPES.cuentaCorriente.cuentaDeDerivados;
}

const accountsToIds = (acc, account) => [...acc, account.get('accountId')];

const getAccountsByCountries = (originCountry, accountCode) =>{
    if (originCountry && ACCOUNTS_BY_COUNTRIES[originCountry]) {
        return ACCOUNTS_BY_COUNTRIES[originCountry][accountCode] ? ACCOUNTS_BY_COUNTRIES[originCountry][accountCode] : ACCOUNTS_BY_COUNTRIES[originCountry].DEFAULT;
    }
    return ACCOUNTS_BY_COUNTRIES.DEFAULT
}

module.exports = {
    areSameReferenceStandard,
    getCuentaCorrienteIndexes,
    accountTypeIs,
    accountsToIds,
    isCuentaCorrienteIsNotDerivados,
    getAccountsByCountries
}

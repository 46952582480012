const wizardComponentsHelper = require('../../utilities/wizardComponenstHelper');
const inputs = require('../../fioc/inputs');
const { PAYROLL_ACCOUNT_2_GDPR_PID } = require('constants/gdprPlacementIds');
const {
    PAYROLL_ACCOUNT_TYPE,
    PAYROLL_ACCOUNT_SUBTYPE,
    PAYROLL_ACCOUNT_STANDARD
} = require('constants/index');
const {
    PAYROLL_ACCOUNT,
    messages: { PAYROLL_ACCOUNT_AND_GDPR }
} = require('constants/operationTypes');
const { PAYROLL_ACCOUNT_ALIAS } = require('constants/hiringProductInformation');
// @ helpers
const { formatText } = require('core/i18n').i18n;
const customErrors = require('./customErrors');

const inputsFioc = wizardComponentsHelper.replacePrefix(inputs.fioc);

const personalData1 = wizardComponentsHelper.replacePrefix(inputs.personalData, 'holder_1_');
const contactData1 = wizardComponentsHelper.replacePrefix(inputs.contactData, 'holder_1_');
const generalData1 = wizardComponentsHelper.replacePrefix(inputs.generalData, 'holder_1_');
const anotherAccount1 = wizardComponentsHelper.replacePrefix(inputs.anotherAccount, 'holder_1_');
const inputsFioc1 = wizardComponentsHelper.replacePrefix(inputs.fioc, 'holder_1_');

const personalData2 = wizardComponentsHelper.replacePrefix(inputs.personalData, 'holder_2_');
const contactData2 = wizardComponentsHelper.replacePrefix(inputs.contactData, 'holder_2_');
const generalData2 = wizardComponentsHelper.replacePrefix(inputs.generalData, 'holder_2_');
const anotherAccount2 = wizardComponentsHelper.replacePrefix(inputs.anotherAccount, 'holder_2_');
const inputsFioc2 = wizardComponentsHelper.replacePrefix(inputs.fioc, 'holder_2_');

const personalData3 = wizardComponentsHelper.replacePrefix(inputs.personalData, 'holder_3_');
const contactData3 = wizardComponentsHelper.replacePrefix(inputs.contactData, 'holder_3_');
const generalData3 = wizardComponentsHelper.replacePrefix(inputs.generalData, 'holder_3_');
const anotherAccount3 = wizardComponentsHelper.replacePrefix(inputs.anotherAccount, 'holder_3_');
const inputsFioc3 = wizardComponentsHelper.replacePrefix(inputs.fioc, 'holder_3_');

const personalData4 = wizardComponentsHelper.replacePrefix(inputs.personalData, 'holder_4_');
const contactData4 = wizardComponentsHelper.replacePrefix(inputs.contactData, 'holder_4_');
const generalData4 = wizardComponentsHelper.replacePrefix(inputs.generalData, 'holder_4_');
const anotherAccount4 = wizardComponentsHelper.replacePrefix(inputs.anotherAccount, 'holder_4_');
const inputsFioc4 = wizardComponentsHelper.replacePrefix(inputs.fioc, 'holder_4_');

const step1 = require('./step1');
const step2 = require('./step2');
const step3 = require('./step3');
const successStep = require('./successStep');

const contract = {
    title: 'contractsView-payrollAccountContractTitle',
    service: '....',
    last_step: 4,
    termsAndCondsKey: PAYROLL_ACCOUNT_ALIAS,
    code_op: PAYROLL_ACCOUNT,
    showCurrentUser: true,
    promo_code: true,
    successStepType: 'dynamic',
    SignParamsFn: 'checkingPayrollAccount_SignParams',
    hireParamsFn: 'checkingPayrollAccount_HireParams',
    define_steps: 3,
    onCancelURL: '/myprofile/accounts',
    showHelpBar: true,
    errorTitle: 'contractsView-errorTitle',
    errorMessage: 'contractsView-errorMessage',
    errorBackButtonLabel: 'contractsView-errorBackButtonLabelAccountsAcquittance',
    errorBackButtonLink: '/myprofile/accounts',
    hasPrecontractualLink: true,
    accountAlias: PAYROLL_ACCOUNT_ALIAS,
    type: PAYROLL_ACCOUNT_TYPE,
    subtype: PAYROLL_ACCOUNT_SUBTYPE,
    operativeName:'operatives-payrollAccount',
    standard: PAYROLL_ACCOUNT_STANDARD,
    warningContractMessageDynamic: 'checkingPayrollAccount_WarningContractMessage',
    breadcrumb: [
        {
            text: 'breadcrumb-accounts',
            url: '/myprofile/accounts'
        },
        {
            text: 'contractsView-payrollAccountContractTitle'
        }
    ],
    state: {
        card_details_title: 'contractsView-cardDetailsTitle',
        card_details: [
            //Forms Values
            {
                id: 'intervinientes_list',
                label: 'contractsView-interveners',
                value: null,
                type: 'interveners',
                format_type: '',
                order: 1,
                confirmation_step: {
                    columns: 4,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    },
                    {
                        step: 5,
                        order: 1
                    },
                    {
                        step: 6,
                        order: 1
                    },
                ]
            },
            {
                type: 'separator',
                order: 2,
                visible: [
                    {
                        step: 1,
                        order: 2
                    },
                    {
                        step: 2,
                        order: 2
                    },
                    {
                        step: 3,
                        order: 2
                    },
                    {
                        step: 4,
                        order: 2
                    },
                    {
                        step: 5,
                        order: 2
                    },
                    {
                        step: 6,
                        order: 2
                    },
                ]
            },
            {
                id: 'holder-validation-error',
                order: 10,
                visible: []
            },
            {
                id: 'holders-personal-info-disclaimer',
                order: 10,
                visible: [],
                value: false,
                required: false,
                isErrorVisible: false
            },
            {
                id: 'step2-validation-error',
                order: 10,
                visible: []
            },
            {
                id: 'nationality',
                label: 'contractsView-nationality',
                value: null,
                type: 'nacionality',
                format_type: '',
                order: 3,
                visible: [
                    {
                        step: 1,
                        order: 3
                    },
                    {
                        step: 2,
                        order: 3
                    },
                    {
                        step: 3,
                        order: 3
                    },
                    {
                        step: 4,
                        order: 3
                    },
                    {
                        step: 5,
                        order: 3
                    },
                    {
                        step: 6,
                        order: 3
                    },
                ]
            },
            {
                id: 'countryOfResidence',
                label: 'contractsView-countryOfResidence',
                value: null,
                type: 'countryOfResidence',
                format_type: '',
                order: 3,
                visible: [
                    {
                        step: 1,
                        order: 4
                    },
                    {
                        step: 2,
                        order: 4
                    },
                    {
                        step: 3,
                        order: 4
                    },
                    {
                        step: 4,
                        order: 4
                    },
                    {
                        step: 5,
                        order: 4
                    },
                    {
                        step: 6,
                        order: 4
                    },
                ]
            },
            {
                id: 'correspondenceAddress',
                label: 'contractsView-correspondenceAddress',
                value: null,
                type: 'address',
                format_type: '',
                order: 3,
                visible: [
                    {
                        step: 1,
                        order: 5
                    },
                    {
                        step: 2,
                        order: 5
                    },
                    {
                        step: 3,
                        order: 5
                    },
                    {
                        step: 4,
                        order: 5
                    },
                    {
                        step: 5,
                        order: 5
                    },
                    {
                        step: 6,
                        order: 5
                    },
                ]
            },
             {
               type: 'separator',
                visible: [
                    {
                        step: 6,
                        order: 6
                    }
                ]
            },
            {
                id: 'promotion',
                type: 'promotion',
                visible: [
                    {
                        step: 6,
                        order: 7
                    }
                ]
            },
            {
                id: 'resident_country',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'excludes_id',
                label: '',
                value: [],
                type: 'single',
                format_type: '',
                order: 0,
                visible: []
            },
            {
                id: 'n_steps',
                label: '',
                value: 4,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
                        {
                id: 'n_steps',
                label: '',
                value: 4,
                type: 'array',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'card_image',
                value: ''
            },
            {
                id: 'card_image_no_es',
                value: {
                    DE: 'B.506.013.001',
                    NL: 'B.506.019.001',
                    PT: 'B.506.015.001',
                },
            },
            {
                id: 'card_pin_change',
                isEnabled: false,
                isValid: false,
                showError: false,
                showErrorInSummary: false
            },
            {
                id: 'passport_or_future_mode',
                value: false,
            },
            {
                id: 'tramitation_expedient',
                addDocumentationError: false,
                details: {},
                showAddDocumentation: false,
            },
            {
                id: 'show_fioc',
                label: '',
                value: false,
                type: 'fioc-test',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'show_fioc_alert',
                value: false,
            },
            {
                id_component: 'holders',
                id: 'holders',
                value: [],
                visible: []
            },
            {
                id: 'fioc_profession_category',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_1_fioc_profession_category',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_2_fioc_profession_category',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_3_fioc_profession_category',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_4_fioc_profession_category',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'fioc_profession_autocomplete_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_1_fioc_profession_autocomplete_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_2_fioc_profession_autocomplete_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_3_fioc_profession_autocomplete_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_4_fioc_profession_autocomplete_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'fioc_profession_search_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_1_fioc_profession_search_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_2_fioc_profession_search_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_3_fioc_profession_search_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_4_fioc_profession_search_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(inputsFioc),

            ...wizardComponentsHelper.returnObjectsFromArrayComponents(personalData1, 'isPayrollAccount'),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(contactData1),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(generalData1),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(anotherAccount1),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(inputsFioc1),

            ...wizardComponentsHelper.returnObjectsFromArrayComponents(personalData2, 'isPayrollAccount'),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(contactData2),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(generalData2),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(anotherAccount2),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(inputsFioc2),

            ...wizardComponentsHelper.returnObjectsFromArrayComponents(personalData3, 'isPayrollAccount'),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(contactData3),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(generalData3),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(anotherAccount3),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(inputsFioc3),

            ...wizardComponentsHelper.returnObjectsFromArrayComponents(personalData4, 'isPayrollAccount'),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(contactData4),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(generalData4),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(anotherAccount4),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(inputsFioc4),
        ],
        breadcrumb: [//PENDING: this is repeated in line 63, one of the instances should be removed
            {
                text: 'breadcrumb-accounts',
                url: '/myprofile/accounts'
            },
            {
                text: 'contractsView-payrollAccount'
            }
        ],
        confirmationCheckboxes: {
            areValid: false,
            list: [
                {
                    id: 'service_contract_checkbox',
                    idName: 'serviceContract',
                    value: false,
                    isRequired: true,
                    errorState: false,
                    errorMessage: 'contractsView-serviceContractErrorMessage',
                    labels: [
                        {
                            text: 'contractsView-payrollAccountServiceContractLabel1'
                        },
                        {
                            text: 'contractsView-payrollAccountServiceContractLabel2',
                            id: 'btn_ContractsView_precontractual',
                            href: 'contractsView-payrollAccountNoESServiceContractLabel2URL'
                        },
                        {
                            text: 'contractsView-payrollAccountServiceContractLabel3'
                        },
                        {
                            text: 'contractsView-payrollAccountServiceContractLabel4',
                            id: 'btn_ContractsView_product_2',
                            href: 'contractsView-payrollAccountNoESServiceContractLabel4URL'
                        },
                        {
                            text: 'contractsView-payrollAccountServiceContractLabel5'
                        },
                        {
                            text: 'contractsView-payrollAccountServiceContractLabel6',
                            id: 'btn_ContractsView_product_1',
                            href: 'contractsView-payrollAccountNoESServiceContractLabel6URL'
                        },
                        {
                            text: 'contractsView-payrollAccountServiceContractLabel7'
                        }
                    ]
                }
            ]
        },
        preContractDocuments : {
            preContractPdfFileName: "documentosCuentaNóminaOpen",
            preContractPdfTitle: formatText('preContractDocument-preContractDocument_payrollAccountPdfTitle')
        }
    },
    steps: [
        step1,
        step2,
        step3,
    ],
    successStep,
    customErrors
};

module.exports = {
    contract
};

const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');
const { FIRST_STEP, SECOND_STEP, THIRD_STEP } = require('constants/index');

const cardChangePaymentMethodStep1 = require('./cardChangePaymentMethod/cardChangePaymentMethodStep1');
const cardChangePaymentMethodStep2 = require('./cardChangePaymentMethod/cardChangePaymentMethodStep2');
const hireCardOpenCreditStepINE = require('./hireCardOpenCredit/hireCardOpenCreditStepINE');
const { isPrintOfficeMode } = require('utilities/contactCenter/printerHelper');

function setInitialState() {
    return Immutable.fromJS({
        steps: [
            cardChangePaymentMethodStep1(undefined, { type: null }),
            hireCardOpenCreditStepINE(),
            cardChangePaymentMethodStep2(undefined, { type: null })
        ],
        stepsCC: [
            cardChangePaymentMethodStep1(undefined, { type: null }),
            cardChangePaymentMethodStep2(undefined, { type: null })
        ],
        visibleStep: 1,
        signatureKey: {
            referenceNumber: '',
            digits: []
        },
        newSMSCodeRequested: false,
        signatureKeyFailure: false,
        isFetching: false,
        inProgress: false,
        submitSuccessful: false,
        willLeave: false,
        willCancel: false,
        error: '',
        signatureKeyAttempt: 1,
        signatureKeyLocked: false,
        isDisabledButton: false,
        initialDocumentsList: [],
        allDocumentRead: true,
        processEmailLoading: false,
        agreeCheckboxPaymentMethod: true,
        agreeIneCheckbox: false,
        mailSent: false
    });
}

function updateStep(index, steps, action) {
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return cardChangePaymentMethodStep1(step, action);
            case SECOND_STEP:
                return hireCardOpenCreditStepINE(step, action);
            case THIRD_STEP:
                return cardChangePaymentMethodStep2(step, action);
        }
    })
}


function getNextVisibleStep(partialSteps, currentStep) {
    const totalSteps = partialSteps.size;
    const stepPosition = currentStep - 1;
    let nextStep = currentStep + 1;

    if (!partialSteps.getIn([stepPosition, 'valid']) || nextStep > totalSteps) {
        nextStep = currentStep;
    }

    return nextStep;
}
const updateDocumentsState = (documents, dmsDocumentType, documentChanges) =>
    documents.map(document =>
        document.dmsDocumentType === dmsDocumentType
            ? {
                ...document,
                ...documentChanges,
            }
            : document
    );
function cardChangePaymentMethodReducer(state = setInitialState(), action) {
    let partialSteps;
    switch (action.type) {
        case actionTypes.CARD_GET_PAYMENT_METHOD_INFORMATION_REQUEST:
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.CARD_GET_PAYMENT_METHOD_INFORMATION_FAILURE:
            return state.merge({
                isFetching: false,
                error: action.payload.error,
            });
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_VALIDATE_STEP:
            let visibleStep;
            const currentStep = action.payload.step;

            switch (action.payload.step) {
                case 1:
                    partialSteps = updateStep(FIRST_STEP, state.get(__CONTACT_CENTER__ && !isPrintOfficeMode() ? 'stepsCC' : 'steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;

                    return state.merge({
                        steps: partialSteps,
                        stepsCC: partialSteps,
                        visibleStep
                    });
                case 2:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);
                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
                case 3:
                    partialSteps = updateStep(THIRD_STEP, state.get('steps'), action);
                    visibleStep = getNextVisibleStep(partialSteps, currentStep);

                    return state.merge({
                        steps: partialSteps,
                        visibleStep
                    });
            }
            return state;

        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            })
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                steps: updateStep(SECOND_STEP, state.get('steps'), action)
            });
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_FAILURE:
            return state.merge({
                error: action.payload.error,
                inProgress: false,
                isFetching: false
            });
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_METHOD:
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_INSTALLMENTS:
        case actionTypes.CARD_GET_PAYMENT_METHOD_INFORMATION_SUCCESS:

            return state.merge({
                steps: updateStep(FIRST_STEP, state.get('steps'), action),
                stepsCC: updateStep(FIRST_STEP, state.get('stepsCC'), action),
                inProgress: true,
                isFetching: false
            })
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_WILL_CANCEL:
            return state.merge({
                willCancel: action.payload.willCancel
            })
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_CLEAR:
            return setInitialState();
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_SUCCESS:
            return state.merge({
                isFetching: false,
                submitSuccessful: true,
                signatureKeyFailure: false,
                errorReceived: '',
                inProgress: false
            })
        case actionTypes.CARD_CHANGE_PAYMENT_METHOD_SET_DISABLED_BUTTON:
            return state.merge({
                isDisabledButton: action.payload.value,
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_RESET_DOCUMENTS_INE_DIR:
            return state.merge({
                initialDocumentsList: []
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_DOCUMENT_READ:
            return state.merge({
                ...state,
                initialDocumentsList: updateDocumentsState(action.payload.documentsList, action.payload.dmsDocumentType, action.payload.documentChanges)
            });
        case actionTypes.HIRE_CARD_CREDIT_INIT_POLICY_SUCCESS:
            return state.merge({
                operationProcessIdInitPolicy: action.payload.response.operationProcessId,
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_ALL_DOCUMENT_READ:
            return state.merge({
                allDocumentRead: false,
            });
        case actionTypes.RESET_INIT_CARDS_DOCUMENTS:
            return state.merge({
                initialDocumentsList: [],
                documentPolicy: false,
                allDocumentRead: true
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_SAVE_DOCUMENT_ACCEPTED:
            return state.merge({
                ...state,
                initialDocumentsList: updateDocumentsState(action.payload.documentsList, action.payload.dmsDocumentType, action.payload.documentChanges)
            });
        case actionTypes.HIRE_NEW_CREDIT_CARD_CHECK_RADIO_BUTTON_INE_DIR:
            const currentDocument = action.payload.currentDocument
            const documentType = action.payload.documentType;
            const indexDocument = state.get('initialDocumentsList').findIndex(doc => doc.dmsDocumentType === currentDocument);
            if (indexDocument !== -1) {
                const updatedDocumentsList = state.get('initialDocumentsList').map((doc, i) => {
                    i === indexDocument ? { ...doc, radioButton: documentType } : doc
                }
                );
                return state.merge({
                    initialDocumentsList: updatedDocumentsList
                });
            }
        case actionTypes.HIRE_CARD_CREDIT_INE_SUCCESS:
            const newDocumentIne = {
                dmsDocumentType: "INE",
                documentName: "documentNameIne",
                file: 'data:application/pdf;base64,' + action.payload.base64,
                isAccepted: false,
                isRead: false,
                title: "hireCreditCard-titleINE",
                fileName: "INE_document",
                radioButton: null

            };
            return state.merge({
                documentINE: action.payload.base64,
                documentIdINE: action.payload.documentId,
                initialDocumentsList: state.get('initialDocumentsList').concat(newDocumentIne)
            });
        case actionTypes.HIRE_CARD_CREDIT_DIR_SUCCESS:
            const newDocumentDir = {
                dmsDocumentType: "DIR",
                documentName: "documentNameDir",
                file: 'data:application/pdf;base64,' + action.payload.base64,
                isAccepted: false,
                isRead: false,
                title: "hireCreditCard-titleDIR",
                fileName: "DIR_document",
                radioButton: null
            };
            return state.merge({
                documentDIR: action.payload.base64,
                documentIdDIR: action.payload.documentId,
                initialDocumentsList: state.get('initialDocumentsList').concat(newDocumentDir)
            });
        case actionTypes.CARD_CHANGE_NEW_PAYMENT_METHOD_SET_PRODUCT:
            return state.merge({
                product: action.payload.product,
            });

        case actionTypes.CARD_CHANGE_PAYMENT_SET_CARD_ID:
            return state.merge({
                cardId: action.payload.cardId
            });
        case actionTypes.HIRE_CARD_CREDIT_POLICY_SUCCESS:
            return state.merge({
                documentPolicy: action.payload.base64,
                documentIdPolicy: action.payload.documentId,
            });
        case actionTypes.CARD_CHANGE_PAYMENT_PROCESS_EMAIL_SUCCESS:
            return state.merge({
                processEmailLoading: false,
                mailSent: true,
                hasBeenSentEmail: true,
                isFetching: false
            });
        case actionTypes.CARD_CHANGE_PAYMENT_PROCESS_EMAIL_LOADING:
            return state.merge({
                processEmailLoading: true,
            });
        case actionTypes.HIRE_CARD_CREDIT_INIT_SUCCESS:
            return state.merge({
                operationProcessIdInit: action.payload.response.operationProcessId,
                contractNumberAccount: action.payload.contractNumberAccount,
            });
        case actionTypes.HIRE_CARD_CREDIT_INIT_ERROR:
        case actionTypes.HIRE_CARD_CREDIT_DIR_ERROR:
        case actionTypes.HIRE_CARD_CREDIT_INE_ERROR:
        case actionTypes.CARD_CHANGE_PAYMENT_PROCESS_EMAIL_ERROR:
            return state.merge({
                error: true,
            });

        case actionTypes.HIRE_CARD_CREDIT_CARD_SET_AGREEMENT_CHECKBOX_CC:
            return state.merge({
                agreeIneCheckbox: !state.get('agreeIneCheckbox')
            });

        default:
            return state
    }
}

module.exports = cardChangePaymentMethodReducer;

const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
        valid: false,
});

function validateStep(state) {
    return state.merge({
        valid: true,
    });
}

function hireNewCreditCardInformationCcStep1(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_NEW_CREDIT_CARD_VALIDATE_STEP:
            return validateStep(state, action, action.payload.valid);
        default:
            return state;
    }
}

module.exports = hireNewCreditCardInformationCcStep1;